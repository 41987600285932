import { useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import CustomButton from '../customButton';
import ReorderItems from '../reorderItems';
import { reorder } from '../../../utility/helpers';
import LevelingFramework from './levelingFramework';

const useStyles = makeStyles(({ palette: { primary } }) => ({
  button: {
    borderRadius: 8,
    width: '100%',
    height: 40,
    minHeight: 40,
    backgroundColor: primary.bluish8,
    boxSizing: 'border-box',
    border: `1px solid ${primary.bluish6}`,
    justifyContent: 'center',
    '&:hover': {
      backgroundColor: primary.bluish8,
    },
  },
}));

const LevelingFrameworks = ({
  translations,
  levelingFrameworks,
  onEditFrameworkTitle,
  onEditTrackTitle,
  onDuplicateFramework,
  onDuplicateTrack,
  handleDeleteFrameworkDialog,
  handleDeleteTrackDialog,
  handleCreateFramework,
  handleCreateLevel,
  handleCreateTrack,
  handleMoveTrack,
  onReorderLevel,
  onReorderTrack,
  onReorderFramework,
  isReadOnly,
  isExpandedOnFirstOpen,
  toggleExpansion,
  isExpandedOnFirstOpenFramework,
  toggleExpansionFramework,
  isAdmin,
}) => {
  const classes = useStyles();

  const handleReorder = useCallback(
    result => {
      if (
        !result.destination ||
        result.source.index === result.destination.index
      )
        return;

      const updatedOrder = reorder(
        levelingFrameworks,
        result.source.index,
        result.destination.index
      );

      return onReorderFramework(updatedOrder);
    },
    [levelingFrameworks, onReorderFramework]
  );

  return (
    <div>
      <ReorderItems
        onSave={handleReorder}
        isDragDisabled={isReadOnly || !isAdmin}
      >
        {levelingFrameworks?.map(levelingFramework => (
          <LevelingFramework
            key={`framework_group_${levelingFramework.id}`}
            id={levelingFramework.id}
            isAdmin={isAdmin}
            translations={translations}
            levelingFramework={levelingFramework}
            onEditFrameworkTitle={onEditFrameworkTitle}
            onEditTrackTitle={onEditTrackTitle}
            onDuplicateFramework={onDuplicateFramework}
            onDuplicateTrack={onDuplicateTrack}
            onReorderTrack={onReorderTrack}
            onReorderLevel={onReorderLevel}
            handleDeleteFrameworkDialog={handleDeleteFrameworkDialog}
            handleDeleteTrackDialog={handleDeleteTrackDialog}
            handleCreateLevel={handleCreateLevel}
            handleCreateTrack={handleCreateTrack}
            handleMoveTrack={handleMoveTrack}
            isReadOnly={isReadOnly}
            isExpandedOnFirstOpen={isExpandedOnFirstOpen}
            toggleExpansion={toggleExpansion}
            isExpandedOnFirstOpenFramework={isExpandedOnFirstOpenFramework}
            toggleExpansionFramework={toggleExpansionFramework}
          />
        ))}
      </ReorderItems>
      {!isReadOnly && isAdmin && (
        <CustomButton
          className={classes.button}
          type="addWithTextDarkNewLarge"
          onClick={handleCreateFramework}
        >
          {translations.createFrameworkButton}
        </CustomButton>
      )}
    </div>
  );
};

LevelingFrameworks.defaultProps = {
  levelingFrameworks: [],
  isReadOnly: false,
  onEditFrameworkTitle: () => {},
  onEditTrackTitle: () => {},
  onDuplicateFramework: () => {},
  onDuplicateTrack: () => {},
  handleCreateFramework: () => {},
  handleDeleteFrameworkDialog: () => {},
  handleDeleteTrackDialog: () => {},
  handleCreateLevel: () => {},
  handleCreateTrack: () => {},
  handleMoveTrack: () => {},
  onReorderLevel: () => {},
  onReorderTrack: () => {},
  onReorderFramework: () => {},
  isExpandedOnFirstOpen: false,
  isExpandedOnFirstOpenFramework: false,
  toggleExpansion: () => {},
  toggleExpansionFramework: () => {},
};

LevelingFrameworks.propTypes = {
  translations: PropTypes.object.isRequired,
  isReadOnly: PropTypes.bool,
  levelingFrameworks: PropTypes.arrayOf(PropTypes.object),
  onEditFrameworkTitle: PropTypes.func,
  onEditTrackTitle: PropTypes.func,
  onDuplicateFramework: PropTypes.func,
  onDuplicateTrack: PropTypes.func,
  handleCreateFramework: PropTypes.func,
  handleCreateLevel: PropTypes.func,
  handleCreateTrack: PropTypes.func,
  handleMoveTrack: PropTypes.func,
  handleDeleteFrameworkDialog: PropTypes.func,
  handleDeleteTrackDialog: PropTypes.func,
  onReorderLevel: PropTypes.func,
  onReorderTrack: PropTypes.func,
  onReorderFramework: PropTypes.func,
  isExpandedOnFirstOpen: PropTypes.bool,
  toggleExpansion: PropTypes.func,
  isExpandedOnFirstOpenFramework: PropTypes.bool,
  toggleExpansionFramework: PropTypes.func,
  isAdmin: PropTypes.bool.isRequired,
};

export default memo(LevelingFrameworks);
