import { ROLES } from './rolesAndPermissionList';

const { ADMIN, MODERATOR, USER } = ROLES;

export const ROUTE_ALL_ROLES = [ADMIN, MODERATOR, USER];

export const ROUTE_MANAGING_ROLES = [ADMIN, MODERATOR];

export default {
  all: [],
  absence: {
    sets: [ADMIN],
    leaveRequests: [ADMIN],
  },
  dashboard: ROUTE_ALL_ROLES,
  companyOverview: [ADMIN],
  people: {
    career: ROUTE_ALL_ROLES,
    people: ROUTE_ALL_ROLES,
    generalInfo: ROUTE_ALL_ROLES,
    actionPlans: ROUTE_ALL_ROLES,
    insights: ROUTE_ALL_ROLES,
    team: ROUTE_ALL_ROLES,
    attributes: ROUTE_ALL_ROLES,
    courses: ROUTE_ALL_ROLES,
    notes: ROUTE_ALL_ROLES,
    oneOnOne: ROUTE_ALL_ROLES,
  },
  survey: {
    survey: ROUTE_MANAGING_ROLES,
    surveySettings: ROUTE_MANAGING_ROLES,
    surveyReport: ROUTE_MANAGING_ROLES,
    attributes: ROUTE_MANAGING_ROLES,
  },
  reports: {
    companyOverview: [ADMIN],
    competenceMap: [ADMIN],
    skillsMatrix: ROUTE_MANAGING_ROLES,
    generalMood: ROUTE_MANAGING_ROLES,
    attribute: [ADMIN],
    compare: [ADMIN],
    surveySummary: ROUTE_ALL_ROLES,
  },
  fillSurvey: ROUTE_ALL_ROLES,
  organization: ROUTE_ALL_ROLES,
  privateNotes: ROUTE_MANAGING_ROLES,
  actionPlans: ROUTE_MANAGING_ROLES,
  actionPlanDetails: ROUTE_ALL_ROLES,
  oneOnOne: ROUTE_MANAGING_ROLES,
  oneOnOneDetails: ROUTE_ALL_ROLES,
  organizationSettings: [ADMIN],
  releaseNotes: ROUTE_ALL_ROLES,
  slackIntegration: [ADMIN],
  pricingPlans: [ADMIN],
  course: {
    course: ROUTE_ALL_ROLES,
    courseDetails: ROUTE_ALL_ROLES,
  },
  career: {
    leveling: ROUTE_MANAGING_ROLES,
    jobTitles: ROUTE_MANAGING_ROLES,
  },
  tags: ROUTE_MANAGING_ROLES,
  tagCategory: ROUTE_ALL_ROLES,
};
