import { useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { TransitionGroup } from 'react-transition-group';
import { Typography, Fade, makeStyles, alpha } from '@material-ui/core';
import PlaceholderButton from '../../../placeholderButton';
import DraggableScroll from '../../../draggableScroll';
import ScrollIndicators from '../../../scrollIndicators';
import Holiday from './holiday';
import { ReactComponent as HolidaysIcon } from '../../../../../assets/icons/celebration.svg';
import { getTotalHolidaysCount } from '../config';
import { useDraggableScrollPosition } from '../../../../../utility/hooks';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  root: {
    display: 'flex',
    width: '100%',
    height: 210,
  },
  content: {
    backgroundColor: primary.white,
    borderRadius: 16,
    display: 'flex',
    maxWidth: '100%',
    alignItems: 'center',
    boxShadow: `0px 2px 8px 0px ${alpha(primary.black, 0.07)}`,
  },
  info: {
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    padding: spacing(4),
    height: '100%',
    width: 370,
    flexShrink: 0,
  },
  infoHeaderWrapper: {
    marginBottom: spacing(4),
  },
  infoHeaderTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 28,
  },
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    marginTop: spacing(0.75),
  },
  icon: {
    marginRight: spacing(1),
  },
  description: {
    marginTop: spacing(1),
  },
  daysCountLabel: {
    color: primary.bluish4,
  },
  holidays: {
    display: 'flex',
    width: '100%',
    maxWidth: 'calc(100% - 370px)',
    position: 'relative',
  },
  scrollWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  holidaysWrapper: {
    display: 'flex',
    width: 'auto',
  },
  holidayItems: {
    display: 'flex',
  },
  arrow: {
    top: 90,
  },
  holiday: {
    display: 'flex',
    maxWidth: '100%',
    marginLeft: spacing(2),
  },
  addButton: {
    backgroundColor: primary.bluish9,
    border: `1px dashed ${primary.bluish5}`,
    borderRadius: 16,
    width: 300,
    height: 194,
    minHeight: 194,
    margin: spacing(0, 2),
    transition: 'box-shadow .3s ease',
    '&:hover': {
      border: `1px dashed ${primary.bluish5}`,
      boxShadow: `0px 2px 8px 0px ${alpha(primary.black, 0.12)}`,
    },
  },
}));

const Holidays = ({
  className,
  translations,
  holidays,
  onAdd,
  onEdit,
  onDelete,
}) => {
  const scrollRef = useRef(null);
  const itemsRef = useRef(null);

  const classes = useStyles();

  const [
    activeScroll,
    handleChangeScrollPosition,
    onScrollToRight,
    ,
    onScrollToStart,
  ] = useDraggableScrollPosition(scrollRef, itemsRef);

  const totalDays = getTotalHolidaysCount(holidays);

  const handleHolidaysClick = e => {
    e.stopPropagation();
  };

  return (
    <div
      className={classNames(classes.root, className)}
      onClick={handleHolidaysClick}
    >
      <div className={classes.content}>
        <div className={classes.info}>
          <div className={classes.infoHeaderWrapper}>
            <div className={classes.infoHeaderTitle}>
              <div className={classes.titleWrapper}>
                <HolidaysIcon className={classes.icon} />
                <Typography className={classes.title} variant="h5">
                  {translations.holidays.title}
                </Typography>
              </div>
            </div>
            <Typography className={classes.description} variant="body2">
              {translations.holidays.description}
            </Typography>
          </div>
          <div>
            <Typography className={classes.daysCountLabel} variant="body2">
              {translations.daysCountLabel}
            </Typography>
            <Typography variant="h1">
              {`${totalDays} ${
                totalDays === 1 ? translations.day : translations.days
              }`}
            </Typography>
          </div>
        </div>
        <div className={classes.holidays}>
          <DraggableScroll
            ref={el => {
              scrollRef.current = el;
            }}
            className={classes.scrollWrapper}
            onEndScroll={handleChangeScrollPosition}
          >
            <div ref={itemsRef} className={classes.holidaysWrapper}>
              <div className={classes.holidaysWrapper}>
                <TransitionGroup className={classes.holidayItems}>
                  {holidays.map(holiday => (
                    <Fade key={`holiday_${holiday.id}`} in unmountOnExit>
                      <div className={classes.holiday}>
                        <Holiday
                          translations={translations}
                          holiday={holiday}
                          onEdit={onEdit}
                          onDelete={onDelete}
                        />
                      </div>
                    </Fade>
                  ))}
                </TransitionGroup>
                <PlaceholderButton
                  className={classes.addButton}
                  label={translations.addHolidayLabel}
                  onAdd={onAdd}
                />
              </div>
            </div>
          </DraggableScroll>
          <ScrollIndicators
            arrowLeftClass={classes.arrow}
            arrowRightClass={classes.arrow}
            activeScroll={activeScroll}
            onScrollToRight={onScrollToRight}
            onScrollToStart={onScrollToStart}
          />
        </div>
      </div>
    </div>
  );
};

Holidays.defaultProps = {
  className: undefined,
  holidays: [],
};

Holidays.propTypes = {
  className: PropTypes.string,
  translations: PropTypes.object.isRequired,
  holidays: PropTypes.arrayOf(PropTypes.object),
  onAdd: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default Holidays;
