import { useState, useRef } from 'react';
import classNames from 'classnames';
import { TransitionGroup } from 'react-transition-group';
import PropTypes from 'prop-types';
import {
  Typography,
  Popper,
  ClickAwayListener,
  Fade,
  makeStyles,
} from '@material-ui/core';
import InputField from '../inputField';
import CustomButton from '../customButton';
import UserAvatar from '../userAvatar';
import CustomCheckbox from '../customCheckbox';
import CustomScrollBar from '../customScrollBar';
import ActionButton from '../actionButton';
import { ReactComponent as DirectReportArrow } from '../../../assets/icons/direct-reports-arrow.svg';
import {
  isArrayEmpty,
  isItemInList,
  getUnionOfTwoArrays,
  isArraySubset,
  trimString,
  getAssociates,
  getEmployees,
} from '../../../utility/helpers';
import { useCustomEffect } from '../../../utility/hooks';
import { customSearch } from '../../../utility/uiUtils';
import { ACTION_BUTTON_TYPES } from '../actionButton/config';
import { isDirty, setPopperWidth, getScrollbarHeight } from './config';
import ShareTypeBox from '../shareTypeBox';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  box: {
    minWidth: 'fit-content',
  },
  picker: {
    display: 'flex',
    width: '100%',
    position: 'relative',
  },
  popperRoot: {
    boxShadow: 'none',
    borderRadius: 4,
    zIndex: 1,
  },
  popperMain: {
    boxSizing: 'border-box',
    backgroundColor: primary.white,
    border: `1px solid ${primary.bluish6}`,
    height: 323,
    borderRadius: 4,
    padding: spacing(4),
    marginTop: spacing(1),
  },
  popperMainSmall: {
    height: 200,
  },
  popperContent: {
    display: 'flex',
    flexDirection: 'column',
  },
  popoverFooter: {
    display: 'flex',
    marginTop: spacing(4),
    paddingTop: 24,
    borderTop: `1px solid ${primary.bluish7}`,
  },
  item: {
    borderRadius: 4,
  },
  selectAllItem: {
    padding: spacing(2),
  },
  userItem: {
    borderRadius: 4,
    display: 'flex',
    alignItems: 'center',
    padding: spacing(2),
  },
  userAvatar: {
    width: 'calc(100% - 24px)',
  },
  avatarLabel: {
    color: primary.bluish2,
    fontSize: 16,
    lineHeight: '20px',
    marginLeft: 0,
    paddingRight: spacing(1.5),
    width: '100%',
    userSelect: 'none',
  },
  avatarLabelSelected: {
    color: primary.bluish1,
    fontFamily: 'ProximaNova-Bold',
    fontSize: 14,
    lineHeight: '18px',
  },
  checkboxContainer: {
    display: 'flex',
    flexShrink: 0,
    width: 24,
    height: 24,
  },
  directReports: {
    borderRadius: 4,
    display: 'flex',
    alignItems: 'center',
    marginTop: spacing(1),
    padding: spacing(2),
  },
  directReportText: {
    color: primary.bluish2,
    cursor: 'pointer',
    marginLeft: spacing(2),
    flexGrow: 1,
    userSelect: 'none',
  },
  noResults: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: spacing(33.5),
  },
  noResultsWithSelectAllEmployees: {
    marginTop: spacing(17.5),
  },
  noResultsWithSelectAll: {
    marginTop: spacing(12.5),
  },
  arrowIcon: {
    marginLeft: spacing(2.75),
    marginBottom: spacing(2.25),
  },
  scrollY: {
    top: 11,
    right: -10,
    height: 'calc(100% - 22px)',
    width: 4,
  },
  hover: {
    transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    '&:hover': {
      backgroundColor: primary.bluish8,
    },
  },
  disabledUser: {
    cursor: 'default',
    backgroundColor: primary.bluish9,
    '&:hover': {
      backgroundColor: primary.bluish9,
    },
  },
  selectButton: {
    marginLeft: 'auto',
  },
  selectionWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
  },
  selectedUser: {
    borderBottom: `1px solid ${primary.bluish7}`,
    display: 'flex',
    alignItems: 'center',
    marginBottom: spacing(4),
    paddingBottom: spacing(4),
    width: '100%',
    '&:last-of-type': {
      borderBottom: 'none',
      paddingBottom: 0,
      marginBottom: 0,
    },
  },
  closeIconButton: {
    height: 24,
    width: 24,
  },
  closeIcon: {
    height: 16,
    width: 16,
    '& path': {
      fill: primary.bluish1,
    },
  },
  closeIconDisabled: {
    '& path': {
      fill: primary.bluish5,
    },
  },
  selectAllWrapper: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  selectAllTitle: {
    color: primary.bluish2,
    marginLeft: spacing(2),
    flexGrow: 1,
    userSelect: 'none',
  },
  disabledSelectAll: {
    cursor: 'default',
    '&:hover': {
      backgroundColor: primary.white,
    },
  },
  selectedUsersWrapper: {
    marginTop: spacing(6),
  },
  selectedUsersWrapperScrollable: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: 147,
  },
  scrollYSelected: {
    backgroundColor: primary.bluish9,
    top: 0,
    right: -20,
    height: '100%',
    width: 8,
  },
  scrollSelected: {
    backgroundColor: primary.bluish7,
  },
}));

const PeoplePicker = ({
  className,
  translations,
  options,
  preselectedUsers,
  blacklistedUsers,
  formattedUsers,
  isDisabled,
  hasError,
  errorMessage,
  showSelection,
  showDirectReports,
  hasSelectAll,
  hasScrollbar,
  dropdownWidth,
  onSelect,
  shouldHandleTypeLabel,
  handleTypeLabel,
}) => {
  const classes = useStyles();
  const inputRef = useRef(null);

  const [isOpen, setIsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [search, setSearch] = useState('');
  const [users, setUsers] = useState(options);
  const [isFocused, setIsFocused] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState(preselectedUsers);

  const allEmployees = getEmployees(options);
  const allAssociates = getAssociates(options);
  const hasEmployees = !isArrayEmpty(allEmployees);
  const hasAssociates = !isArrayEmpty(allAssociates);
  const disabledEmployeesCount = allEmployees.filter(
    employee => employee.isDisabled
  ).length;
  const isSelectAllEmployeesDisabled =
    disabledEmployeesCount === allEmployees.length;

  const handleClose = () => {
    setIsOpen(false);
    setSearch('');
    setUsers([]);
    setSelectedUsers(preselectedUsers);
    setIsFocused(false);
  };

  const onSetAnchorEl = () => setAnchorEl(inputRef?.current);

  const handleOpen = () => {
    if (!isOpen) {
      setIsOpen(true);
    }
  };

  const getPeople = (term = '') => {
    const searchTerm = trimString(term);
    const newUsers = customSearch(options, searchTerm, true);

    return setUsers(newUsers);
  };

  const handleChange = event => {
    const { value } = event.target;

    setSearch(value);
    handleOpen();
    return getPeople(value);
  };

  const handleInputFocus = () => {
    setIsFocused(true);
    setIsOpen(true);
  };

  const handleSelectAll = allOptions => () => {
    if (isArraySubset(selectedUsers, allOptions)) {
      setSelectedUsers(prevSelectedUsers =>
        prevSelectedUsers.filter(
          sUser => !allOptions.some(u => u.id === sUser.id && !u.isDisabled)
        )
      );
    } else {
      setSelectedUsers(prevSelectedUsers =>
        getUnionOfTwoArrays(prevSelectedUsers, allOptions)
      );
    }
  };

  const handleUserSelect = currentUser => () => {
    const isSelected = isItemInList(selectedUsers, currentUser);

    if (isSelected) {
      setSelectedUsers(prevSelectedUsers =>
        prevSelectedUsers.filter(u => u.id !== currentUser.id)
      );
    } else {
      setSelectedUsers(prevSelectedUsers => [
        ...prevSelectedUsers,
        currentUser,
      ]);
    }
  };

  const handleDirectReportSelect = reporters => () => {
    const isSelected = isArraySubset(selectedUsers, reporters);

    if (isSelected) {
      setSelectedUsers(prevSelectedUsers =>
        prevSelectedUsers.filter(user => !isItemInList(reporters, user))
      );
    } else {
      setSelectedUsers(prevSelectedUsers =>
        getUnionOfTwoArrays(prevSelectedUsers, reporters)
      );
    }
  };

  const handleDeselectUser = currentUser => () => {
    const selectedUsersUpdated = selectedUsers.filter(
      u => u.id !== currentUser.id
    );

    setSelectedUsers(selectedUsersUpdated);
    onSelect(selectedUsersUpdated);
  };

  const handleAddUsers = () => {
    const shouldAddUsers = isDirty(preselectedUsers, selectedUsers);

    if (shouldAddUsers) {
      onSelect(selectedUsers);
    }

    handleClose();
  };

  useCustomEffect(onSetAnchorEl);

  useCustomEffect(
    () => {
      if (!isArrayEmpty(preselectedUsers)) {
        setSelectedUsers(preselectedUsers);
      }
      if (isFocused) {
        getPeople();
      }
    },
    [isFocused, preselectedUsers],
    false
  );

  const renderSuggestions = () => {
    if (!isArrayEmpty(users)) {
      return (
        <TransitionGroup>
          {users.map(user => {
            const isUserSelected = isItemInList(selectedUsers, user);
            const reportsArray = formattedUsers[user.id];
            const directReports = reportsArray
              ? reportsArray.filter(
                  reporter => !blacklistedUsers.includes(reporter.id)
                )
              : [];

            return (
              <Fade key={user.id} unmountOnExit appear addEndListener={null}>
                <div className={classes.item}>
                  <div
                    className={classNames(classes.userItem, classes.hover, {
                      [classes.disabledUser]: user.isDisabled,
                    })}
                  >
                    <UserAvatar
                      className={classes.userAvatar}
                      labelClass={classes.avatarLabel}
                      user={user}
                      onClickHandler={handleUserSelect(user)}
                      clickableCaption={!user.isDisabled}
                      caption
                      small
                    />
                    <div className={classes.checkboxContainer}>
                      <CustomCheckbox
                        isChecked={isUserSelected}
                        onChange={handleUserSelect(user)}
                        disabled={user.isDisabled}
                        isControlled
                      />
                    </div>
                  </div>
                  {showDirectReports && !isArrayEmpty(directReports) ? (
                    <div
                      className={classNames(
                        classes.directReports,
                        classes.hover
                      )}
                    >
                      <DirectReportArrow className={classes.arrowIcon} />
                      <Typography
                        className={classes.directReportText}
                        onClick={handleDirectReportSelect(directReports)}
                      >
                        {`${translations.directReports} (${directReports.length})`}
                      </Typography>
                      <div className={classes.checkboxContainer}>
                        <CustomCheckbox
                          isChecked={
                            isArrayEmpty(directReports)
                              ? false
                              : isArraySubset(selectedUsers, directReports)
                          }
                          onChange={handleDirectReportSelect(directReports)}
                          isControlled
                        />
                      </div>
                    </div>
                  ) : null}
                </div>
              </Fade>
            );
          })}
        </TransitionGroup>
      );
    }
    return (
      <Typography
        className={classNames(classes.noResults, {
          [classes.noResultsWithSelectAllEmployees]: hasSelectAll,
          [classes.noResultsWithSelectAll]:
            hasSelectAll && hasEmployees && hasAssociates,
        })}
      >
        {translations.noUserResults}
      </Typography>
    );
  };

  const renderSelectedUsers = () => (
    <div
      className={classNames(classes.selectedUsersWrapper, {
        [classes.selectedUsersWrapperScrollable]: hasScrollbar,
      })}
    >
      <CustomScrollBar
        customScrollBarYClass={classes.scrollYSelected}
        customScrollClass={classes.scrollSelected}
        verticalScroll={hasScrollbar}
        passContentHeight
        removeScrollX
      >
        <TransitionGroup className={classes.selectionWrapper}>
          {preselectedUsers.map(user => {
            const label = shouldHandleTypeLabel ? handleTypeLabel(user) : false;
            return (
              <Fade key={user.id} unmountOnExit addEndListener={null}>
                <div className={classes.selectedUser}>
                  <UserAvatar
                    className={classes.userAvatar}
                    labelClass={classNames(
                      classes.avatarLabel,
                      classes.avatarLabelSelected
                    )}
                    user={user}
                    caption
                    small
                  />
                  {shouldHandleTypeLabel && label && (
                    <ShareTypeBox className={classes.box} label={label} />
                  )}
                  <ActionButton
                    className={classes.closeIconButton}
                    iconClass={classNames(classes.closeIcon, {
                      [classes.closeIconDisabled]: user.isDisabled,
                    })}
                    type={ACTION_BUTTON_TYPES.CLOSE}
                    disabledTooltipText={translations.disabledUserTooltip}
                    isDisabled={user.isDisabled}
                    onClickHandler={handleDeselectUser(user)}
                  />
                </div>
              </Fade>
            );
          })}
        </TransitionGroup>
      </CustomScrollBar>
    </div>
  );

  return (
    <div className={className}>
      <ClickAwayListener onClickAway={handleClose} mouseEvent="onMouseDown">
        <div className={classes.picker}>
          <InputField
            ref={inputRef}
            label={translations.label}
            placeholder={translations.placeholder}
            value={search}
            error={hasError}
            errorMessage={errorMessage}
            disabled={isDisabled}
            onChange={handleChange}
            onInputFocus={handleInputFocus}
            autoComplete="off"
            fullWidth
          />
          <Popper
            className={classes.popperRoot}
            style={{
              width: anchorEl ? anchorEl.clientWidth : null,
            }}
            anchorEl={anchorEl}
            open={isOpen}
            modifiers={{
              offset: { enabled: true, offset: '-12, 0' },
              preventOverflow: {
                enabled: true,
                escapeWithReference: true,
                boundariesElement: 'viewport',
              },
            }}
            popperOptions={{
              onCreate: ({ instance: { reference, popper } }) =>
                setPopperWidth(reference, popper, dropdownWidth),
              onUpdate: ({ instance: { reference, popper } }) =>
                setPopperWidth(reference, popper, dropdownWidth),
            }}
            placement="bottom-start"
            disablePortal
          >
            <div className={classes.popperMain}>
              {hasSelectAll && (
                <>
                  {!isArrayEmpty(allEmployees) && (
                    <div
                      className={classNames(
                        classes.selectAllItem,
                        classes.hover,
                        classes.selectAllWrapper,
                        {
                          [classes.disabledSelectAll]:
                            isSelectAllEmployeesDisabled,
                        }
                      )}
                      onClick={
                        isSelectAllEmployeesDisabled
                          ? () => {}
                          : handleSelectAll(allEmployees)
                      }
                    >
                      <Typography className={classes.selectAllTitle}>
                        {translations.allEmployees}
                      </Typography>
                      <div className={classes.checkboxContainer}>
                        <CustomCheckbox
                          isChecked={
                            !isArrayEmpty(options) &&
                            isArraySubset(selectedUsers, allEmployees)
                          }
                          disabled={isSelectAllEmployeesDisabled}
                          onChange={handleSelectAll(allEmployees)}
                          isControlled
                        />
                      </div>
                    </div>
                  )}
                  {!isArrayEmpty(allAssociates) && (
                    <div
                      className={classNames(
                        classes.selectAllItem,
                        classes.hover,
                        classes.selectAllWrapper
                      )}
                      onClick={handleSelectAll(allAssociates)}
                    >
                      <Typography className={classes.selectAllTitle}>
                        {translations.allAssociates}
                      </Typography>
                      <div className={classes.checkboxContainer}>
                        <CustomCheckbox
                          isChecked={
                            !isArrayEmpty(options) &&
                            isArraySubset(selectedUsers, allAssociates)
                          }
                          onChange={handleSelectAll(allAssociates)}
                          isControlled
                        />
                      </div>
                    </div>
                  )}
                </>
              )}
              {isOpen && (
                <div className={classes.popperContent}>
                  <CustomScrollBar
                    customScrollBarYClass={classes.scrollY}
                    scrollBarHeight={getScrollbarHeight(
                      hasSelectAll,
                      allEmployees,
                      allAssociates
                    )}
                    verticalScroll
                    removeScrollX
                  >
                    {renderSuggestions()}
                  </CustomScrollBar>
                </div>
              )}
              {!isArrayEmpty(users) || hasSelectAll ? (
                <div className={classes.popoverFooter}>
                  <CustomButton
                    className={classes.selectButton}
                    type="withTextLightRounded"
                    onClick={handleAddUsers}
                  >
                    {translations.selectButtonLabel}
                  </CustomButton>
                </div>
              ) : null}
            </div>
          </Popper>
        </div>
      </ClickAwayListener>
      {showSelection &&
        !isArrayEmpty(preselectedUsers) &&
        renderSelectedUsers()}
    </div>
  );
};

PeoplePicker.defaultProps = {
  className: undefined,
  preselectedUsers: [],
  hasError: false,
  errorMessage: '',
  showDirectReports: false,
  isDisabled: false,
  hasSelectAll: false,
  showSelection: false,
  hasScrollbar: false,
  options: [],
  blacklistedUsers: [],
  dropdownWidth: null,
  shouldHandleTypeLabel: false,
  handleTypeLabel: () => {},
};

PeoplePicker.propTypes = {
  className: PropTypes.string,
  translations: PropTypes.object.isRequired,
  preselectedUsers: PropTypes.arrayOf(PropTypes.shape({})),
  blacklistedUsers: PropTypes.arrayOf(PropTypes.number),
  hasError: PropTypes.bool,
  errorMessage: PropTypes.string,
  dropdownWidth: PropTypes.string,
  isDisabled: PropTypes.bool,
  hasSelectAll: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.shape({})),
  showDirectReports: PropTypes.bool,
  showSelection: PropTypes.bool,
  hasScrollbar: PropTypes.bool,
  onSelect: PropTypes.func.isRequired,
  shouldHandleTypeLabel: PropTypes.bool,
  handleTypeLabel: PropTypes.func,
};

export default PeoplePicker;
