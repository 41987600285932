import PropTypes from 'prop-types';
import classNames from 'classnames';
import { TransitionGroup } from 'react-transition-group';
import { Fade, makeStyles } from '@material-ui/core';
import AbsenceType from './absenceType';

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  type: {
    display: 'flex',
    maxWidth: '100%',
    marginBottom: spacing(6),
    '&:last-of-type': {
      marginBottom: 0,
    },
  },
}));

const AbsenceTypes = ({
  className,
  translations,
  types,
  hasSetUsers,
  onEditType,
  onDeleteType,
  onGetTypeUsers,
  onAssignTypeUsers,
  onAddExtension,
  onEditExtension,
  onDeleteExtension,
  onGetExtensionUsers,
  onAssignExtensionUsers,
  onGoToProfilePage,
}) => {
  const classes = useStyles();

  return (
    <TransitionGroup className={classNames(classes.root, className)}>
      {types.map(type => (
        <Fade key={`absence_type_${type.id}`} in unmountOnExit>
          <div className={classes.type}>
            <AbsenceType
              translations={translations}
              type={type}
              hasSetUsers={hasSetUsers}
              onEditType={onEditType}
              onDeleteType={onDeleteType}
              onGetTypeUsers={onGetTypeUsers(type.id)}
              onAssignTypeUsers={onAssignTypeUsers(type)}
              onAddExtension={onAddExtension}
              onEditExtension={onEditExtension}
              onDeleteExtension={onDeleteExtension}
              onGetExtensionUsers={onGetExtensionUsers}
              onAssignExtensionUsers={onAssignExtensionUsers(type.id)}
              onGoToProfilePage={onGoToProfilePage}
            />
          </div>
        </Fade>
      ))}
    </TransitionGroup>
  );
};

AbsenceTypes.defaultProps = {
  className: undefined,
  types: [],
};

AbsenceTypes.propTypes = {
  className: PropTypes.string,
  translations: PropTypes.object.isRequired,
  types: PropTypes.arrayOf(PropTypes.object),
  hasSetUsers: PropTypes.bool.isRequired,
  onEditType: PropTypes.func.isRequired,
  onDeleteType: PropTypes.func.isRequired,
  onGetTypeUsers: PropTypes.func.isRequired,
  onAssignTypeUsers: PropTypes.func.isRequired,
  onAddExtension: PropTypes.func.isRequired,
  onEditExtension: PropTypes.func.isRequired,
  onDeleteExtension: PropTypes.func.isRequired,
  onGetExtensionUsers: PropTypes.func.isRequired,
  onAssignExtensionUsers: PropTypes.func.isRequired,
  onGoToProfilePage: PropTypes.func.isRequired,
};

export default AbsenceTypes;
