import { FIELD_TYPES } from '../constants/fieldTypes';
import { getObjectToNumberArray, isArrayEmpty } from './helpers';
import {
  forbiddenCharacters,
  isEmpty,
  validateLength,
  validateRange,
  validateUniqueName,
} from './validation';
import { ReactComponent as EditIcon } from '../assets/icons/edit-dark.svg';
import { ReactComponent as DuplicateIcon } from '../assets/icons/content_copy.svg';
import { ReactComponent as DeleteIcon } from '../assets/icons/delete-outlined.svg';
import { ReactComponent as MoveIcon } from '../assets/icons/undo.svg';

export const prepareLevelingLevelsTableData = (
  levels,
  renderDescription,
  renderAttributes,
  renderRange,
  renderPeople,
  renderActions,
  track,
  framework
) => {
  return levels.map(level => {
    return {
      id: level.id,
      description: renderDescription(level),
      attributes: renderAttributes(level.attributes),
      range: renderRange(level.attributes),
      people: renderPeople(level, track, framework),
      actions: renderActions(level, track, framework),
    };
  });
};

export const prepareLevelingTracksTableData = (
  tracks,
  renderDescription,
  renderAttributes,
  renderRange,
  renderPeople,
  renderActions,
  framework
) => {
  return tracks.map(track => ({
    ...track,
    levels: !isArrayEmpty(track.levels)
      ? prepareLevelingLevelsTableData(
          track.levels,
          renderDescription,
          renderAttributes,
          renderRange,
          renderPeople,
          renderActions,
          track,
          framework
        )
      : [],
  }));
};

export const prepareLevelingFrameworksData = (
  frameworks,
  renderDescription,
  renderAttributes,
  renderRange,
  renderPeople,
  renderActions = () => {}
) => {
  return frameworks?.map(framework => ({
    ...framework,
    tracks: !isArrayEmpty(framework.tracks)
      ? prepareLevelingTracksTableData(
          framework.tracks,
          renderDescription,
          renderAttributes,
          renderRange,
          renderPeople,
          renderActions,
          framework
        )
      : [],
  }));
};

export const getDotsMenuItems = (
  translations,
  record,
  recordParent,
  onDelete,
  onEdit,
  onDuplicate,
  onMove,
  hasMoveOption = false,
  frameworkParent = {}
) => [
  {
    type: 'edit',
    label: translations.edit,
    shouldItemRender: () => record.name,
    action: () => onEdit(record, recordParent, frameworkParent),
    icon: EditIcon,
  },
  {
    type: 'move',
    label: translations.move,
    shouldItemRender: () => hasMoveOption,
    action: () => onMove(recordParent, record, frameworkParent),
    icon: MoveIcon,
  },
  {
    type: 'duplicate',
    label: translations.duplicate,
    shouldItemRender: () => record.name,
    action: () => onDuplicate(recordParent, record, frameworkParent),
    icon: DuplicateIcon,
  },
  {
    type: 'delete',
    label: translations.delete,
    shouldItemRender: () => record.name,
    action: () => onDelete(record),
    icon: DeleteIcon,
  },
];

export const prepareInitialData = (data, isEdit, isLevel = false) => {
  const { RANGE_FROM, RANGE_TO } = FIELD_TYPES;
  if (isEdit) {
    return {
      id: data.id,
      name: data.name,
      ...(isLevel
        ? {
            attributes: data.attributes?.map(item => {
              return {
                ...item.attribute,
                [RANGE_FROM]: item[RANGE_FROM],
                [RANGE_TO]: item[RANGE_TO],
              };
            }),
          }
        : {}),
      ...(isLevel ? { description: data.description } : {}),
      ...(isLevel ? { users: data.users } : {}),
    };
  }
  return {
    name: '',
    ...(isLevel ? { attributes: [] } : {}),
    ...(isLevel ? { description: '' } : {}),
    ...(isLevel ? { users: [] } : {}),
  };
};

const LEVELING_INFO = {
  NAME: 'name',
  FRAMEWORK: 'framework',
  TRACK: 'track',
};

export const prepareInitialDataToMove = (
  framework,
  track,
  level,
  isLevel = false
) => {
  return {
    [LEVELING_INFO.NAME]: isLevel ? level.name : track.name,
    [LEVELING_INFO.FRAMEWORK]: framework?.id,
    ...(isLevel ? { [LEVELING_INFO.TRACK]: track?.id } : {}),
  };
};

export const getFieldsToMove = (subtitle = '', isLevel = false) => {
  return [
    {
      name: 'pathSubtitle',
      type: FIELD_TYPES.SUBTITLE,
      label: subtitle,
      variant: 'body2',
    },
    {
      name: 'name',
      type: FIELD_TYPES.TEXT,
      required: true,
      validators: [
        {
          validator: isEmpty,
          type: 'required',
        },
        {
          validator: forbiddenCharacters,
          type: 'forbiddenCharacters',
        },
        {
          validator: fieldValue => validateLength(fieldValue, 0, 255),
          type: 'titleMaxLength',
        },
      ],
    },
    {
      name: LEVELING_INFO.FRAMEWORK,
      type: FIELD_TYPES.SELECT,
      optionsKey: 'frameworks',
      parser: {
        value: 'id',
        label: 'name',
      },
      ...(isLevel
        ? {
            dependants: [
              {
                name: LEVELING_INFO.TRACK,
              },
            ],
          }
        : {}),
    },
    ...(isLevel
      ? [
          {
            name: LEVELING_INFO.TRACK,
            type: FIELD_TYPES.SELECT,
            optionsKey: 'tracks',
            parser: {
              value: 'id',
              label: 'name',
            },
            parent: {
              name: LEVELING_INFO.FRAMEWORK,
              options: 'frameworks',
            },
            validators: [{ validator: isEmpty, type: 'required' }],
          },
        ]
      : []),
  ];
};

export const getFields = (frameworks, subtitle = '') => {
  const isFramework = !isEmpty(subtitle);

  return [
    ...(!isFramework
      ? [
          {
            name: 'pathSubtitle',
            type: FIELD_TYPES.SUBTITLE,
            label: subtitle,
            variant: 'body2',
          },
        ]
      : []),
    {
      name: 'name',
      type: FIELD_TYPES.TEXT,
      required: true,
      shouldValidateInitialValue: true,
      validators: [
        {
          validator: isEmpty,
          type: 'required',
        },
        {
          validator: forbiddenCharacters,
          type: 'forbiddenCharacters',
        },
        {
          validator: fieldValue => validateLength(fieldValue, 0, 255),
          type: 'titleMaxLength',
        },
        ...(isFramework
          ? [
              {
                validator: value => validateUniqueName(value, frameworks),
                type: 'uniqueFrameworkName',
              },
            ]
          : []),
      ],
    },
  ];
};

export const getLevelFields = (subtitle = '') => {
  return [
    {
      name: 'pathSubtitle',
      type: FIELD_TYPES.SUBTITLE,
      label: subtitle,
      variant: 'body2',
    },
    {
      name: 'name',
      type: FIELD_TYPES.TEXT,
      required: true,
      validators: [
        {
          validator: isEmpty,
          type: 'required',
        },
        {
          validator: forbiddenCharacters,
          type: 'forbiddenCharacters',
        },
        {
          validator: fieldValue => validateLength(fieldValue, 0, 255),
          type: 'titleMaxLength',
        },
      ],
    },
    {
      name: 'description',
      type: FIELD_TYPES.RICH_TEXT_EDITOR,
      required: true,
      validators: [
        {
          type: 'levelMaxLength',
          validator: fieldValue => validateLength(fieldValue, 0, 5000),
        },
      ],
    },
    {
      name: 'attributes',
      type: FIELD_TYPES.SELECT_WITH_RANGE,
      optionsKey: 'attributesWithQuestions',
      isMulti: true,
      hasColorBox: true,
      validators: [
        {
          type: 'attributeRange',
          validator: value => validateRange(value),
        },
      ],
      parser: {
        value: 'id',
        label: 'name',
      },
    },
  ];
};

export const getAssignedUsersToLevelFields = (subtitle = '') => {
  return [
    {
      name: 'pathSubtitle',
      type: FIELD_TYPES.SUBTITLE,
      label: subtitle,
      variant: 'body2',
    },
    {
      name: 'assignUsers',
      type: FIELD_TYPES.SELECT,
      optionsKey: 'allUsers',
      isUser: true,
      isMulti: true,
      parser: {
        value: 'id',
        label: 'name',
      },
    },
  ];
};

export const getInitialUsersData = assignedTo => ({
  assignUsers: getObjectToNumberArray(assignedTo),
});
