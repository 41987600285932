import { createAction, handleActions } from 'redux-actions';
import http from '../../utility/http';
import { parseDuplicateParameters } from '../../utility/uiUtils';
import { isObjectEmpty } from '../../utility/helpers';
import {
  api_user_absences,
  api_user_absence_requests,
  api_user_absence_upcoming_time_off,
} from '../../constants/apiRoutes';
import {
  PEOPLE_ABSENCE_REQUESTS_DEFAULT_PARAMS,
  PEOPLE_ABSENCE_UPCOMING_TIME_OFF_DEFAULT_PARAMS,
  PARAMS,
} from '../../constants/pages';

// ------------------------------------
// Constants
// ------------------------------------

const SET_PERSON_ABSENCE_BALANCE = 'SET_PERSON_ABSENCE_BALANCE';
const SET_PERSON_ABSENCE_REQUESTS = 'SET_PERSON_ABSENCE_REQUESTS';
const LOAD_MORE_PERSON_ABSENCE_REQUESTS = 'LOAD_MORE_PERSON_ABSENCE_REQUESTS';
const SET_PERSON_UPCOMING_TIME_OFF = 'SET_PERSON_UPCOMING_TIME_OFF';
const LOAD_MORE_PERSON_UPCOMING_TIME_OFF = 'LOAD_MORE_PERSON_UPCOMING_TIME_OFF';
const CLEAR_PERSON_ABSENCES = 'CLEAR_PERSON_ABSENCES';

const initialState = {
  balance: [],
  requests: {},
  upcomingTimeOff: {},
};

// ------------------------------------
// Actions
// ------------------------------------

const setPersonAbsenceBalance = createAction(SET_PERSON_ABSENCE_BALANCE);
const setPersonAbsenceRequests = createAction(SET_PERSON_ABSENCE_REQUESTS);
const loadMorePersonAbsenceRequests = createAction(
  LOAD_MORE_PERSON_ABSENCE_REQUESTS
);
const setPersonUpcomingTimeOff = createAction(SET_PERSON_UPCOMING_TIME_OFF);
const loadMorePersonUpcomingTimeOff = createAction(
  LOAD_MORE_PERSON_UPCOMING_TIME_OFF
);
const clearAbsences = createAction(CLEAR_PERSON_ABSENCES);

// ------------------------------------
// Reducers
// ------------------------------------

const reducers = {
  [SET_PERSON_ABSENCE_BALANCE]: (state, { payload }) => {
    return { ...state, balance: payload };
  },
  [SET_PERSON_ABSENCE_REQUESTS]: (state, { payload }) => {
    return { ...state, requests: payload };
  },
  [LOAD_MORE_PERSON_ABSENCE_REQUESTS]: (state, { payload }) => {
    return isObjectEmpty(payload)
      ? { ...state, requests: payload }
      : {
          ...state,
          requests: {
            ...payload,
            results: state.requests.results
              ? [...state.requests.results, ...payload.results]
              : [...payload.results],
          },
        };
  },
  [SET_PERSON_UPCOMING_TIME_OFF]: (state, { payload }) => {
    return { ...state, upcomingTimeOff: payload };
  },
  [LOAD_MORE_PERSON_UPCOMING_TIME_OFF]: (state, { payload }) => {
    return isObjectEmpty(payload)
      ? { ...state, upcomingTimeOff: payload }
      : {
          ...state,
          upcomingTimeOff: {
            ...payload,
            results: state.upcomingTimeOff.results
              ? [...state.upcomingTimeOff.results, ...payload.results]
              : [...payload.results],
          },
        };
  },
  [CLEAR_PERSON_ABSENCES]: () => {
    return initialState;
  },
};

// ------------------------------------
// API calls
// ------------------------------------

export const getPersonAbsenceBalance = (dispatch, userId, year) => {
  return http
    .get(api_user_absences(userId), {
      params: {
        [PARAMS.YEAR]: year,
      },
      paramsSerializer: data => parseDuplicateParameters(data),
    })
    .then(({ data }) => dispatch(setPersonAbsenceBalance(data)));
};

export const getPersonAbsenceRequests = (
  dispatch,
  userId,
  isLoadMore = false,
  params = {}
) => {
  return http
    .get(api_user_absence_requests(userId), {
      params: {
        ...PEOPLE_ABSENCE_REQUESTS_DEFAULT_PARAMS,
        ...params,
      },
      paramsSerializer: data => parseDuplicateParameters(data),
    })
    .then(({ data }) =>
      dispatch(
        isLoadMore
          ? loadMorePersonAbsenceRequests(data)
          : setPersonAbsenceRequests(data)
      )
    );
};

export const getPersonUpcomingTimeOff = (
  dispatch,
  userId,
  isLoadMore = false,
  params = {}
) => {
  return http
    .get(api_user_absence_upcoming_time_off(userId), {
      params: {
        ...PEOPLE_ABSENCE_UPCOMING_TIME_OFF_DEFAULT_PARAMS,
        ...params,
      },
      paramsSerializer: data => parseDuplicateParameters(data),
    })
    .then(({ data }) =>
      dispatch(
        isLoadMore
          ? loadMorePersonUpcomingTimeOff(data)
          : setPersonUpcomingTimeOff(data)
      )
    );
};

export default handleActions(reducers, initialState);

// ------------------------------------
// "plain" action functions
// ------------------------------------

export const clearPersonAbsences = () => clearAbsences();
