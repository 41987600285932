import { ReactComponent as PaidLeaveIcon } from '../assets/icons/event_busy.svg';
import { ReactComponent as SickLeaveIcon } from '../assets/icons/local_hospital.svg';
import { ReactComponent as ParentalLeaveIcon } from '../assets/icons/escalator_warning.svg';
import { ReactComponent as PersonalDayIcon } from '../assets/icons/person.svg';
import { ReactComponent as UnpaidLeaveIcon } from '../assets/icons/money_off.svg';
import { ReactComponent as HolidayIcon } from '../assets/icons/celebration.svg';
import { primaryPalette } from '../styles/theme';

export const HOLIDAY = {
  id: 'HOLIDAY',
  icon: HolidayIcon,
  name: 'Holiday',
  key: 'holiday',
};

export const INITIAL_LEAVE_REQUESTS_SORT = {
  column: 'createdAt',
  asc: false,
  sortKey: '-created_at',
};

export const ACCRUAL_TYPES_KEYS = {
  YEARLY: 'YEARLY',
  MONTHLY: 'MONTHLY',
};

export const ACCRUAL_TYPES = [
  {
    value: ACCRUAL_TYPES_KEYS.YEARLY,
    name: 'Yearly',
    key: 'yearly',
  },
  {
    value: ACCRUAL_TYPES_KEYS.MONTHLY,
    name: 'Monthly',
    key: 'monthly',
  },
];

export const ABSENCE_TYPES_KEYS = {
  VACATION_LEAVE: 'VACATION_LEAVE',
  SICK_LEAVE: 'SICK_LEAVE',
  PERSONAL_DAYS: 'PERSONAL_DAYS',
  PARENTAL_LEAVE: 'PARENTAL_LEAVE',
  UNPAID_LEAVE: 'UNPAID_LEAVE',
};

export const ABSENCE_TYPES = [
  {
    id: ABSENCE_TYPES_KEYS.VACATION_LEAVE,
    name: 'Paid leave',
    key: 'vacationLeave',
    hasExtensions: true,
    color: primaryPalette.yellow5,
    icon: PaidLeaveIcon,
  },
  {
    id: ABSENCE_TYPES_KEYS.SICK_LEAVE,
    name: 'Sick leave',
    key: 'sickLeave',
    hasExtensions: false,
    color: primaryPalette.red6,
    icon: SickLeaveIcon,
  },
  {
    id: ABSENCE_TYPES_KEYS.PERSONAL_DAYS,
    name: 'Personal day',
    key: 'personalDays',
    hasExtensions: false,
    color: primaryPalette.blue10,
    icon: PersonalDayIcon,
  },
  {
    id: ABSENCE_TYPES_KEYS.PARENTAL_LEAVE,
    name: 'Parental leave',
    key: 'parentalLeave',
    hasExtensions: false,
    color: primaryPalette.blue9,
    icon: ParentalLeaveIcon,
  },
  {
    id: ABSENCE_TYPES_KEYS.UNPAID_LEAVE,
    name: 'Unpaid leave',
    key: 'unpaidLeave',
    hasExtensions: false,
    color: primaryPalette.red7,
    icon: UnpaidLeaveIcon,
  },
];
