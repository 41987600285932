import PropTypes from 'prop-types';
import { TransitionGroup } from 'react-transition-group';
import { Fade, makeStyles } from '@material-ui/core';
import Extension from './extension';

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    display: 'flex',
  },
  extension: {
    display: 'flex',
    maxWidth: '100%',
    marginLeft: spacing(2),
  },
}));

const Extensions = ({
  translations,
  extensions,
  hasTypeUsers,
  onEdit,
  onDelete,
  onGetUsers,
  onAssignUsers,
  onGoToProfilePage,
}) => {
  const classes = useStyles();

  return (
    <TransitionGroup className={classes.root}>
      {extensions.map(extension => (
        <Fade key={`extension_${extension.id}`} in unmountOnExit>
          <div className={classes.extension}>
            <Extension
              translations={translations}
              extension={extension}
              hasTypeUsers={hasTypeUsers}
              onEdit={onEdit}
              onDelete={onDelete}
              onGetUsers={onGetUsers(extension.id)}
              onAssignUsers={onAssignUsers(extension)}
              onGoToProfilePage={onGoToProfilePage}
            />
          </div>
        </Fade>
      ))}
    </TransitionGroup>
  );
};

Extensions.defaultProps = {
  extensions: [],
};

Extensions.propTypes = {
  translations: PropTypes.object.isRequired,
  extensions: PropTypes.arrayOf(PropTypes.object),
  hasTypeUsers: PropTypes.bool.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onGetUsers: PropTypes.func.isRequired,
  onAssignUsers: PropTypes.func.isRequired,
  onGoToProfilePage: PropTypes.func.isRequired,
};

export default Extensions;
