import { PureComponent, createRef } from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  FormHelperText,
  Drawer,
  Fade,
  withStyles,
} from '@material-ui/core';
import classNames from 'classnames';
import CustomButton from '../customButton';
import InputField from '../inputField';
import Autocomplete from '../autocomplete';
import SelectField from '../selectField';
import ToggleButtonSwitch from '../toggleButtonSwitch';
import SelectWithRangeField from '../selectWithRange';
import ColorPicker from '../colorPicker';
import Search from '../search';
import SelectCourseField from '../selectCourseField';
import AddImageField from '../addImageField';
import CustomScrollBar from '../customScrollBar';
import CustomCheckbox from '../customCheckbox';
import CheckboxGroup from '../checkboxGroup';
import CustomDatePicker from '../customDatePicker';
import CustomTimePicker from '../customTimePicker';
import SurveyPreview from '../surveyPreview';
import AttributeQuestionsForm from '../attributeQuestionsForm';
import AddUsersTableField from '../addUsersTableField';
import CustomRichTextEditor from '../customRichTextEditor';
import Impressions from '../impressions';
import PeoplePicker from '../peoplePicker';
import AddCoursesField from '../addCoursesField';
import ActionButton from '../actionButton';
import OneOnOneTimeIndicator from '../oneOnOneTimeIndicator';
import MeetingTopics from '../meetingTopics';
import CreatableTagsField from '../creatableTagsField';
import CustomSwitch from '../customSwitch';
import TasksField from '../tasksField';
import ProgressField from '../progressField';
import ReviewsField from '../reviewsField';
import PreviewField from '../previewField';
import SelectableListField from '../selectableListField';
import FiltersSetup from '../filtersSetup';
import MultipleDatePicker from '../multipleDatePicker';
import { formatDate } from '../../../utility/dateUtils';
import { getPersonFullName } from '../../../utility/uiUtils';
import { validateField } from '../../../utility/validation';
import {
  asyncDebounce,
  isArrayEmpty,
  isArray,
  getObjectToNumberArray,
  getFormattedUsers,
  removeObjectFromArray,
  replaceObjectInList,
} from '../../../utility/helpers';
import { getSelectFieldOptions } from '../../../utility/formUtils';
import { FIELD_TYPES } from '../../../constants/fieldTypes';
import { ACTION_PLAN_FIELDS } from '../../../constants/actionPlan';
import { AUTOMATION_ID } from '../../../constants/automationId';
import { ACTION_BUTTON_TYPES } from '../actionButton/config';

const SHORTNAME = 'code';
const SAVE = 'save';
const SAVE_AND_ADD = 'saveAndAdd';
const SAVE_TYPE = 'save_type';
const DIRTY = 'isDirty';

const styles = ({ palette: { primary }, spacing, breakpoints }, props) => ({
  wholeScreen: {
    width: '100vw',
  },
  fullWidth: {
    width: 'calc(100vw - 200px)',
    [breakpoints.up('xl')]: {
      width: 'calc(100vw - 245px)',
    },
  },
  middleWidth: {
    width: 940,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    maxHeight: 71,
    padding: spacing(5, 6),
    borderBottom: `1px solid ${primary.bluish5}`,
  },
  headerFullWidth: {
    padding: spacing(5, 8),
  },
  headerMiddleWidth: {},
  title: {
    marginRight: spacing(2),
    wordBreak: 'break-word',
  },
  content: {
    boxSizing: 'border-box',
    height: 'calc(100% - 144px)',
    maxHeight: 'calc(100% - 144px)',
    padding: spacing(6, 1, 0, 6),
  },
  contentWholeScreen: {
    padding: spacing(6, 8),
    maxWidth: '100vw',
  },
  contentFullWidth: {
    padding: spacing(6, 8),
    maxWidth: 'calc(100vw - 200px)',
  },
  contentMiddleWidth: {},
  columns: {
    display: 'grid',
    gridTemplateColumns: `repeat(${props?.columns?.length || 1}, 1fr)`,
    columnGap: 1,
    height: 'calc(100vh - 144px)',
    maxHeight: 'calc(100vh - 144px)',
  },
  columnScrollWrapper: {
    minHeight: 'calc(100vh - 144px)',
  },
  columnWrapper: {
    boxSizing: 'border-box',
    boxShadow: `1px 0 0 0 ${primary.bluish5}`,
    height: '100%',
    position: 'relative',
  },
  column: {
    boxSizing: 'border-box',
    height: '100%',
  },
  columnTitle: {
    marginBottom: spacing(6),
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    maxHeight: 73,
    padding: spacing(4, 6),
    borderTop: `1px solid ${primary.bluish5}`,
    height: 40,
  },
  footerFullWidth: {
    padding: spacing(4, 8),
  },
  headerActions: {
    marginLeft: 'auto',
    display: 'flex',
  },
  footerWithPreview: {
    justifyContent: 'space-between',
  },
  field: {
    marginBottom: spacing(6),
  },
  hasError: {
    marginBottom: spacing(1),
  },
  divider: {
    '&::after': {
      content: '""',
      backgroundColor: primary.bluish5,
      display: 'block',
      marginBottom: spacing(6),
      marginTop: spacing(6),
      height: 1,
      width: '100%',
    },
    '&$hasError': {
      marginBottom: 0,
      '&::after': {
        content: '""',
        backgroundColor: primary.bluish5,
        display: 'block',
        marginBottom: spacing(6),
        marginTop: spacing(1),
        height: 1,
        width: '100%',
      },
    },
  },
  saveButton: {
    backgroundColor: primary.blue1,
    border: `2px solid ${primary.blue1}`,
    fontFamily: 'ProximaNova-Bold',
    fontSize: 16,
    lineHeight: '24px',
    padding: spacing(2, 4),
    marginLeft: spacing(4),
  },
  saveButtonSecondary: {
    backgroundColor: primary.white,
    color: primary.blue1,
    marginLeft: 8,
    '&:hover': {
      backgroundColor: primary.white,
    },
  },
  cancelButton: {
    backgroundColor: primary.white,
    border: `1px solid ${primary.bluish1}`,
    borderRadius: 44,
    color: primary.bluish1,
    fontFamily: 'ProximaNova-Bold',
    fontSize: 16,
    lineHeight: '24px',
    padding: '8px 16px',
    maxHeight: 40,
  },
  saveAndAddButton: {
    backgroundColor: primary.blue1,
    border: `1px solid ${primary.blue1}`,
    borderRadius: 44,
    color: primary.white,
    fontFamily: 'ProximaNova-Bold',
    fontSize: 16,
    lineHeight: '24px',
    padding: '8px 16px',
    marginLeft: 8,
    maxHeight: 40,
    '&:hover': {
      backgroundColor: primary.blue1,
    },
  },
  highlightedLabel: {
    fontFamily: 'ProximaNova-Bold',
    fontSize: 18,
    lineHeight: '20px',
  },
  checkbox: {
    alignItems: 'flex-start',
  },
  deleteIcon: {
    marginRight: spacing(1),
  },
  scrollX: {
    backgroundColor: primary.bluish9,
    left: 24,
    bottom: 6,
    width: 'calc(100% - 48px)',
    height: 8,
  },
  scrollXFullWidth: {
    left: 31,
  },
  scrollY: {
    backgroundColor: primary.bluish9,
    top: 24,
    right: 6,
    height: 'calc(100% - 48px)',
    width: 8,
  },
  scrollYColumn: {
    top: 20,
    right: 6,
    width: 6,
    height: 'calc(100% - 44px)',
    [breakpoints.up('xLg')]: {
      right: 8,
      width: 8,
    },
  },
  scrollYFullWidth: {
    top: 31,
  },
  scroll: {
    backgroundColor: primary.bluish7,
  },
  scroller: {
    marginRight: spacing(5),
    marginBottom: spacing(5),
  },
  createdBy: {
    color: primary.bluish4,
  },
  readOnly: {
    wordBreak: 'break-word',
  },
  preview: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  textEditor: {
    '& .ql-toolbar': {
      borderTopLeftRadius: 3,
      borderTopRightRadius: 3,
    },
    '& .ql-container': {
      height: 188,
      borderBottomLeftRadius: 3,
      borderBottomRightRadius: 3,
      '& .ql-blank::before': {
        lineHeight: '24px',
      },
    },
  },
  privateNoteError: {
    bottom: -24,
  },
  meetingStatus: {
    marginBottom: spacing(3),
  },
  notifyAll: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 'auto',
  },
  checkboxLabel: {
    width: '100%',
    maxWidth: 'calc(100% - 24px)',
    flexShrink: 0,
  },
});

const {
  CLOSE_BUTTON,
  DELETE_BUTTON,
  CANCEL_BUTTON,
  SAVE_BUTTON,
  SAVE_AND_ADD_BUTTON,
} = AUTOMATION_ID;

class CustomFormDrawerLayout extends PureComponent {
  constructor(props) {
    super(props);
    this.handleValidateSingleField = asyncDebounce(
      this.handleValidateSingleField
    );

    this.scrollBar = createRef();
    this.state = {
      scrollLeft: 0,
      isExpandedOnFirstOpen: true,
      isExpandedOnFirstOpenFramework: true,
    };
  }

  resetDependants = ({
    fieldDependants,
    reverse,
    fieldName,
    currentFieldValue,
  }) => {
    const { setFieldValue, setFieldError, values, initialValues } = this.props;

    if (fieldDependants && fieldDependants.length) {
      fieldDependants.map(async dependant => {
        if (dependant.callbackFnc) {
          dependant.callbackFnc(
            values[fieldName],
            currentFieldValue,
            values[dependant.name]
          );
        } else if (dependant.resetFnc) {
          const dependantValue = await dependant.resetFnc(
            values[fieldName],
            currentFieldValue,
            values[dependant.name],
            initialValues,
            values
          );
          setFieldValue(dependant.name, dependantValue);
        } else {
          setFieldValue(
            dependant.name,
            reverse && dependant.reverse ? !dependant.value : dependant.value
          );
        }

        return setFieldError(dependant.name, null);
      });
    }
  };

  getFieldOptions = field => {
    const { values } = this.props;
    if (
      field.parent &&
      isArray(values[field.parent]) &&
      !isArrayEmpty(values[field.parent])
    ) {
      return this.props[field.optionsKey].filter(
        value =>
          !values[field.parent].some(
            parentValue =>
              parentValue === value || parentValue?.id === value?.id
          )
      );
    }

    return this.props[field.optionsKey] || [];
  };

  handleClose = () => {
    const { onClose, resetForm } = this.props;

    onClose();
    resetForm();
  };

  handleValidateSingleField = (
    field,
    value,
    initialValue,
    values,
    canSkipValidation
  ) => {
    const { setFieldError } = this.props;

    return validateField(
      field,
      value,
      initialValue,
      values,
      canSkipValidation
    ).then(errorType => {
      setFieldError(field.name, errorType);
      return errorType;
    });
  };

  handleInputChange = async (e, field) => {
    const { setFieldValue, initialValues } = this.props;

    const updatedValue =
      field.name === SHORTNAME ? e.target.value.toUpperCase() : e.target.value;

    this.handleValidateSingleField(
      field,
      updatedValue,
      initialValues[field.name]
    );

    setFieldValue(field.name, updatedValue);
  };

  handleAddUsersChange = field => (value, rowIndex) => {
    const { setFieldValue, initialValues } = this.props;
    this.handleValidateSingleField(
      {
        ...field,
        validators: [
          {
            validator: users =>
              field.usersValidator(
                users,
                field.columns,
                initialValues[field.name],
                rowIndex
              ),
            isMultiple: true,
          },
        ],
      },
      value
    );
    setFieldValue(field.name, value);
  };

  handleChangeSelectWithRangeValue = field => value => {
    const { setFieldValue } = this.props;

    this.handleValidateSingleField(field, value);
    setFieldValue(field.name, value);
  };

  handleChangeRange = field => (value, item, fieldName) => {
    const { setFieldValue, values } = this.props;

    if (item[fieldName] !== value) {
      const attributeIndex = values[field.name].findIndex(
        attr => attr.id === item.id
      );
      const newAttributes = replaceObjectInList(
        values[field.name],
        attributeIndex,
        {
          ...item,
          [fieldName]: value,
        }
      );
      this.handleValidateSingleField(field, newAttributes);
      setFieldValue(field.name, newAttributes);
    }
  };

  handleChangeSelectValue = field => value => {
    const { setFieldValue, initialValues, values } = this.props;
    if (values[field.name] !== value) {
      if (field.callbackFnc) {
        return field.callbackFnc(value);
      }

      this.handleValidateSingleField(field, value, initialValues[field.name]);
      this.resetDependants({
        fieldDependants: field.dependants,
        fieldName: field.name,
        currentFieldValue: value,
      });
      setFieldValue(field.name, value);
    }
  };

  handleDateTimeChange = field => value => {
    const { initialValues, values, setFieldValue } = this.props;
    const formatedValue = field.valueFormat
      ? formatDate(value, field.valueFormat)
      : value;

    this.handleValidateSingleField(
      field,
      formatedValue,
      initialValues[field.name],
      values
    );
    this.resetDependants({
      fieldDependants: field.dependants,
      fieldName: field.name,
      currentFieldValue: formatedValue,
    });

    setFieldValue(field.name, formatedValue);
  };

  handleMeetingTopicsChange = field => value => {
    const { setFieldValue } = this.props;

    this.handleValidateSingleField(field, value);
    setFieldValue(field.name, value);
  };

  handleRecommendedPlacementChange = field => placements => {
    const { setFieldValue } = this.props;

    this.handleValidateSingleField(field, placements);
    setFieldValue(field.name, placements);
  };

  handleSearch = field => searchTerm => {
    const { values, setFieldValue } = this.props;

    this.resetDependants({ fieldDependants: field.dependants });
    setFieldValue(field.name, searchTerm);
    field.onSearch(searchTerm, values);
  };

  handleToggleChange = field => value => {
    const { setFieldValue, resetForm } = this.props;
    setFieldValue(field.name, value);

    if (field.onValueChange) {
      field.onValueChange(value, { resetForm });
    }
  };

  handlePhotoUpload = field => photo => {
    const { setFieldValue } = this.props;
    this.handleValidateSingleField(field, photo);
    setFieldValue(field.name, photo);
  };

  handleAutocompleteInputChange = async (value, field) => {
    const { onAutoCompleteChange, setFieldValue, initialValues } = this.props;
    this.resetDependants({
      fieldDependants: field.dependants,
      fieldName: field.name,
      currentFieldValue: value,
    });
    setFieldValue(field.name, []);
    this.handleValidateSingleField(field, [], initialValues[field.name]);
    await onAutoCompleteChange(value);
  };

  handleAutocompleteSelect = async (field, selectedValues, dependants) => {
    const { initialValues, setFieldValue, onAutoCompleteChange } = this.props;

    this.resetDependants({
      fieldDependants: dependants,
      fieldName: field.name,
      currentFieldValue: selectedValues,
    });
    this.handleValidateSingleField(
      field,
      selectedValues,
      initialValues[field.name]
    );
    await onAutoCompleteChange();
    setFieldValue(field.name, selectedValues);
  };

  handleCheckboxChange = field => value => {
    const { setFieldValue, values, initialValues } = this.props;

    this.resetDependants({
      fieldName: field.name,
      fieldDependants: field.dependants,
      currentFieldValue: field.isReverse ? !value : value,
    });
    this.handleValidateSingleField(
      field,
      field.isReverse ? !value : value,
      initialValues[field.name],
      values
    );
    setFieldValue(field.name, field.isReverse ? !value : value);
  };

  handleSliderChange = field => value => {
    const { values, setFieldValue } = this.props;

    if (value !== values[field.name]) {
      setFieldValue(field.name, value);
    }
  };

  handleCheckboxGroupChange = fieldName => itemId => {
    const { values, setFieldValue } = this.props;
    const { NOTIFY } = ACTION_PLAN_FIELDS;
    const checkedItems = values[NOTIFY];
    const isChecked = checkedItems.some(item => item === itemId);

    if (isChecked) {
      return setFieldValue(
        fieldName,
        checkedItems.filter(item => item !== itemId)
      );
    }

    return setFieldValue(fieldName, [...checkedItems, itemId]);
  };

  handleTasksChange = field => value => {
    const { initialValues, setFieldValue } = this.props;

    this.handleValidateSingleField(field, value, initialValues[field.name]);
    this.resetDependants({
      fieldDependants: field.dependants,
      fieldName: field.name,
      currentFieldValue: value,
    });
    setFieldValue(field.name, value);
  };

  handleAddCourse = field => value => {
    const { values, setFieldValue } = this.props;
    const selectedCourses = values[field.name];
    const isSelected = selectedCourses.some(course => course.id === value.id);

    if (isSelected) {
      return setFieldValue(
        field.name,
        removeObjectFromArray(selectedCourses, value)
      );
    }

    return setFieldValue(field.name, [...selectedCourses, value]);
  };

  handleReviewChange = field => value => {
    const { setFieldValue } = this.props;

    this.resetDependants({
      fieldName: field.name,
      fieldDependants: field.dependants,
      currentFieldValue: value,
    });

    this.handleValidateSingleField(field, value, null, null, true);

    setFieldValue(field.name, value);
  };

  handleSelectableListChange = field => value => {
    const { setFieldValue } = this.props;

    this.handleValidateSingleField(field, value);
    this.setState({
      isExpandedOnFirstOpen: true,
      isExpandedOnFirstOpenFramework: true,
    });

    setFieldValue(field.name, value);
  };

  renderTitle = titleText => {
    const { translations, values, getTitle } = this.props;
    const customTitle = getTitle(translations, values, !!values.id);

    if (customTitle) {
      return customTitle;
    }

    if (titleText) {
      return titleText;
    }
    if (values.id) {
      return translations.edit;
    }
    return translations.add;
  };

  renderField = field => {
    const {
      classes,
      values,
      translations,
      errors,
      setFieldValue,
      setFieldError,
      setDialogVisibility,
      dialogs,
      updateAttribute,
      deleteAllAttributeQuestions,
      createQuestion,
      setAttribute,
      reorderQuestions,
      setAttributeQuestions,
      updateAttributeQuestions,
      updateAttributeQuestion,
      deleteAttributeQuestion,
      getAttribute,
      onAutoCompleteChange,
      initialValues,
    } = this.props;

    const {
      ADD_COURSES,
      ADD_USERS_TABLE,
      ADD_IMAGE,
      TEXT,
      AUTOCOMPLETE,
      SELECT,
      SELECT_WITH_RANGE,
      BUTTON_SWITCH,
      COLOR_PICKER,
      CREATED_BY,
      CREATABLE_TAGS,
      ATTRIBUTES_QUESTIONS_FORM,
      SEARCH,
      SELECT_COURSE,
      SELECTABLE_LIST,
      CHECKBOX_GROUP,
      DATE_PICKER,
      RICH_TEXT_EDITOR,
      SUBTITLE,
      TIME_PICKER,
      SURVEY_PREVIEW,
      PREVIEW,
      CHECKBOX,
      READ_ONLY,
      PEOPLE_PICKER,
      MEETING_IMPRESSION,
      MEETING_STATUS,
      MEETING_AGENDA,
      MULTIPLE_DATE_PICKER,
      FILTERS_SETUP,
      SWITCH,
      SLIDER,
      TASKS,
      REVIEWS,
    } = FIELD_TYPES;
    const { formLabels, validationMessages } = translations;
    const { labelIcon: LabelIcon } = field;
    const fieldClasses = classNames(
      classes.field,
      {
        [classes.hasError]: !!errors[field.name],
        [classes.divider]: field.hasDivider,
      },
      field.fieldWrapperClass
    );
    switch (field.type) {
      case TEXT:
        if (field.isToggleable) {
          return (
            <Fade
              key={`input_field_${field.name}`}
              in={field.shouldRender(values)}
              unmountOnExit
            >
              <div key={field.name} className={fieldClasses}>
                <InputField
                  id={field.name}
                  name={field.name}
                  type={field.inputType || field.type}
                  customRootClass={field.customRootClass}
                  label={formLabels[field.translationKey || field.name].label}
                  labelExplanation={
                    formLabels[field.translationKey || field.name]
                      .labelExplanation
                  }
                  placeholder={
                    formLabels[field.translationKey || field.name]
                      .placeholder || field.placeholder
                  }
                  required={field.required}
                  value={
                    field.inputType === 'number'
                      ? values[field.name]
                      : values[field.name] || ''
                  }
                  multiline={field.multiline}
                  rows={field.rows}
                  startAdornment={
                    field.startAdornment
                      ? field.startAdornment(values)
                      : undefined
                  }
                  error={!!errors[field.name]}
                  errorMessage={validationMessages[errors[field.name]]}
                  onChange={e => this.handleInputChange(e, field)}
                  disabled={field.isDisabled}
                  adornmentIcon={field.adornmentIcon}
                  isIconOnStart={field.isIconOnStart}
                  labelHelp={
                    formLabels[field.translationKey || field.name].labelHelp
                  }
                  fullWidth
                />
              </div>
            </Fade>
          );
        }

        return (
          <div key={field.name} className={fieldClasses}>
            <InputField
              id={field.name}
              name={field.name}
              type={field.inputType || field.type}
              customRootClass={field.customRootClass}
              label={formLabels[field.translationKey || field.name].label}
              labelExplanation={
                formLabels[field.translationKey || field.name].labelExplanation
              }
              placeholder={
                formLabels[field.translationKey || field.name].placeholder ||
                field.placeholder
              }
              required={field.required}
              value={
                field.inputType === 'number'
                  ? values[field.name]
                  : values[field.name] || ''
              }
              multiline={field.multiline}
              rows={field.rows}
              startAdornment={
                field.startAdornment ? field.startAdornment(values) : undefined
              }
              error={!!errors[field.name]}
              errorMessage={validationMessages[errors[field.name]]}
              onChange={e => this.handleInputChange(e, field)}
              disabled={field.isDisabled}
              adornmentIcon={field.adornmentIcon}
              isIconOnStart={field.isIconOnStart}
              labelHelp={
                formLabels[field.translationKey || field.name].labelHelp
              }
              fullWidth
            />
          </div>
        );
      case SELECT:
        if (field.isToggleable) {
          return (
            <Fade
              key={`select_field_${field.name}`}
              in={field.shouldRender(values)}
              unmountOnExit
            >
              <div key={field.name} className={fieldClasses}>
                <SelectField
                  id={field.name}
                  labelClass={field.labelClass}
                  label={formLabels[field.translationKey || field.name]?.label}
                  shouldRemoveLabel={field.shouldRemoveLabel}
                  labelHelp={
                    formLabels[field.translationKey || field.name]?.labelHelp
                  }
                  placeholder={
                    formLabels[field.translationKey || field.name]?.placeholder
                  }
                  creatableOptionLabel={
                    formLabels[field.translationKey || field.name]
                      ?.creatableOptionLabel
                  }
                  startIcon={field.startIcon}
                  required={field.required}
                  options={getSelectFieldOptions(
                    field,
                    values,
                    this.props[field.optionsKey],
                    this.props[field?.parent?.options]
                  )}
                  parser={field.parser}
                  value={values[field.name]}
                  renderRolesTooltip={
                    formLabels[field.name]?.renderRolesTooltip
                  }
                  creatableOptionField={field.creatableOptionField}
                  shouldReturnOption={field.shouldReturnOption}
                  shouldDisableSort={field.shouldDisableSort}
                  isCategorized={field.isCategorized}
                  isDisabled={
                    (field.parent && !values[field.parent.name]) ||
                    (field.isDisabledCheck
                      ? field.isDisabledCheck(values)
                      : undefined) ||
                    field.isDisabled
                  }
                  isTag={field.isTag}
                  isUser={field.isUser}
                  isAttribute={field.isAttribute}
                  isAttributeDraggable={field.isAttributeDraggable}
                  hasHash={field.hasHash}
                  hasCategoryColorBox={field.hasCategoryColorBox}
                  isSearchDisabled={field.isSearchDisabled}
                  isMulti={field.isMulti}
                  isCreatable={field.isCreatable}
                  hasError={!!errors[field.name]}
                  errorMessage={validationMessages?.[errors[field.name]]}
                  isClearable={field.isClearable}
                  hasColorBox={field.hasColorBox}
                  onCreateOption={field.onCreateOption}
                  onChange={this.handleChangeSelectValue(field)}
                  customStyles={field.customStyles}
                  isFullWidth
                />
              </div>
            </Fade>
          );
        }
        return (
          <div key={field.name} className={fieldClasses}>
            <SelectField
              id={field.name}
              labelClass={field.labelClass}
              label={formLabels[field.translationKey || field.name]?.label}
              shouldRemoveLabel={field.shouldRemoveLabel}
              labelHelp={
                formLabels[field.translationKey || field.name]?.labelHelp
              }
              placeholder={
                formLabels[field.translationKey || field.name]?.placeholder
              }
              creatableOptionLabel={
                formLabels[field.translationKey || field.name]
                  ?.creatableOptionLabel
              }
              startIcon={field.startIcon}
              required={field.required}
              options={getSelectFieldOptions(
                field,
                values,
                this.props[field.optionsKey],
                this.props[field?.parent?.options]
              )}
              parser={field.parser}
              value={values[field.name]}
              renderRolesTooltip={formLabels[field.name]?.renderRolesTooltip}
              creatableOptionField={field.creatableOptionField}
              shouldReturnOption={field.shouldReturnOption}
              shouldDisableSort={field.shouldDisableSort}
              isCategorized={field.isCategorized}
              isDisabled={
                (field.parent && !values[field.parent.name]) ||
                (field.isDisabledCheck
                  ? field.isDisabledCheck(values)
                  : undefined) ||
                field.isDisabled
              }
              isTag={field.isTag}
              isUser={field.isUser}
              isAttribute={field.isAttribute}
              isAttributeDraggable={field.isAttributeDraggable}
              hasHash={field.hasHash}
              hasCategoryColorBox={field.hasCategoryColorBox}
              isSearchDisabled={field.isSearchDisabled}
              isMulti={field.isMulti}
              isCreatable={field.isCreatable}
              hasError={!!errors[field.name]}
              errorMessage={validationMessages?.[errors[field.name]]}
              isClearable={field.isClearable}
              hasColorBox={field.hasColorBox}
              onCreateOption={field.onCreateOption}
              onChange={this.handleChangeSelectValue(field)}
              customStyles={field.customStyles}
              isFullWidth
            />
          </div>
        );
      case SELECT_WITH_RANGE:
        return (
          <div key={field.name} className={fieldClasses}>
            <SelectWithRangeField
              id={field.name}
              labelClass={field.labelClass}
              label={formLabels[field.translationKey || field.name]?.label}
              shouldRemoveLabel={field.shouldRemoveLabel}
              labelHelp={
                formLabels[field.translationKey || field.name]?.labelHelp
              }
              placeholder={
                formLabels[field.translationKey || field.name]?.placeholder
              }
              creatableOptionLabel={
                formLabels[field.translationKey || field.name]
                  ?.creatableOptionLabel
              }
              startIcon={field.startIcon}
              required={field.required}
              options={getSelectFieldOptions(
                field,
                values,
                this.props[field.optionsKey],
                this.props[field?.parent?.options]
              )}
              parser={field.parser}
              value={values[field.name]}
              renderRolesTooltip={formLabels[field.name]?.renderRolesTooltip}
              creatableOptionField={field.creatableOptionField}
              shouldReturnOption={field.shouldReturnOption}
              shouldDisableSort={field.shouldDisableSort}
              isCategorized={field.isCategorized}
              isDisabled={
                (field.parent && !values[field.parent.name]) ||
                (field.isDisabledCheck
                  ? field.isDisabledCheck(values)
                  : undefined) ||
                field.isDisabled
              }
              isAttributeDraggable={field.isAttributeDraggable}
              hasHash={field.hasHash}
              hasCategoryColorBox={field.hasCategoryColorBox}
              isSearchDisabled={field.isSearchDisabled}
              isCreatable={field.isCreatable}
              hasError={!!errors[field.name]}
              errorMessage={validationMessages[errors[field.name]]}
              isClearable={field.isClearable}
              hasColorBox={field.hasColorBox}
              onCreateOption={field.onCreateOption}
              onChange={this.handleChangeSelectWithRangeValue(field)}
              onChangeRange={this.handleChangeRange(field)}
              customStyles={field.customStyles}
              isFullWidth
            />
          </div>
        );
      case AUTOCOMPLETE:
        return (
          <div
            key={field.name}
            className={classNames(
              classes.field,
              {
                [classes.divider]: field.hasDivider,
              },
              field.fieldWrapperClass
            )}
          >
            <Autocomplete
              id={field.name}
              name={field.name}
              label={formLabels[field.translationKey || field.name].label}
              placeholder={
                formLabels[field.translationKey || field.name].placeholder
              }
              noOptionsLabel={
                formLabels[field.translationKey || field.name].noOptions
              }
              items={this.getFieldOptions(field)}
              selectedItems={values[field.name] || []}
              shouldRenderSelectedOutside={field.shouldRenderSelectedOutside}
              isAttribute={field.isAttribute}
              isAsync={field.isAsync}
              isSingleSelect={field.isSingleSelect}
              hasAvatar={field.hasAvatar}
              hasError={!!errors[field.name]}
              organizationUser={this.props[field.organizationUserKey]}
              errorMessage={validationMessages[errors[field.name]]}
              isDisabled={
                field.isDisabled ||
                (field?.parent &&
                  field.parent?.isDisabled &&
                  field.parent?.isDisabled(values[field.parent.name]))
              }
              initialValue={field.initialValue}
              adornmentIcon={field.adornmentIcon}
              isIconOnStart={field.isIconOnStart}
              onChange={selectedValues =>
                this.handleAutocompleteSelect(
                  field,
                  selectedValues,
                  field.dependants
                )
              }
              onInputChange={value =>
                this.handleAutocompleteInputChange(value, field)
              }
              onBlur={() => onAutoCompleteChange()}
            />
          </div>
        );
      case SELECTABLE_LIST:
        return (
          <div key={field.name} className={fieldClasses}>
            <SelectableListField
              options={field.options}
              label={field.label}
              selected={values[field.name]}
              hasError={!!errors[field.name]}
              errorMessage={validationMessages[errors[field.name]]}
              onSelect={this.handleSelectableListChange(field)}
            />
          </div>
        );
      case BUTTON_SWITCH:
        return (
          <div
            key={field.name}
            className={classNames(classes.field, field.fieldWrapperClass)}
          >
            <ToggleButtonSwitch
              id={field.name}
              label={formLabels[field.translationKey || field.name].label}
              value={values[field.name]}
              items={field.items}
              onSelect={this.handleToggleChange(field)}
            />
          </div>
        );
      case COLOR_PICKER:
        return (
          <div
            key={field.name}
            className={classNames(classes.field, field.fieldWrapperClass)}
          >
            <ColorPicker
              label={formLabels[field.translationKey || field.name].label}
              tooltipText={
                formLabels[field.translationKey || field.name].tooltip
              }
              shouldOpenPicker={field.shouldOpenPicker}
              selectedColor={values[field.name]}
              colors={field.colors}
              onColorPick={color => setFieldValue(field.name, color)}
            />
          </div>
        );
      case CREATED_BY:
        return (
          <div key={field.name} className={field.fieldWrapperClass}>
            <FormHelperText>
              {formLabels[field.translationKey || field.name].label}
            </FormHelperText>
            <Typography variant="subtitle1" className={classes.createdBy}>
              {getPersonFullName(values[field.name])}
            </Typography>
          </div>
        );
      case SEARCH:
        return (
          <div key={field.name} className={fieldClasses}>
            <Search
              label={formLabels[field.translationKey || field.name].label}
              placeholder={
                formLabels[field.translationKey || field.name].placeholder
              }
              onChange={this.handleSearch(field)}
              isFormField
            />
          </div>
        );

      case SELECT_COURSE:
        return (
          <div key={field.name} className={fieldClasses}>
            <SelectCourseField
              label={formLabels[field.translationKey || field.name].label}
              noResults={
                formLabels[field.translationKey || field.name].noResults
              }
              isRequired={field.required}
              courses={this.props[field.optionsKey] || []}
              selectedCourse={values[field.name]}
              hasError={!!errors[field.name]}
              errorMessage={validationMessages[errors[field.name]]}
              onSelectCourse={this.handleChangeSelectValue(field)}
            />
          </div>
        );
      case ADD_IMAGE:
        return (
          <div key={field.name} className={fieldClasses}>
            <AddImageField
              label={formLabels[field.translationKey || field.name].label}
              placeholder={
                formLabels[field.translationKey || field.name].placeholder
              }
              image={values[field.name] || null}
              hasError={!!errors[field.name]}
              errorMessage={validationMessages[errors[field.name]]}
              onAddImage={this.handlePhotoUpload(field)}
            />
          </div>
        );
      case CHECKBOX:
        return (
          <div key={field.name} className={fieldClasses}>
            <CustomCheckbox
              className={field.className}
              customRootClass={classNames({
                [classes.checkbox]: field.shouldTopAlign,
              })}
              labelTextClass={classes.checkboxLabel}
              isChecked={
                field.isReverse ? !values[field.name] : values[field.name]
              }
              label={formLabels[field.translationKey || field.name].label}
              labelIcon={LabelIcon}
              endLabelIcon={field.endLabelIcon}
              labelText={
                formLabels[field.translationKey || field.name].labelText
              }
              labelHelp={
                formLabels[field.translationKey || field.name].labelHelp
              }
              endLabelIconHelp={
                formLabels[field.translationKey || field.name].endLabelIconHelp
              }
              hasError={!!errors[field.name]}
              errorMessage={validationMessages[errors[field.name]]}
              isControlled={field.isControlled}
              isHighlightedLabel={field.isHighlightedLabel}
              disabled={
                field.isDisabled ||
                (field.isDisabledCheck
                  ? field.isDisabledCheck(values)
                  : undefined)
              }
              smallText={field.smallText}
              onChange={this.handleCheckboxChange(field)}
            />
          </div>
        );
      case CHECKBOX_GROUP:
        return (
          <div key={field.name} className={fieldClasses}>
            <CheckboxGroup
              label={formLabels[field.translationKey || field.name].label}
              labelHelp={
                formLabels[field.translationKey || field.name]?.labelHelp
              }
              checkedItems={values[field.name]}
              checkboxItems={field.getItems(values)}
              onChange={this.handleCheckboxGroupChange(field.name)}
            />
          </div>
        );
      case DATE_PICKER:
        if (field.isToggleable) {
          return (
            <Fade
              key={`date_picker_${field.name}`}
              in={field.shouldRender(values)}
              unmountOnExit
            >
              <div className={fieldClasses}>
                <CustomDatePicker
                  name={field.name}
                  label={formLabels[field.translationKey || field.name]?.label}
                  placeholder={
                    formLabels[field.translationKey || field.name]?.placeholder
                  }
                  tooltipInfo={
                    formLabels[field.translationKey || field.name]?.tooltipInfo
                  }
                  value={values[field.name] ? values[field.name] : null}
                  error={!!errors[field.name]}
                  errorMessage={validationMessages[errors[field.name]]}
                  required={field.required}
                  disableFuture={field.disableFuture}
                  disablePast={field.isPastDisabled}
                  disableToolbar={field.isToolbarDisabled}
                  disabled={
                    field.isDisabled ||
                    (field.isDisabledCheck
                      ? field.isDisabledCheck(values)
                      : undefined)
                  }
                  minDate={
                    field.getMinDate
                      ? field.getMinDate(
                          values[field.name],
                          values,
                          initialValues
                        )
                      : undefined
                  }
                  maxDate={
                    field.getMaxDate
                      ? field.getMaxDate(
                          values[field.name],
                          values,
                          initialValues
                        )
                      : undefined
                  }
                  onChange={this.handleDateTimeChange(field)}
                  fullWidth
                  autoOk
                  isIconOnStart
                />
              </div>
            </Fade>
          );
        }

        return (
          <div key={`date_picker_${field.name}`} className={fieldClasses}>
            <CustomDatePicker
              name={field.name}
              label={formLabels[field.translationKey || field.name]?.label}
              placeholder={
                formLabels[field.translationKey || field.name]?.placeholder
              }
              tooltipInfo={
                formLabels[field.translationKey || field.name]?.tooltipInfo
              }
              value={values[field.name] ? values[field.name] : null}
              error={!!errors[field.name]}
              errorMessage={validationMessages[errors[field.name]]}
              required={field.required}
              disableFuture={field.disableFuture}
              disablePast={field.isPastDisabled}
              disableToolbar={field.isToolbarDisabled}
              disabled={
                field.isDisabled ||
                (field.isDisabledCheck
                  ? field.isDisabledCheck(values)
                  : undefined)
              }
              minDate={
                field.getMinDate
                  ? field.getMinDate(values[field.name], values, initialValues)
                  : undefined
              }
              maxDate={
                field.getMaxDate
                  ? field.getMaxDate(values[field.name], values, initialValues)
                  : undefined
              }
              onChange={this.handleDateTimeChange(field)}
              fullWidth
              autoOk
              isIconOnStart
            />
          </div>
        );

      case MULTIPLE_DATE_PICKER:
        return (
          <div
            key={`multiple_date_picker_${field.name}`}
            className={classes.field}
          >
            <MultipleDatePicker
              label={formLabels[field.translationKey || field.name]?.label}
              placeholder={
                formLabels[field.translationKey || field.name]?.placeholder
              }
              tooltipInfo={
                formLabels[field.translationKey || field.name]?.tooltipInfo
              }
              addDateButtonLabel={
                formLabels[field.translationKey || field.name]
                  ?.addDateButtonLabel
              }
              value={values[field.name] || []}
              minDate={
                field.getMinDate
                  ? field.getMinDate(values[field.name], values, initialValues)
                  : undefined
              }
              maxDate={
                field.getMaxDate
                  ? field.getMaxDate(values[field.name], values, initialValues)
                  : undefined
              }
              errors={errors[field.name]}
              errorMessages={validationMessages}
              onChange={this.handleDateTimeChange(field)}
              isFullWidth
              autoOk
              isIconOnStart
            />
          </div>
        );
      case RICH_TEXT_EDITOR:
        return (
          <div
            key={field.name}
            className={classNames(
              classes.field,
              {
                [classes.divider]: field.hasDivider,
              },
              field.fieldWrapperClass
            )}
          >
            <CustomRichTextEditor
              customLabelWrapperClass={field.labelWrapperClass}
              customLabelClass={field.labelClass}
              customEditorClass={classes.textEditor}
              customErrorClass={classes.privateNoteError}
              label={formLabels[field.translationKey || field.name]?.label}
              placeholder={
                formLabels[field.translationKey || field.name]?.placeholder
              }
              labelTooltip={field.labelTooltip}
              value={values[field.name]}
              hasError={!!errors[field.name]}
              errorMessage={validationMessages[errors[field.name]]}
              onUpdateText={updatedValue => {
                this.handleValidateSingleField(
                  field,
                  updatedValue,
                  initialValues[field]
                );
                setFieldValue(field.name, updatedValue);
              }}
            />
          </div>
        );
      case SUBTITLE:
        return (
          <div key={field.name} className={fieldClasses}>
            <Typography variant={field?.variant || 'h5'}>
              {formLabels[field.name]?.label || field?.label}
            </Typography>
          </div>
        );
      case REVIEWS:
        return (
          <div
            key={field.name}
            className={classNames(
              classes.field,
              {
                [classes.divider]: field.hasDivider,
              },
              field.fieldWrapperClass
            )}
          >
            <ReviewsField
              translations={formLabels[field.translationKey || field.name]}
              reviews={this.props[field.optionsKey]}
              survey={this.props?.survey}
              selectedUsers={this.props?.selectedUsers}
              value={values[field.name]}
              errors={errors[field.name]}
              errorMessages={validationMessages}
              onChange={this.handleReviewChange(field)}
            />
          </div>
        );
      case TIME_PICKER:
        if (field.isToggleable) {
          return (
            <Fade
              key={`time_picker_${field.name}`}
              in={field.shouldRender(values)}
              unmountOnExit
            >
              <div
                className={classNames(
                  classes.field,
                  {
                    [classes.divider]: field.hasDivider,
                  },
                  field.fieldWrapperClass
                )}
              >
                <CustomTimePicker
                  customRootClass={field.customRootClass}
                  label={formLabels[field.translationKey || field.name]?.label}
                  selectedTime={values[field.name] ? values[field.name] : null}
                  disabled={
                    field.isDisabled ||
                    (field.isDisabledCheck
                      ? field.isDisabledCheck(values)
                      : undefined)
                  }
                  minuteStep={field.minuteStep}
                  error={!!errors[field.name]}
                  errorMessage={validationMessages[errors[field.name]]}
                  onChange={this.handleDateTimeChange(field)}
                />
              </div>
            </Fade>
          );
        }
        return (
          <div
            key={`time_picker_${field.name}`}
            className={classNames(
              classes.field,
              {
                [classes.divider]: field.hasDivider,
              },
              field.fieldWrapperClass
            )}
          >
            <CustomTimePicker
              customRootClass={field.customRootClass}
              label={formLabels[field.translationKey || field.name]?.label}
              selectedTime={values[field.name] ? values[field.name] : null}
              disabled={
                field.isDisabled ||
                (field.isDisabledCheck
                  ? field.isDisabledCheck(values)
                  : undefined)
              }
              minuteStep={field.minuteStep}
              error={!!errors[field.name]}
              errorMessage={validationMessages[errors[field.name]]}
              onChange={this.handleDateTimeChange(field)}
            />
          </div>
        );
      case READ_ONLY:
        return (
          <div
            key={field.name}
            className={classNames(classes.field, field.fieldWrapperClass)}
          >
            <FormHelperText>
              {formLabels[field.translationKey || field.name].label}
            </FormHelperText>
            <Typography
              className={classes.readOnly}
              variant="subtitle2"
              component="div"
            >
              {values[field.name]}
            </Typography>
          </div>
        );
      case PEOPLE_PICKER:
        return (
          <div
            key={field.name}
            className={classNames(
              classes.field,
              {
                [classes.divider]: field.hasDivider,
              },
              field.fieldWrapperClass
            )}
          >
            <PeoplePicker
              translations={formLabels[field.translationKey || field.name]}
              preselectedUsers={values[field.name] || []}
              isAsync={field.isAsync}
              options={this.getFieldOptions(field)}
              blacklistedUsers={getObjectToNumberArray(
                values?.[field.parent] || []
              )}
              formattedUsers={getFormattedUsers(
                this.getFieldOptions(field),
                true
              )}
              hasError={!!errors[field.name]}
              hasSelectAll={field.hasSelectAll}
              errorMessage={validationMessages?.[errors[field.name]]}
              isDisabled={field.isDisabled}
              showSelection={field.showSelection}
              showDirectReports={field.showDirectReports}
              onSelect={selectedValues =>
                this.handleAutocompleteSelect(
                  field,
                  selectedValues,
                  field.dependants
                )
              }
              shouldHandleTypeLabel={field.shouldHandleTypeLabel}
              handleTypeLabel={field.handleTypeLabel}
            />
          </div>
        );
      case PREVIEW:
        return (
          <PreviewField
            key={field.name}
            translations={formLabels[field.translationKey || field.name]}
            data={values[field.previewKey]}
            isReadOnly={field.isReadOnly}
            component={field.component}
            isExpandedOnFirstOpen={this.state.isExpandedOnFirstOpen}
            handleIsExpandedOnFirstOpen={value =>
              this.setState({ isExpandedOnFirstOpen: value })
            }
            isExpandedOnFirstOpenFramework={
              this.state.isExpandedOnFirstOpenFramework
            }
            handleIsExpandedOnFirstOpenFramework={value =>
              this.setState({ isExpandedOnFirstOpenFramework: value })
            }
          />
        );
      case SURVEY_PREVIEW:
        return (
          <SurveyPreview
            key={field.name}
            translations={formLabels[field.translationKey || field.name]}
            attribute={this.props[field.reviewKey]}
            onGetReview={this.props[field.getReviewKey]}
            onClearReview={this.props[field.clearReviewKey]}
            values={values}
          />
        );
      case ADD_COURSES:
        return (
          <AddCoursesField
            key={field.name}
            className={classNames(classes.field, field.fieldWrapperClass)}
            translations={formLabels[field.translationKey || field.name]}
            selectedCourses={values[field.name]}
            courses={this.getFieldOptions(field)}
            getCourses={field.getCourses}
            getEnrolledUsers={field.getCourseEnrolledUsers}
            onCoursesSearch={field.onCoursesSearch}
            onChange={this.handleAddCourse(field)}
          />
        );
      case ATTRIBUTES_QUESTIONS_FORM:
        return (
          <AttributeQuestionsForm
            key={field.name}
            field={field}
            values={values}
            translations={translations}
            setDialogVisibility={setDialogVisibility}
            dialogs={dialogs}
            questionsLimit={this.props[field.questionsLimitKey]}
            updateAttribute={updateAttribute}
            deleteAllAttributeQuestions={deleteAllAttributeQuestions}
            updateAttributeQuestion={updateAttributeQuestion}
            createQuestion={createQuestion}
            setAttribute={setAttribute}
            reorderQuestions={reorderQuestions}
            deleteAttributeQuestion={deleteAttributeQuestion}
            getAttribute={getAttribute}
            setAttributeQuestions={setAttributeQuestions}
            updateAttributeQuestions={updateAttributeQuestions}
          />
        );
      case ADD_USERS_TABLE:
        return (
          <div key={field.name} className={classNames(field.fieldWrapperClass)}>
            <AddUsersTableField
              translations={translations}
              data={field}
              organizationUser={this.props[field.organizationUserKey]}
              users={values[field.name]}
              errors={errors[field.name]}
              onChange={this.handleAddUsersChange(field)}
            />
          </div>
        );
      case MEETING_IMPRESSION:
        return (
          <div
            key={field.name}
            className={classNames(
              classes.field,
              {
                [classes.divider]: field.hasDivider,
              },
              field.fieldWrapperClass
            )}
          >
            <Impressions
              translations={formLabels[field.translationKey || field.name]}
              label={formLabels[field.translationKey || field.name]?.label}
              value={values[field.name]}
              isFutureMeeting={field.isFutureMeeting(values)}
              onSelectHandler={this.handleChangeSelectValue(field)}
            />
          </div>
        );
      case MEETING_STATUS:
        return (
          <div
            key={field.name}
            className={classNames(
              classes.meetingStatus,
              field.fieldWrapperClass
            )}
          >
            <OneOnOneTimeIndicator
              labels={formLabels[field.translationKey || field.name]?.labels}
              meetingTime={values[field.name]}
              hasImpression={values[field.parent] !== null}
            />
          </div>
        );
      case MEETING_AGENDA:
        return (
          <div key={field.name} className={fieldClasses}>
            <MeetingTopics
              label={formLabels[field.translationKey || field.name]?.label}
              placeholder={
                formLabels[field.translationKey || field.name].placeholder
              }
              topics={field.topics}
              agenda={values[field.name]}
              errorType={errors[field.name]}
              hasError={!!errors[field.name]}
              errorMessage={validationMessages[errors[field.name]]}
              initialCustomTopic={initialValues[field.name]?.customTopic}
              onChange={this.handleMeetingTopicsChange(field)}
            />
          </div>
        );
      case FILTERS_SETUP:
        return (
          <div key={`${field.name}_${field.id}`} className={fieldClasses}>
            <FiltersSetup
              label={formLabels[field.translationKey || field.name]?.label}
              subtext={formLabels[field.translationKey || field.name]?.subtext}
              helpText={
                formLabels[field.translationKey || field.name]?.helpText
              }
              allPlacements={field.placements}
              placements={values[field.name]}
              onChange={this.handleRecommendedPlacementChange(field)}
            />
          </div>
        );
      case CREATABLE_TAGS:
        return (
          <div key={field.name} className={fieldClasses}>
            <CreatableTagsField
              label={formLabels[field.translationKey || field.name]?.label}
              placeholder={
                formLabels[field.translationKey || field.name].placeholder
              }
              startAdornment={
                field.startAdornment ? field.startAdornment(values) : undefined
              }
              hasError={!!errors[field.name]}
              errorMessage={validationMessages[errors[field.name]]}
              initialTags={initialValues[field.name]}
              tags={values[field.name]}
              category={field.category}
              field={field}
              onValidate={this.handleValidateSingleField}
              onSetError={(fieldName, error) => setFieldError(fieldName, error)}
              onChange={value => setFieldValue(field.name, value)}
            />
          </div>
        );
      case SWITCH:
        if (field.isToggleable) {
          return (
            <Fade
              key={`switch_field_${field.name}`}
              in={field.shouldRender(values)}
              unmountOnExit
            >
              <div key={field.name} className={fieldClasses}>
                <CustomSwitch
                  label={formLabels[field.translationKey || field.name]?.label}
                  tooltipInfo={
                    formLabels[field.translationKey || field.name]?.tooltipInfo
                  }
                  isHighlightedLabel={field.isHighlightedLabel}
                  isChecked={values[field.name]}
                  isDisabled={field.isDisabled}
                  onChange={this.handleCheckboxChange(field)}
                />
              </div>
            </Fade>
          );
        }
        return (
          <div key={field.name} className={fieldClasses}>
            <CustomSwitch
              label={formLabels[field.translationKey || field.name]?.label}
              tooltipInfo={
                formLabels[field.translationKey || field.name]?.tooltipInfo
              }
              isHighlightedLabel={field.isHighlightedLabel}
              isChecked={values[field.name]}
              isDisabled={field.isDisabled}
              onChange={this.handleCheckboxChange(field)}
            />
          </div>
        );
      case TASKS:
        return (
          <Fade
            key={`tasks_${field.name}`}
            in={field.shouldRender && field.shouldRender(values)}
            unmountOnExit
          >
            <div key={field.name} className={fieldClasses}>
              <TasksField
                translations={formLabels[field.translationKey || field.name]}
                tasks={values[field.name]}
                allUsers={this.props[field.optionsKey]}
                checkCanManageTask={field.checkCanManageTask}
                error={errors[field.name]}
                onChange={this.handleTasksChange(field)}
              />
            </div>
          </Fade>
        );
      case SLIDER:
        return (
          <div key={field.name} className={fieldClasses}>
            <ProgressField
              label={formLabels[field.translationKey || field.name]?.label}
              value={values[field.name]}
              isDisabled={
                field.isDisabledCheck ? field.isDisabledCheck(values) : false
              }
              onChange={this.handleSliderChange(field)}
            />
          </div>
        );
      default:
        return null;
    }
  };

  renderContent = () => {
    const { classes, customColumnsClass, fields, columns } = this.props;

    if (!isArrayEmpty(columns)) {
      return (
        <div className={classNames(classes.columns, customColumnsClass)}>
          {columns.map(column => (
            <div
              key={`column_${column.id}`}
              className={classNames(
                classes.columnWrapper,
                column.columnWrapperClass
              )}
            >
              {column.hasScroll ? (
                <CustomScrollBar
                  customScrollBarYClass={classNames(
                    classes.scrollY,
                    classes.scrollYColumn
                  )}
                  customScrollClass={classes.scroll}
                  customContentClass={classes.columnScrollWrapper}
                  removeScrollX
                  verticalScroll
                >
                  <div className={classNames(classes.column, column.className)}>
                    {column.title && (
                      <Typography
                        variant="h5"
                        component="div"
                        className={classes.columnTitle}
                      >
                        {column.title}
                      </Typography>
                    )}
                    {column.fields.map(field => this.renderField(field))}
                  </div>
                </CustomScrollBar>
              ) : (
                <div className={classNames(classes.column, column.className)}>
                  {column.title && (
                    <Typography
                      variant="h5"
                      component="div"
                      className={classes.columnTitle}
                    >
                      {column.title}
                    </Typography>
                  )}
                  {column.fields.map(field => this.renderField(field))}
                </div>
              )}
            </div>
          ))}
        </div>
      );
    }

    return fields.map(field => this.renderField(field));
  };

  handleSubmit = async saveType => {
    const { setFieldValue, submitForm, dirty, isInitialValid } = this.props;
    if (isInitialValid) {
      setFieldValue(DIRTY, dirty);
    }
    setFieldValue(SAVE_TYPE, saveType);

    submitForm();
  };

  render() {
    const {
      classes,
      customHeaderClass,
      customContentClass,
      customFormClass,
      translations,
      isOpened,
      values,
      errors,
      notifyAll,
      isSubmitting,
      dirty,
      hasColumnScroll,
      isInitialValid,
      isWholeScreen,
      isFullWidth,
      isMiddleWidth,
      hasCancelButton,
      hasAddButton,
      hasPreview,
      openPreview,
      isFinish,
      titleText,
      saveButtonText,
      hideDelete,
      hasHorizontalScroll,
      onDelete,
    } = this.props;
    const { scrollLeft } = this.state;

    const hasErrors = Object.values(errors)?.some(val => val);
    const saveButtonContent = () => {
      if (isFinish) {
        return translations.finish;
      } else if (saveButtonText) {
        return saveButtonText;
      }
      return translations.save;
    };

    return (
      <Drawer
        anchor="right"
        classes={{
          paper: classNames({
            [classes.wholeScreen]: isWholeScreen,
            [classes.fullWidth]: isFullWidth,
            [classes.middleWidth]: isMiddleWidth,
          }),
        }}
        open={isOpened}
        onClose={this.handleClose}
      >
        <div
          className={classNames(
            classes.header,
            {
              [classes.headerFullWidth]: isFullWidth || isWholeScreen,
              [classes.headerMiddleWidth]: isMiddleWidth,
            },
            customHeaderClass
          )}
        >
          <Typography className={classes.title} variant="h4">
            {this.renderTitle(titleText)}
          </Typography>
          <div className={classes.headerActions}>
            {isInitialValid && !hideDelete ? (
              <ActionButton
                id={DELETE_BUTTON}
                className={classes.deleteIcon}
                type={ACTION_BUTTON_TYPES.DELETE_FILL}
                onClickHandler={() => onDelete(values)}
              />
            ) : null}
            <ActionButton
              id={CLOSE_BUTTON}
              type={ACTION_BUTTON_TYPES.CLOSE}
              onClickHandler={this.handleClose}
            />
          </div>
        </div>
        {hasColumnScroll ? (
          <div
            className={classNames(
              classes.content,
              {
                [classes.contentFullWidth]: isFullWidth,
                [classes.contentMiddleWidth]: isMiddleWidth,
              },
              customContentClass
            )}
          >
            {isOpened ? <form>{this.renderContent()}</form> : null}
          </div>
        ) : (
          <CustomScrollBar
            ref={this.scrollBar}
            customScrollBarXClass={classNames(classes.scrollX, {
              [classes.scrollXFullWidth]: isFullWidth,
            })}
            customScrollBarYClass={classNames(classes.scrollY, {
              [classes.scrollYFullWidth]: isFullWidth,
            })}
            customScrollClass={classes.scroll}
            customScrollerClass={classes.scroller}
            scrollLeft={scrollLeft}
            removeScrollX={!hasHorizontalScroll}
            verticalScroll
          >
            <div
              className={classNames(
                classes.content,
                {
                  [classes.contentWholeScreen]: isWholeScreen,
                  [classes.contentFullWidth]: isFullWidth,
                  [classes.contentMiddleWidth]: isMiddleWidth,
                },
                customContentClass
              )}
            >
              {isOpened ? (
                <form
                  onSubmit={e => e.preventDefault()}
                  className={customFormClass}
                >
                  {this.renderContent()}
                </form>
              ) : null}
            </div>
          </CustomScrollBar>
        )}
        <div
          className={classNames(classes.footer, {
            [classes.footerFullWidth]: isFullWidth,
            [classes.footerWithPreview]: hasPreview,
          })}
        >
          {notifyAll && (
            <div className={classes.notifyAll}>
              <CustomCheckbox
                customLabelClass={classes.checkboxLabel}
                isChecked={values[notifyAll.name]}
                labelText={
                  translations.formLabels[
                    notifyAll.translationKey || notifyAll.name
                  ].labelText
                }
                disabled={notifyAll.isDisabled(values)}
                onChange={this.handleCheckboxChange(notifyAll)}
                isControlled
                smallText
              />
            </div>
          )}
          {hasPreview && (
            <Typography
              variant="subtitle1"
              className={classes.preview}
              onClick={openPreview}
            >
              {translations.preview}
            </Typography>
          )}
          <div>
            {hasCancelButton && (
              <CustomButton
                id={CANCEL_BUTTON}
                className={classes.cancelButton}
                onClick={this.handleClose}
                type="addWithTextRounded"
              >
                {translations.cancel}
              </CustomButton>
            )}
            <CustomButton
              id={SAVE_BUTTON}
              className={classNames(classes.saveButton, {
                [classes.saveButtonSecondary]: hasAddButton,
              })}
              onClick={
                !isFinish && (dirty || !isInitialValid)
                  ? () => this.handleSubmit(SAVE)
                  : this.handleClose
              }
              disabled={isSubmitting || hasErrors}
              type="withTextDarkRounded"
            >
              {saveButtonContent()}
            </CustomButton>
            {hasAddButton && (
              <CustomButton
                id={SAVE_AND_ADD_BUTTON}
                className={classes.saveAndAddButton}
                onClick={() => this.handleSubmit(SAVE_AND_ADD)}
                type="withTextDarkRounded"
                disabled={isSubmitting || hasErrors}
              >
                {translations.saveAndAdd}
              </CustomButton>
            )}
          </div>
        </div>
      </Drawer>
    );
  }
}

CustomFormDrawerLayout.defaultProps = {
  customHeaderClass: null,
  customContentClass: null,
  customColumnsClass: null,
  customFormClass: undefined,
  isWholeScreen: false,
  isFullWidth: false,
  isMiddleWidth: false,
  hasCancelButton: false,
  hasAddButton: false,
  isInitialValid: false,
  hasColumnScroll: false,
  columns: [],
  fields: [],
  notifyAll: null,
  onDelete: () => {},
  hasPreview: false,
  openPreview: () => {},
  updateAttributeQuestion: () => {},
  deleteAttributeQuestion: () => {},
  getTitle: () => {},
  getAttribute: () => {},
  isFinish: false,
  titleText: '',
  onAutoCompleteChange: () => {},
  saveButtonText: '',
  hideDelete: false,
  hasHorizontalScroll: false,
};

CustomFormDrawerLayout.propTypes = {
  classes: PropTypes.object.isRequired,
  customHeaderClass: PropTypes.string,
  customContentClass: PropTypes.string,
  customFormClass: PropTypes.string,
  customColumnsClass: PropTypes.string,
  translations: PropTypes.object.isRequired,
  isWholeScreen: PropTypes.bool,
  isFullWidth: PropTypes.bool,
  isMiddleWidth: PropTypes.bool,
  isOpened: PropTypes.bool.isRequired,
  fields: PropTypes.arrayOf(PropTypes.shape({})),
  columns: PropTypes.arrayOf(PropTypes.shape({})),
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  dirty: PropTypes.bool.isRequired,
  hasHorizontalScroll: PropTypes.bool,
  isInitialValid: PropTypes.bool,
  hasCancelButton: PropTypes.bool,
  hasAddButton: PropTypes.bool,
  hasPreview: PropTypes.bool,
  hasColumnScroll: PropTypes.bool,
  notifyAll: PropTypes.shape({}),
  submitForm: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
  resetForm: PropTypes.func.isRequired,
  openPreview: PropTypes.func,
  updateAttributeQuestion: PropTypes.func,
  deleteAttributeQuestion: PropTypes.func,
  getAttribute: PropTypes.func,
  getTitle: PropTypes.func,
  isFinish: PropTypes.bool,
  titleText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onAutoCompleteChange: PropTypes.func,
  saveButtonText: PropTypes.string,
  hideDelete: PropTypes.bool,
};

export default withStyles(styles)(CustomFormDrawerLayout);
