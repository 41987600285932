import userflow from 'userflow.js';
import { formatISO } from 'date-fns';
import { getPersonFullName } from './uiUtils';
import { USER_INFO } from '../constants/people';

export const onInitUserFlow = async user => {
  userflow.init(process.env.REACT_APP_USER_FLOW_TOKEN);
  await userflow.identify(user.id, {
    name: getPersonFullName(user),
    email: user[USER_INFO.EMAIL],
    signed_up_at: formatISO(new Date()), // Temp Add date of first signin
    role: user[USER_INFO.ROLE],
  });
};
