import { memo } from 'react';
import classNames from 'classnames';
import { components } from 'react-select';
import { makeStyles } from '@material-ui/core';
import TagHash from '../../tagHash';
import ColorBox from '../../colorBox';
import UserAvatar from '../../userAvatar';
import Tooltip from '../../tooltip';
import { ReactComponent as TextReplyIcon } from '../../../../assets/icons/text_snippet.svg';
import { ReactComponent as PersonalAttributeIcon } from '../../../../assets/icons/private-attribute-icon.svg';
import { ellipsis } from '../../../../constants/helperCssRules';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  root: {
    boxSizing: 'border-box',
    borderRadius: 4,
    fontFamily: 'Proximanova-Regular',
    fontSize: '16px',
    lineHeight: '20px',
    color: primary.bluish1,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    height: 40,
    padding: spacing(2),
    marginBottom: spacing(1),
    width: '100%',
    ...ellipsis(),
    '&:last-of-type': {
      marginBottom: 0,
    },
  },
  disabled: {
    color: primary.bluish7,
    cursor: 'default',
  },
  disabledLabel: {
    marginLeft: 'auto',
  },
  selected: {
    backgroundColor: primary.bluish9,
    color: primary.bluish1,
    fontFamily: 'ProximaNova-Bold',
  },
  focused: {
    backgroundColor: primary.bluish8,
  },
  innerWrapper: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    minWidth: 0,
  },
  content: {
    ...ellipsis(),
  },
  centeredContent: {
    justifyContent: 'center',
  },
  colorBox: {
    marginRight: spacing(2),
  },
  userLabel: {
    fontSize: 16,
    lineHeight: '20px',
  },
  iconWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
  },
  attributeIcon: {
    display: 'flex',
    alignItems: 'center',
    flexShrink: 0,
    marginLeft: spacing(1),
    marginTop: spacing(-0.25),
    '& svg': {
      '& g, & path': {
        fill: primary.bluish1,
      },
    },
    '& g, & path': {
      fill: primary.bluish1,
    },
  },
}));

const Option = props => {
  const classes = useStyles();
  const { onClick, ...otherProps } = props.innerProps;
  const {
    translations,
    isOptionValueCentered,
    hasHash,
    hasColorBox,
    isUser,
    isAttribute,
  } = props.selectProps;

  return (
    <components.Option
      {...props}
      className={classNames(classes.root, {
        [classes.disabled]: props.isDisabled,
        [classes.selected]: props.isSelected,
        [classes.focused]: props.isFocused && !props.isSelected,
        [classes.centeredContent]: isOptionValueCentered,
      })}
    >
      <div
        {...otherProps}
        ref={props.innerRef}
        className={classes.innerWrapper}
      >
        {hasHash && props.data.id && <TagHash bgColor={props.data.color} />}
        {hasColorBox && (
          <ColorBox
            className={classes.colorBox}
            bgColor={props.data.color}
            isSmall
          />
        )}
        {isUser && !props.data.shouldRemoveUserAvatar ? (
          <UserAvatar
            labelClass={classNames(classes.userLabel, {
              [classes.selected]: props.isSelected,
            })}
            user={props.data}
            caption
            small
          />
        ) : (
          <div className={classes.content}>{props.children}</div>
        )}
        {props.isDisabled && !props.isSelected && props.data?.disabledLabel && (
          <div className={classes.disabledLabel}>
            {props.data.disabledLabel}
          </div>
        )}
        {isAttribute && (
          <div className={classes.iconWrapper}>
            {isAttribute && props.data.text_reply_questions && (
              <Tooltip
                customIconClass={classes.attributeIcon}
                text={translations.openEndedAttribute}
                icon={TextReplyIcon}
              />
            )}
            {isAttribute && props.data.is_private && (
              <Tooltip
                customIconClass={classes.attributeIcon}
                text={translations.personalAttribute}
                icon={PersonalAttributeIcon}
              />
            )}
          </div>
        )}
      </div>
    </components.Option>
  );
};

export default memo(Option);
