import { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import UserLabel from '../userLabel';
import AsyncListTooltip from '../asyncListTooltip';
import UserCountBadge from '../userCountBadge';
import ShareTypeBox from '../shareTypeBox';

const useStyles = makeStyles(({ spacing }) => ({
  userTypeLabel: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  box: {
    minWidth: 'fit-content',
  },
  labelSpacing: {
    marginBottom: spacing(1.5),
  },
  badge: {
    maxWidth: 'fit-content',
  },
}));

const UserCount = ({
  className,
  customUserCountBadgeClassName,
  tooltipTitle,
  addButtonLabel,
  userCount,
  canSeeUserProfile,
  shouldDisablePortal,
  isReadOnly,
  hasAddButton,
  isTooltipDisabled,
  onAdd,
  goToUserProfile,
  getUsers,
  hasUserCount,
  handleTypeLabel,
  shouldHandleTypeLabel,
  tooltipLabel,
  icon,
}) => {
  const classes = useStyles();
  const [isOpenedView, setIsOpenedView] = useState(true);

  const getLabelData =
    userCount !== 0 || !hasUserCount
      ? getUsers
      : () => Promise.resolve({ data: null });

  const renderUser = (user, isClickable) => {
    const seeTypeLabel = shouldHandleTypeLabel ? handleTypeLabel(user) : false;

    return (
      <div className={classes.userTypeLabel}>
        <UserLabel user={user} isDisabled={isReadOnly || !isClickable} />
        {shouldHandleTypeLabel && seeTypeLabel && (
          <ShareTypeBox className={classes.box} label={seeTypeLabel} />
        )}
      </div>
    );
  };
  const onToggleView = value => {
    setIsOpenedView(value);
  };

  return (
    <div className={classNames({ [classes.badge]: !hasUserCount }, className)}>
      <AsyncListTooltip
        title={tooltipTitle}
        tooltipButtonLabel={addButtonLabel}
        hasTooltipButton={hasAddButton}
        getLabelData={getLabelData}
        renderLabelItem={renderUser}
        shouldDisablePortal={shouldDisablePortal}
        isTooltipDisabled={isTooltipDisabled}
        isItemClickable={canSeeUserProfile}
        onItemClickHandler={goToUserProfile}
        onTooltipButtonClickHandler={onAdd}
        onToggleView={onToggleView}
      >
        <UserCountBadge
          className={customUserCountBadgeClassName}
          hasTooltip={isOpenedView}
          hasUserCount={hasUserCount}
          tooltipLabel={tooltipLabel}
          userCount={userCount || 0}
          icon={icon}
        />
      </AsyncListTooltip>
    </div>
  );
};

UserCount.defaultProps = {
  className: null,
  customUserCountBadgeClassName: null,
  userCount: 0,
  tooltipTitle: '',
  addButtonLabel: '',
  isReadOnly: false,
  handleTypeLabel: () => {},
  shouldHandleTypeLabel: false,
  tooltipLabel: '',
  hasUserCount: true,
  shouldDisablePortal: true,
  hasAddButton: false,
  isTooltipDisabled: false,
  canSeeUserProfile: () => true,
  goToUserProfile: () => {},
  onAdd: () => {},
  icon: null,
};

UserCount.propTypes = {
  className: PropTypes.string,
  customUserCountBadgeClassName: PropTypes.string,
  addButtonLabel: PropTypes.string,
  userCount: PropTypes.number,
  isReadOnly: PropTypes.bool,
  canSeeUserProfile: PropTypes.func,
  tooltipLabel: PropTypes.string,
  tooltipTitle: PropTypes.string,
  isTooltipDisabled: PropTypes.bool,
  shouldDisablePortal: PropTypes.bool,
  getUsers: PropTypes.func.isRequired,
  goToUserProfile: PropTypes.func,
  hasAddButton: PropTypes.bool,
  hasUserCount: PropTypes.bool,
  onAdd: PropTypes.func,
  handleTypeLabel: PropTypes.func,
  shouldHandleTypeLabel: PropTypes.bool,
  icon: PropTypes.object,
};

export default memo(UserCount);
