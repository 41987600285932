import { useState, useEffect, useCallback, memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Typography, makeStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';
import PageContainer from '../../shared/pageContainer';
import Filters from '../../shared/filters';
import Search from '../../shared/search';
import GridTable from '../../shared/gridTable';
import NotificationCard from '../../shared/notificationCard';
import SurveyType from '../../shared/surveyType';
import UserAvatar from '../../shared/userAvatar';
import DotsMenu from '../../shared/dotsMenu';
import UserCount from '../../shared/userCount';
import AlertDialog from '../../shared/alertDialog';
import CustomFormDrawer from '../../shared/customFormDrawer';
import { surveySummariesSelector } from '../../../store/selectors/surveySummariesSelector';
import { peopleSelector } from '../../../store/selectors/peopleSelector';
import {
  getSurveySummaries,
  clearSurveySummaries,
} from '../../../store/modules/surveySummaries';
import { getAllUsers, clearAllUsers } from '../../../store/modules/people';
import {
  trimString,
  isArray,
  isArrayEmpty,
  canSeeEmployeeProfile,
  isObjectEmpty,
  getItemById,
} from '../../../utility/helpers';
import { hasSelectedFilters } from '../../shared/filters/config';
import {
  hasNextPage,
  showSuccessMessage,
  parseDuplicateParameters,
  parseQueryParams,
} from '../../../utility/uiUtils';
import http from '../../../utility/http';
import { getTranslatedSurveyTypes } from '../../../utility/survey';
import { useTranslations } from '../../../utility/useTranslations';
import {
  APP_PAGES,
  PARAMS,
  SURVEYS_SUMMARY_DEFAULT_PARAMS,
  PAGE_WHITELISTED_PARAMS,
} from '../../../constants/pages';
import { sticky } from '../../../constants/helperCssRules';
import {
  api_survey_summary,
  api_survey_summary_shared_with,
  api_survey_summary_share,
} from '../../../constants/apiRoutes';
import {
  INITIAL_SORT,
  SHARE_REPORT_FORM_FIELDS,
} from '../../../constants/surveySummary';
import {
  getSurveySummaryActionItems,
  getSurveySummaryPageFilters,
  getSurveySummaryHeaders,
  getSurveySummaryHeaderActions,
  prepareSummaryResults,
  getInitialShareReportData,
} from '../../../utility/surveySummary';

const useStyles = makeStyles(
  ({ breakpoints, palette: { primary }, spacing }) => ({
    stickyHeader: {
      padding: spacing(8, 0, 6, 0),
      ...sticky(primary.white, 105),
    },
    description: {
      marginBottom: spacing(6),
    },
    filtersSection: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    filters: {
      marginRight: spacing(2),
    },
    search: {
      width: 300,
    },
    table: {
      '& .grid-table-header': {
        ...sticky(primary.bluish8, 243),
      },
      '& .title-cell, & .created-at-cell, & .shared-with-cell, & .created-by-cell':
        {
          '& .row-cell-value': {
            color: primary.bluish1,
          },
        },
      '& .created-by-cell': {
        '& .row-cell-value': {
          overflow: 'visible',
        },
      },
      '& .actions-cell': {
        overflow: 'visible',
        paddingLeft: 0,
        paddingTop: spacing(2),
        paddingBottom: spacing(2),
        paddingRight: spacing(1),

        [breakpoints.up('xLg')]: {
          paddingRight: spacing(3),
        },
      },
    },
    userLabel: {
      fontFamily: 'ProximaNova-Bold',
      fontSize: 16,
      lineHeight: '20px',
    },
    surveyTypeTooltip: {
      cursor: 'pointer',
    },
    typeIcon: {
      marginRight: 0,
    },
    actions: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
    sharedWith: {
      display: 'flex',
      alignItems: 'center',
    },
  })
);

const SurveySummaryPage = ({
  location,
  navigate,
  dispatch,
  auth,
  organizationSettings,
  ...rest
}) => {
  const classes = useStyles();
  const translations = useTranslations(APP_PAGES.SURVEY_SUMMARY);
  const { page: defaultPage } = SURVEYS_SUMMARY_DEFAULT_PARAMS;
  const { PAGE, ORDERING, SURVEY_SUMMARY_TYPE, CREATOR, SEARCH } = PARAMS;

  const surveySummaries = useSelector(surveySummariesSelector);
  const { allUsers } = useSelector(peopleSelector);

  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [ordering, setOrdering] = useState(INITIAL_SORT);
  const [selectedFilters, setSelectedFilters] = useState({
    [SURVEY_SUMMARY_TYPE]: [],
    [CREATOR]: [],
  });
  const [selectedReport, setSelectedReport] = useState(null);
  const [isDeleteReportOpened, setIsDeleteReportOpened] = useState(false);
  const [isShareReportOpened, setIsShareReportOpened] = useState(false);
  const [sharedWith, setSharedWith] = useState([]);

  const surveyTypes = getTranslatedSurveyTypes(translations);
  const filters = getSurveySummaryPageFilters(
    translations,
    surveyTypes,
    allUsers
  );
  const gridHeaderActions = getSurveySummaryHeaderActions(translations.columns);
  const gridHeaders = getSurveySummaryHeaders(translations.columns);
  const isActiveFilter = hasSelectedFilters(selectedFilters);
  const hasLoadMore = hasNextPage(surveySummaries);
  const sharableUsers = useMemo(
    () =>
      allUsers.reduce(
        (acc, currentUser) => [
          ...acc,
          ...(currentUser.id === auth.id ||
          selectedReport?.creator?.id === currentUser.id
            ? []
            : [currentUser]),
        ],
        []
      ),
    [allUsers, auth, selectedReport]
  );

  const goToUserProfilePage = userId => navigate(`/people/${userId}`);

  const getReportSharedWith = reportId => () =>
    http.get(api_survey_summary_shared_with(reportId));

  const handlePageChange = ({
    newPage,
    newOrdering,
    newFilters,
    newSearch,
    isLoadMore,
  } = {}) => {
    const params = {
      [PAGE]: newPage,
      [ORDERING]: newOrdering?.sortKey,
      ...newFilters,
      ...(newSearch ? { [SEARCH]: newSearch } : {}),
    };

    const query = parseDuplicateParameters(params);

    if (location.search !== `?${query}`) {
      navigate(`/reports/survey-summary/?${query}`, { replace: true });
    }

    return getSurveySummaries(dispatch, isLoadMore, params);
  };

  const getInitialData = async () => {
    try {
      const columns = getSurveySummaryHeaders(translations.columns);
      const params = parseQueryParams(
        location.search,
        PAGE_WHITELISTED_PARAMS.SURVEY_SUMMARY
      );

      if (!isObjectEmpty(params)) {
        const [ascending, descending] = params[ORDERING].split('-');
        const isAscending = !!ascending;
        const initialOrdering = {
          column: getItemById(
            columns,
            isAscending ? ascending : descending,
            'sortAs'
          )?.rowKey,
          asc: isAscending,
          sortKey: params[ORDERING],
        };
        const initialSearch = params[SEARCH]?.toString() || '';
        const initialFilters = {
          [CREATOR]: params[CREATOR]
            ? [
                ...(isArray(params[CREATOR])
                  ? params[CREATOR]
                  : [params[CREATOR]]),
              ]
            : [],
          [SURVEY_SUMMARY_TYPE]: params[SURVEY_SUMMARY_TYPE]
            ? [params[SURVEY_SUMMARY_TYPE]]
            : [],
        };

        setOrdering(initialOrdering);
        setSearch(initialSearch);
        setSelectedFilters(initialFilters);
        await Promise.all([
          handlePageChange({
            newPage: page,
            newOrdering: initialOrdering,
            newFilters: initialFilters,
            newSearch: initialSearch,
          }),
          getAllUsers(dispatch),
        ]);
      } else {
        await Promise.all([
          handlePageChange({
            newPage: page,
            newOrdering: ordering,
            newFilters: selectedFilters,
            newSearch: search,
          }),
          getAllUsers(dispatch),
        ]);
      }
    } finally {
      setIsInitialLoad(false);
    }
  };

  const cleanup = useCallback(() => {
    dispatch(clearSurveySummaries());
    dispatch(clearAllUsers());
  }, [dispatch]);

  const onSearch = async text => {
    const searchTerm = trimString(text);

    try {
      setPage(defaultPage);
      setSearch(searchTerm);
      setIsLoading(true);
      await handlePageChange({
        newPage: defaultPage,
        newSearch: searchTerm,
        newOrdering: ordering,
        newFilters: selectedFilters,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const onSort = newOrdering => {
    setPage(defaultPage);
    setOrdering(newOrdering);
    handlePageChange({
      newPage: defaultPage,
      newSearch: search,
      newOrdering,
      newFilters: selectedFilters,
    });
  };

  const onFiltersChange = async newFilters => {
    try {
      setIsLoading(true);
      setPage(defaultPage);
      setSelectedFilters(newFilters);
      await handlePageChange({
        newPage: defaultPage,
        newSearch: search,
        newOrdering: ordering,
        newFilters,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const onLoadMore = () => {
    setPage(page + 1);
    handlePageChange({
      newPage: page + 1,
      newSearch: search,
      newOrdering: ordering,
      newFilters: selectedFilters,
      isLoadMore: true,
    });
  };

  const handleDeleteReport = report => {
    setSelectedReport(report);
    setIsDeleteReportOpened(true);
  };

  const handleCloseDeleteReport = () => {
    setSelectedReport(null);
    setIsDeleteReportOpened(false);
  };

  const handleShareReport = report => async () => {
    if (report.shares_number !== 0) {
      const { data } = await getReportSharedWith(report.id)();
      setSharedWith(data);
    }

    setSelectedReport(report);
    setIsShareReportOpened(true);
  };

  const handleCloseShareReport = () => {
    setSelectedReport(null);
    setIsShareReportOpened(false);
    setSharedWith([]);
  };

  const onShareReport = async ({ share_to }) => {
    await http.put(api_survey_summary_share(selectedReport.id), share_to);
    setPage(defaultPage);
    handlePageChange({
      newPage: defaultPage,
      newSearch: search,
      newOrdering: ordering,
      newFilters: selectedFilters,
    });
    showSuccessMessage(translations.reportShareDialog.successMessage);
  };

  const onDeleteReport = async () => {
    try {
      await http.delete(api_survey_summary(selectedReport.id));
      setPage(defaultPage);
      handlePageChange({
        newPage: defaultPage,
        newSearch: search,
        newOrdering: ordering,
        newFilters: selectedFilters,
      });
      showSuccessMessage(translations.deleteReportDialog.deleteSuccess);
    } finally {
      handleCloseDeleteReport();
    }
  };

  const canSeeUserProfileCheck = user =>
    canSeeEmployeeProfile(
      auth,
      user.id,
      organizationSettings.global_see_himself
    );

  useEffect(() => {
    getInitialData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return cleanup;
  }, [cleanup]);

  const renderActions = summaryReport => (
    <div className={classes.actions}>
      <DotsMenu
        menuItems={getSurveySummaryActionItems(
          translations.menuItemLabels,
          summaryReport,
          handleShareReport,
          handleDeleteReport
        )}
      />
    </div>
  );

  const renderSurveyType = type => (
    <SurveyType
      typeIconClass={classes.typeIcon}
      tooltipClass={classes.surveyTypeTooltip}
      type={type}
      isIconStatus
    />
  );

  const renderCreator = (user, canAccessCreatorProfile) => (
    <UserAvatar
      labelClass={classes.userLabel}
      user={user}
      clickableCaption={canAccessCreatorProfile}
      caption
      small
    />
  );

  const renderSharedWith = currentReport => (
    <div className={classes.sharedWith}>
      <UserCount
        addButtonLabel={translations.menuItemLabels.share}
        userCount={currentReport.shares_number}
        shouldDisablePortal={false}
        canSeeUserProfile={canSeeUserProfileCheck}
        getUsers={getReportSharedWith(currentReport.id)}
        goToUserProfile={goToUserProfilePage}
        onAdd={handleShareReport(currentReport)}
        hasAddButton
      />
    </div>
  );

  return (
    <PageContainer
      {...rest}
      translations={translations}
      location={location}
      navigate={navigate}
      auth={auth}
      organizationSettings={organizationSettings}
      shouldPassProps={false}
      isFullWidthContent
    >
      {!isInitialLoad && (
        <div>
          <div className={classes.stickyHeader}>
            <div className={classes.description}>
              <Typography variant="body2">
                {translations.description}
              </Typography>
            </div>
            <div className={classes.filtersSection}>
              <div className={classes.filters}>
                <Filters
                  translations={translations.filters}
                  selectedFilters={selectedFilters}
                  filters={filters}
                  onApplyFilters={onFiltersChange}
                />
              </div>
              <Search
                className={classes.search}
                placeholder={translations.search}
                value={search}
                onChange={onSearch}
              />
            </div>
          </div>
          {isArray(surveySummaries.results) &&
            !isArrayEmpty(surveySummaries.results) && (
              <GridTable
                className={classes.table}
                translations={translations}
                initialSort={ordering}
                headerActions={gridHeaderActions}
                headers={gridHeaders}
                hasLoadMore={hasLoadMore}
                rows={prepareSummaryResults(
                  surveySummaries.results,
                  auth,
                  organizationSettings.global_see_himself,
                  surveyTypes,
                  renderSurveyType,
                  renderCreator,
                  renderSharedWith,
                  renderActions
                )}
                onLoadMore={onLoadMore}
                onSort={onSort}
              />
            )}
          {!isLoading &&
            isArray(surveySummaries.results) &&
            isArrayEmpty(surveySummaries.results) && (
              <NotificationCard
                title={
                  search || isActiveFilter ? '' : translations.noResults.title
                }
                content={
                  search || isActiveFilter
                    ? translations.noResults.search
                    : translations.noResults.message
                }
              />
            )}
          <AlertDialog
            isOpened={isDeleteReportOpened}
            translations={translations.deleteReportDialog}
            onClose={handleCloseDeleteReport}
            onConfirm={onDeleteReport}
            isWarning
            isLarge
          />
          <CustomFormDrawer
            translations={translations.reportShareDialog}
            isOpened={isShareReportOpened}
            initialData={getInitialShareReportData(sharedWith)}
            fields={SHARE_REPORT_FORM_FIELDS}
            allUsers={sharableUsers}
            onClose={handleCloseShareReport}
            onSave={onShareReport}
            hasCancelButton
            hideDelete
            isInitialValid
          />
        </div>
      )}
    </PageContainer>
  );
};

SurveySummaryPage.propTypes = {
  location: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  organizationSettings: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default memo(SurveySummaryPage);
