// ------------------------------------
// Create Organization
// ------------------------------------

export const API_VALIDATE_ORGANIZATION_NAME = 'validate/domain-name/';
export const API_JOIN_ON_INVITE = 'invite/confirm/';

// ------------------------------------
// Create Organization
// ------------------------------------

export const API_REGISTRATION = 'registration/';
export const API_APPLY_REGISTRATION_EMAIL = `${API_REGISTRATION}apply/`;
export const API_VERIFY_EMAIL = `${API_REGISTRATION}verify-email/`;
export const API_REGISTRATION_UPDATE = `${API_REGISTRATION}update/`;
export const API_REGISTRATION_CONFIRM = `${API_REGISTRATION}confirm/`;

// ------------------------------------
// Credentials
// ------------------------------------

export const API_LOGIN = 'auth/login/';
export const API_ME = 'auth/me/';
export const API_REQUEST_RESET_PASSWORD = 'reset-password/';
export const API_RESET_PASSWORD = 'auth/password/reset/confirm/';

// ------------------------------------
// Users
// ------------------------------------

export const API_USERS = 'users/';
export const API_USERS_BASIC = `${API_USERS}basic/`;
export const API_USERS_MULTIPLE = `${API_USERS}multiple/`;
export const API_VALIDATE_EMAILS = `${API_USERS}emails/`;
export const API_ORG_CHART = `${API_USERS}organization/`;
export const API_TO_DO = `${API_USERS}to-do/`;
export const API_TEAM_STATS = `${API_USERS}team-stats/`;
export const api_user = id => `${API_USERS}${id}/`;
export const api_user_surveys_timeline = id =>
  `${API_USERS}${id}/surveys-timeline/`;
export const api_upload_photo = id => `${API_USERS}${id}/avatar/`;
export const API_USER_NOTES = `user-notes/`;
export const API_MY_PRIVATE_NOTES = `${API_USER_NOTES}private/`;
export const api_user_notes_with_view_permission = id =>
  `${API_USER_NOTES}${id}/users-with-view-permission/`;
export const api_user_notes = id => `users/${id}/notes/`;
export const api_user_reviewed_attributes = userId =>
  `${API_USERS}${userId}/reviewed-attributes/`;
export const api_user_reports_aggregated = (userId, groupBy) =>
  `${API_USERS}${userId}/reports/grouped-by-${groupBy}/`;
export const api_user_reports_comments = userId =>
  `${API_USERS}${userId}/reports/comments/`;
export const api_user_organization = userId => `${API_ORG_CHART}${userId}/`;
export const api_user_one_on_one = userId =>
  `${API_USERS}${userId}/one-on-one/`;
export const api_user_shared_with = userId =>
  `${API_USERS}${userId}/shared-with/`;
export const api_user_has_shared_access = userId =>
  `${API_USERS}${userId}/shared/`;
export const api_user_share = userId => `${API_USERS}${userId}/share/`;
export const api_user_attributes = userId =>
  `${API_USERS}${userId}/attributes/`;
export const api_user_tasks = userId => `${API_USERS}${userId}/tasks/`;
export const api_user_absences = userId => `${API_USERS}${userId}/absences/`;
export const api_user_absence_requests = userId =>
  `${API_USERS}${userId}/timeline/`;
export const api_user_absence_upcoming_time_off = userId =>
  `${API_USERS}${userId}/upcoming-time-off/`;
export const api_user_absence_available_days = userId =>
  `${API_USERS}${userId}/number-of-days/`;

// ------------------------------------
// Questions
// ------------------------------------

export const API_QUESTIONS = 'questions/';
export const api_question = id => `${API_QUESTIONS}${id}/`;

// ------------------------------------
// Attributes
// ------------------------------------

export const API_ATTRIBUTES = 'attributes/';
export const API_ATTRIBUTES_BASIC = `${API_ATTRIBUTES}basic/`;
// params: { name: string(attribute name), code: string(short name) }
export const API_UNIQUE_ATTRIBUTE = `${API_ATTRIBUTES}validate/`;
export const api_duplicate_attribute = id =>
  `${API_ATTRIBUTES}${id}/duplicate/`;
export const api_set_questions = id =>
  `${API_ATTRIBUTES}${id}/${API_QUESTIONS}`;
export const api_attribute_usage = id => `${API_ATTRIBUTES}${id}/usage/`;
export const api_attribute = id => `${API_ATTRIBUTES}${id}/`;
export const api_attribute_reorder_question = id =>
  `${API_ATTRIBUTES}order/${id}/`;
export const API_IMPORT_ATTRIBUTES = `${API_ATTRIBUTES}import-fixtures/`;

// ------------------------------------
// Surveys/Inquiries
// ------------------------------------
export const API_SURVEYS = 'surveys/';
export const API_SURVEY_SUMMARY = `survey-reports/`;
export const API_FILL_SURVEYS = 'fill-surveys/';
export const API_SURVEY_GROUPS = 'survey-groups/';
export const API_SURVEY_GROUPS_BASIC = `${API_SURVEY_GROUPS}basic/`;
export const API_PENDING_SURVEYS = `${API_SURVEYS}pending/`;
export const api_survey_group = groupId => `${API_SURVEY_GROUPS}${groupId}/`;
export const api_survey_group_duplicate = groupId =>
  `${api_survey_group(groupId)}duplicate/`;
export const api_survey = surveyId => `${API_SURVEYS}${surveyId}/`;
export const api_survey_attributes = surveyId =>
  `${API_SURVEYS}${surveyId}/attributes/`;
export const api_survey_reviewers = surveyId =>
  `${API_SURVEYS}${surveyId}/reviewers/`;
export const api_review_participants_info = (surveyId, attributeId) =>
  `${API_SURVEYS}${surveyId}/${API_ATTRIBUTES}${attributeId}/`;
export const api_review_results = (surveyId, attributeId) =>
  `${API_SURVEYS}${surveyId}/${API_ATTRIBUTES}${attributeId}/answers/`;
export const api_text_reply_review_results = (surveyId, attributeId) =>
  `${API_SURVEYS}${surveyId}/${API_ATTRIBUTES}${attributeId}/replies/`;
export const api_review_feedback = (surveyId, attributeId) =>
  `${API_SURVEYS}${surveyId}/${API_ATTRIBUTES}${attributeId}/comments/`;
export const api_get_survey_tracking_by_id = id =>
  `${API_SURVEYS}${id}/tracking-token/`;
export const api_survey_force_complete = surveyId =>
  `${API_SURVEYS}${surveyId}/finish/`;
export const api_survey_send_reminder = surveyId =>
  `${API_SURVEYS}${surveyId}/remind/`;
export const api_survey_reopen = surveyId => `${API_SURVEYS}${surveyId}/open/`;
export const api_fill_survey = token => `${API_FILL_SURVEYS}${token}/`;
export const api_fill_review = (token, id) =>
  `${api_fill_survey(token)}attributes/${id}/`;
export const api_answer_on_question = token =>
  `${api_fill_survey(token)}answers/`;
export const api_answer_on_text_reply_question = token =>
  `${api_fill_survey(token)}replies/`;
export const api_finish_review = token => `${api_fill_survey(token)}finish/`;
export const api_survey_export = surveyId =>
  `${API_SURVEYS}${surveyId}/export/`;
export const api_review_score_per_reviewer = (surveyId, attributeId) =>
  `${API_SURVEYS}${surveyId}/${API_ATTRIBUTES}${attributeId}/score-per-reviewer/`;
export const api_survey_summary_report = surveyId =>
  `${API_SURVEYS}${surveyId}/reports/`;
export const api_review_score_per_subject = (surveyId, attributeId) =>
  `${API_SURVEYS}${surveyId}/${API_ATTRIBUTES}${attributeId}/score-per-subject/`;
export const api_survey_activate = surveyId =>
  `${API_SURVEYS}${surveyId}/activate/`;
export const api_survey_group_items_reorder = groupId =>
  `${API_SURVEY_GROUPS}${groupId}/ordering/`;
export const api_survey_summary = reportId =>
  `${API_SURVEY_SUMMARY}${reportId}/`;
export const api_survey_summary_shared_with = reportId =>
  `${API_SURVEY_SUMMARY}${reportId}/shared-to/`;
export const api_survey_summary_share = reportId =>
  `${API_SURVEY_SUMMARY}${reportId}/share/`;

// ------------------------------------
// Action Plans
// ------------------------------------

export const API_ACTION_PLANS = 'plans/';
export const API_ACTION_PLANS_EXPORT = `${API_ACTION_PLANS}export/`;
export const API_PLANS_BASIC = `${API_ACTION_PLANS}basic/`;
export const api_action_plan_tasks = id => `${API_ACTION_PLANS}${id}/tasks/`;
export const api_action_plans_with_view_permission = id =>
  `${API_ACTION_PLANS}${id}/users-with-view-permission/`;
export const api_user_action_plans = userId =>
  `${API_USERS}${userId}/${API_ACTION_PLANS}`;
export const api_action_plan = id => `${API_ACTION_PLANS}${id}/`;
export const api_action_plan_tasks_status = (id, taskId) =>
  `${api_action_plan_tasks(id)}${taskId}/status-toggle/`;
export const api_action_plan_comment = id => `${api_action_plan(id)}comment/`;
export const api_action_plan_comment_update = (id, commentId) =>
  `${api_action_plan_comment(id)}${commentId}/`;

// ------------------------------------
// Reports
// ------------------------------------

const API_REPORTS = 'reports/';
export const API_MEASURED_ATTRIBUTES = `${API_REPORTS}attribute-usage/`;
export const API_ATTRIBUTES_SCORE_DISTRIBUTION = `${API_REPORTS}attr-score-distribution/`;
export const API_ATTRIBUTES_USER_SCORES = `${API_REPORTS}attr-score-users/`;
export const API_COMPARE_REPORTS_REVIEWED_ATTRIBUTES =
  'users/reports/reviewed-attributes/';
export const API_COMPARE_REPORTS_USERS_BY_ATTRIBUTES =
  'users/reports/users-by-attributes/';
export const API_REPORT_ATTRIBUTE_ANSWERS = `${API_REPORTS}attributes/answers/`;
export const API_REPORT_ATTRIBUTE_REPLIES = `${API_REPORTS}attributes/replies/`;
export const API_REPORT_ATTRIBUTE_FEEDBACK = `${API_REPORTS}attributes/comments/`;
export const API_REPORT_ATTRIBUTE_TIMELINE = `${API_REPORTS}attributes/surveys-timeline/`;
export const API_REPORT_ATTRIBUTE_SCORE = `${API_REPORTS}attributes/score/`;
export const API_REPORT_USER_COMPARE = `${API_REPORTS}profiles/`;

// ------------------------------------
// OneOnOne
// ------------------------------------

export const API_ONE_ON_ONE = 'one-on-one/';
export const API_ONE_ON_ONE_MY_HISTORY = `${API_ONE_ON_ONE}my-history/`;
export const API_ONE_ON_ONE_TOPICS = `${API_ONE_ON_ONE}topics/`;
export const API_ONE_ON_ONE_USER_REQUEST = `${API_ONE_ON_ONE}requests/`;
export const API_ONE_ON_ONE_MODERATOR_REQUEST = `${API_ONE_ON_ONE}requested-for/`;
export const API_ONE_ON_ONE_REPORT = `${API_REPORTS}1-1-history/`;
export const API_ONE_ONE_ONE_REPORT_PARTIALS = `${API_REPORTS}1-1-partials/`;
export const api_one_on_one_record = id => `${API_ONE_ON_ONE}${id}/`;
export const api_one_on_one_record_agenda = id =>
  `${API_ONE_ON_ONE}${id}/agenda/`;
export const api_one_on_one_topic = id => `${API_ONE_ON_ONE_TOPICS}${id}/`;
export const api_one_on_one_comments = id =>
  `${api_one_on_one_record(id)}comments/`;
export const api_one_on_one_comment_update = (id, commentId) =>
  `${api_one_on_one_comments(id)}${commentId}/`;
export const api_one_on_one_tasks = id => `${API_ONE_ON_ONE}${id}/tasks/`;
export const api_one_on_one_tasks_status = (id, taskId) =>
  `${api_one_on_one_tasks(id)}${taskId}/status-toggle/`;
export const api_one_on_one_with_view_permission = id =>
  `${API_ONE_ON_ONE}${id}/users-with-view-permission/`;
export const api_one_on_one_with_notes_view_permission = id =>
  `${API_ONE_ON_ONE}${id}/users-with-notes-view-permission/`;

// ------------------------------------
// Positions
// ------------------------------------

export const API_POSITIONS = 'positions/';
// params: { name: string }
export const API_UNIQUE_POSITION = `${API_POSITIONS}unique/`;
export const api_position = id => `${API_POSITIONS}${id}/`;
export const API_IMPORT_POSITIONS = `${API_POSITIONS}import-fixtures/`;

// ------------------------------------
// Tags, Tag Categories
// ------------------------------------

export const API_TAGS = 'tags/';
export const API_TAGS_MULTIPLE = `${API_TAGS}multiple/`;
export const API_TAG_CATEGORIES = 'tag-categories/';
export const API_TAGS_IN_USE = `${API_TAGS}in-use/`;
export const API_TAG_CATEGORIES_REORDER = `${API_TAG_CATEGORIES}ordering/`;
export const api_page_tag_categories = pageId =>
  `${API_TAG_CATEGORIES}${pageId}/placements/`;
export const api_tag_category = categoryId =>
  `${API_TAG_CATEGORIES}${categoryId}/`;
export const api_tagged_users = categoryId =>
  `${API_TAG_CATEGORIES}${categoryId}/tagged-users/`;

// ------------------------------------
// Positions
// ------------------------------------

export const API_PRESETS = 'presets/';

// ------------------------------------
// eLearning
// ------------------------------------

export const API_COURSES = 'courses/';
export const API_UDEMY = 'udemy/';
export const API_AI = 'ai/';
export const api_course = courseId => `${API_COURSES}${courseId}/`;
export const api_course_users = courseId => `${API_COURSES}${courseId}/users/`;
export const api_user_courses = userId => `${API_USERS}${userId}/courses/`;
export const api_course_users_enroll = courseId =>
  `${API_COURSES}${courseId}/enroll/`;
export const api_course_users_disenroll = courseId =>
  `${API_COURSES}${courseId}/disenroll/`;
export const API_MY_COURSES = `${API_COURSES}me/`;
export const API_IMPORT_COURSES = `${API_COURSES}import-fixtures/`;

// ------------------------------------
// Subscription
// ------------------------------------

export const API_SUBSCRIPTION = 'subscriptions/';
export const API_CUSTOMER_PORTAL = 'subscriptions/customer-portal/';

// ------------------------------------
// Company Overview
// ------------------------------------

export const API_COMPANY = 'company/';
export const API_COMPANY_METRICS = `${API_COMPANY}metrics/`;
export const API_COMPANY_ONE_ON_ONE_STATS = `${API_COMPANY}stats/1-1/`;
export const API_COMPANY_ACTION_PLANS_STATS = `${API_COMPANY}stats/plans/`;
export const API_COMPANY_COURSES_STATS = `${API_COMPANY}stats/courses/`;
export const api_company_no_one_on_one = managerId =>
  `${API_COMPANY}${managerId}/without-1-1/`;
export const api_company_low_impressed = managerId =>
  `${API_COMPANY}${managerId}/low-impressed/`;

// ------------------------------------
// Leveling
// ------------------------------------

export const API_FRAMEWORKS = 'frameworks/';
export const api_frameworks = id => `${API_FRAMEWORKS}${id}/`;
export const api_framework_duplicate = frameworkId =>
  `${api_frameworks(frameworkId)}duplicate/`;
export const API_FRAMEWORK_ITEMS_REORDER = `${API_FRAMEWORKS}ordering/`;
export const api_leveling_add_template = `${API_FRAMEWORKS}add-template/`;
export const api_tracks_reorder = id => `${api_frameworks(id)}ordering/`;
export const API_TRACKS = 'tracks/';
export const api_tracks = id => `${API_TRACKS}${id}/`;
export const api_levels_reorder = id => `${api_tracks(id)}ordering/`;
export const api_track_duplicate = trackId =>
  `${api_tracks(trackId)}duplicate/`;
export const api_track_move = id => `${api_tracks(id)}move/`;
export const API_LEVELS = 'levels/';
export const api_levels = id => `${API_LEVELS}${id}/`;
export const api_levels_users = id => `${api_levels(id)}users/`;
export const api_level_duplicate = levelId =>
  `${api_levels(levelId)}duplicate/`;
export const api_level_move = id => `${api_levels(id)}move/`;

// ------------------------------------
// Slack
// ------------------------------------

export const API_SLACK_STATUS = 'slack/';
export const API_SLACK_DISCONNECT = 'slack/disconnect/';
export const API_SLACK_USERS = 'slack/users/';
export const API_SLACK_SYNC_USERS = 'slack/sync-users/';

// ------------------------------------
// Tasks
// ------------------------------------
export const API_TASKS = 'tasks/';
export const api_task_completion = taskId =>
  `${API_TASKS}${taskId}/status-toggle/`;

// ------------------------------------
// Team notifications
// ------------------------------------

export const API_NOTIFICATIONS = 'notifications/';
export const API_TEAM_NOTIFICATIONS = `${API_NOTIFICATIONS}reports/`;
export const api_clear_user_notifications = userId =>
  `${API_TEAM_NOTIFICATIONS}${userId}/clear/`;
export const api_clear_notification = notificationId =>
  `${API_NOTIFICATIONS}${notificationId}/clear/`;

// ------------------------------------
// AAbsences
// ------------------------------------

export const API_ABSENCE_SETS = 'absence-sets/';
export const API_ABSENCE_TYPES = 'absence-types/';
export const API_ABSENCE_EXTENSIONS = 'absence-extensions/';
export const API_ABSENCE_REQUESTS = 'absence-requests/';
export const API_UNIQUE_ABSENCE_SET = `${API_ABSENCE_SETS}exists/`;
export const API_HOLIDAYS = 'holidays/';
export const api_absence_set = setId => `${API_ABSENCE_SETS}${setId}/`;
export const api_absence_set_duplicate = setId =>
  `${API_ABSENCE_SETS}${setId}/duplicate/`;
export const api_absence_set_users = setId =>
  `${API_ABSENCE_SETS}${setId}/users/`;
export const api_absence_type = typeId => `${API_ABSENCE_TYPES}${typeId}/`;
export const api_absence_type_users = typeId =>
  `${API_ABSENCE_TYPES}${typeId}/users/`;
export const api_absence_extension = extensionId =>
  `${API_ABSENCE_EXTENSIONS}${extensionId}/`;
export const api_absence_extension_users = extensionId =>
  `${API_ABSENCE_EXTENSIONS}${extensionId}/users/`;
export const api_absence_request = requestId =>
  `${API_ABSENCE_REQUESTS}${requestId}/`;
export const api_absence_request_status = requestId =>
  `${API_ABSENCE_REQUESTS}${requestId}/confirm/`;
export const api_holiday = holidayId => `${API_HOLIDAYS}${holidayId}/`;

// ------------------------------------
// Rest
// ------------------------------------

export const API_ORGANIZATION_SETTINGS = 'config/';
export const API_RELEASE_NOTES = 'config/releases/';
export const API_DEMO_DATA = 'config/demo-data/';
