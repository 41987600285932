import { toast } from 'react-toastify';
import stringToColor from 'string-to-color';
import AlertBody from '../components/shared/alertBody';
import Avatar from '../components/shared/avatar';
import ExternalUserIcon from '../assets/icons/vpn_lock.svg';
import OrganizationIcon from '../assets/icons/apartment.svg';
import {
  checkUserRole,
  getObjectToNumberArray,
  isArray,
  isArrayEmpty,
  isSomeValueInList,
  isUserDeactivated,
  isValueInList,
} from './helpers';
import { formatDate } from './dateUtils';
import { ROLES } from '../constants/rolesAndPermissionList';
import { USER_INFO } from '../constants/people';
import { INCENTIVE, INCENTIVE_MAP } from '../components/pages/signUp/config';

export const showErrorMessage = text =>
  toast.error(<AlertBody text={text} type="error" />);
export const showSuccessMessage = text =>
  toast.success(<AlertBody text={text} type="success" />);

export const getInitials = (firstName, lastName) => {
  if (lastName && lastName.length > 0 && firstName && firstName.length > 0) {
    return `${firstName.charAt(0)}${lastName.charAt(0)}`;
  }
  if (firstName && firstName.length > 1) {
    return `${firstName.charAt(0)}${firstName.charAt(1)}`;
  }
  if (firstName && firstName.length > 0) {
    return `${firstName.charAt(0)}`;
  }
  return '?';
};

export const getPersonFullName = person => {
  if (!person) return '';

  if (person?.first_name || person?.last_name) {
    return `${person.first_name} ${person.isCeo ? '(' : ''}${person.last_name}${
      person.isCeo ? ')' : ''
    }`;
  }

  return person.email;
};

export const parseQuery = query =>
  query.length === 0
    ? []
    : query
        .replace(/^\?/, '')
        .split('&')
        .map(x => {
          const [key, value] = x.split('=');
          return { key, value };
        });

export const parseQueryParams = (query, whiteListedParams = []) =>
  query.length === 0
    ? {}
    : query
        .replace(/^\?/, '')
        .split('&')
        .reduce((acc, param) => {
          const [key, value] = param.split('=');
          const isAllowed = whiteListedParams.includes(key);

          if (isAllowed) {
            return {
              ...acc,
              ...(acc[key]
                ? {
                    [key]: [
                      ...(isArray(acc[key])
                        ? [...acc[key], +value || value]
                        : [acc[key], +value || value]),
                    ],
                  }
                : { [key]: +value || value }),
            };
          }
          return acc;
        }, {});

export const parseDuplicateParameters = params => {
  const keys = Object.keys(params);
  let options = '';

  keys.forEach(key => {
    const isParamTypeObject = typeof params[key] === 'object';
    const isParamTypeArray = isParamTypeObject && params[key].length >= 0;

    if (!isParamTypeObject) {
      options += `${key}=${params[key]}&`;
    }

    if (isParamTypeObject && isParamTypeArray) {
      params[key].forEach(element => {
        options += `${key}=${element}&`;
      });
    }
  });

  return options ? options.slice(0, -1) : options;
};

export const manageQuery = ({ key, value, query, shouldDelete }) => {
  const currentQuery = parseQuery(query);
  const queryItemIndex = currentQuery.findIndex(q => q.key === key);

  if (shouldDelete) {
    if (queryItemIndex !== -1) {
      currentQuery.splice(queryItemIndex, 1);
    }
  } else {
    currentQuery.splice(
      queryItemIndex === -1 ? currentQuery.length : queryItemIndex,
      queryItemIndex === -1 ? 0 : 1,
      {
        key,
        value,
      }
    );
  }
  return currentQuery;
};

export const parameterizeQuery = parsedQuery =>
  parsedQuery.reduce(
    (acc, queryItem) => ({ ...acc, [queryItem.key]: queryItem.value }),
    {}
  );

export const orderingString = (column, asc) =>
  `${asc ? '' : '-'}${column.sortAs || column.recordKey}`;

export const capitalize = str => {
  return str && str.charAt(0).toUpperCase() + str.slice(1);
};

export const getSubdomain = hostname => {
  const regexParse = /[a-z-0-9]{2,63}.[a-z.]{2,5}$/;
  const urlParts = regexParse.exec(hostname);
  return hostname.replace(urlParts[0], '').slice(0, -1);
};

export const isEmployeeInReporters = (employeeId, reporters = {}) => {
  return Object.keys(reporters).includes(employeeId?.toString());
};

export const hasCollaborationPrivileges = (users, userId) =>
  users?.[userId]?.read_only === false;

export const mergeAccessibleProfilesForUser = ({
  reporters,
  accessible,
  role,
}) => {
  const shared = {};
  const isUser = checkUserRole(role, ROLES.USER);

  if (accessible) {
    Object.keys(accessible).forEach(key => {
      shared[key] = true;
    });
  }

  return isUser ? shared : { ...reporters, ...shared };
};

export const prepareTagsForAdding = (tags, categoryId, tagId) => {
  return tags
    .trim()
    .split(',')
    .reduce((acc, el) => {
      const tagName = el.trim().replace(/[\n\r]/g, '');

      if (tagName === '') {
        return acc;
      }
      const newTag = {
        name: tagName,
        is_active: true,
        category: categoryId,
      };

      if (tagId) {
        newTag.id = tagId;
      }
      return acc.concat(newTag);
    }, []);
};

export const reformatUserForSurvey = user => {
  return {
    id: user.id,
    avatar: user.avatar,
    first_name: user.first_name,
    last_name: user.last_name,
  };
};

export const reformatAttributeForSurvey = attribute => {
  return {
    id: attribute.id,
    code: attribute.code,
    name: attribute.name,
    questions: attribute.questions,
    color: attribute.color,
    total_questions: attribute.questions.length,
  };
};

export const truncateText = (str, truncateAfter) =>
  str.length > truncateAfter
    ? `${str.substring(0, truncateAfter).trimEnd().concat('...')}`
    : str;

export const getWorkspaceNameFromHostName = url => {
  return url.slice(0, url.search(process.env.REACT_APP_API_DOMAIN) - 1);
};

export const formatUserForOrgChart = (user, hasAccessToProfile = false) => {
  return {
    id: user.id,
    person: {
      ...user,
      name: truncateText(getPersonFullName(user), 18),
      title: user?.position?.name || '',
      totalReports: user.users.length,
      hasAccessToProfile,
    },
    hasChild: user.users.length > 0,
    hasParent: user.report_to > 0,
    children: [],
  };
};

export const toggleTidioChatVisibility = (shouldHideChat = true) => {
  const chatApi = window.tidioChatApi;

  if (chatApi) {
    if (shouldHideChat) {
      chatApi.hide();
    } else {
      chatApi.show();
      chatApi.open();
    }
  }
};

export const getContentLength = htmlString => {
  return htmlString.replace(/(<([^>]+)>)/gi, '').length;
};

export const customSearch = (
  array,
  string = '',
  isUserSearch = false,
  searchKey = 'name'
) => {
  const searchTerm = string.toLowerCase();

  if (!searchTerm) return array;

  if (isUserSearch) {
    return array.filter(item =>
      getPersonFullName(item).toLowerCase().includes(searchTerm)
    );
  }

  return array.filter(
    item => item[searchKey].toLowerCase().indexOf(searchTerm) !== -1
  );
};

export const hasNextPage = results => !!results.next;

export const prepareFrameworksWithTracks = (frameworks = []) => {
  return frameworks.reduce((acc, framework) => {
    const frameworkTracks = framework.tracks;

    if (!isArrayEmpty(frameworkTracks)) {
      const tracks = frameworkTracks.filter(
        track => !isArrayEmpty(track.levels)
      );

      return isArrayEmpty(tracks) ? acc : [...acc, { ...framework, tracks }];
    }

    return acc;
  }, []);
};

export const prepareTracksWithLevels = (tracks = []) => {
  return tracks.reduce((acc, track) => {
    const trackLevels = track.levels;

    if (!isArrayEmpty(trackLevels)) {
      return [...acc, { ...track, levels: trackLevels }];
    }

    return acc;
  }, []);
};

export const onScrollIntoView = ({ current }, options = {}) => {
  if (current) {
    current.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      ...options,
    });
  }
};

export const getUserAvatarPicture = (
  role,
  avatar,
  slackImage,
  isOrganizationUser = false
) => {
  const isExternal = checkUserRole(role, ROLES.ASSOCIATE);

  if (isOrganizationUser) return OrganizationIcon;

  if (isExternal) return ExternalUserIcon;

  if (avatar) return avatar;

  return slackImage;
};

export const getPersonalAttributeTooltipText = (label, user) =>
  label.replace('[FULL NAME]', getPersonFullName(user));

export const getLevelingDrawerTitle = (label, operationType, type) =>
  label.replace('[LABEL]', operationType).replace('[RECORD TYPE]', type);

export const getUserProfileLink = (userId, isVisible) =>
  isVisible ? `/people/${userId}` : undefined;

export const getRandomColorFromColorArray = colors => {
  const randomIndex = Math.floor(Math.random() * colors.length);
  return colors[randomIndex];
};

export const sortAlphabetically = (items, key = 'name') =>
  items.sort((a, b) => a[key].localeCompare(b[key]));

export const getShareTypeUserLabel = (translations, user) =>
  user.read_only ? translations.viewer : translations.collaborator;

export const getProgressWidth = progress => ({
  width: `${progress === 0 ? 100 : progress * 10}%`,
});

export const getProgress = progress => `${progress * 10}%`;

export const getFilterableUsers = users =>
  users.map(user => ({
    ...user,
    name: getPersonFullName(user),
    icon: () => (
      <Avatar
        avatarPicture={getUserAvatarPicture(
          user[USER_INFO.ROLE],
          user.avatar,
          user.slack_image
        )}
        avatarColor={stringToColor(user.id)}
        avatarText={getInitials(
          user[USER_INFO.FIRST_NAME],
          user[USER_INFO.LAST_NAME]
        )}
        extraSmall
      />
    ),
  }));

export const getUserRequestDateLabel = (label, date) =>
  `${label} \u2022 ${formatDate(date, 'MMM d, yyyy')}`;

export const getTimeDifference = (difference, translations) => {
  if (difference === 0) return translations.today;
  if (difference === -1) return translations.yesterday;
  return `${Math.abs(difference)} ${translations.days} ${translations.ago}`;
};

export const getInstructionGuideMenuItems = (
  translations,
  user,
  organizationSettings
) => {
  const incentives = user?.init_settings?.incentive
    ? user.init_settings.incentive
    : [];
  const incentiveIndexes = incentives?.reduce(
    (acc, curr) => [...acc, INCENTIVE.findIndex(item => curr === item)],
    []
  );
  const { LEVEL_AND_JOB, GOALS_AND_EXPECTATIONS, TEAMS_WELL_BEING, LEARNING } =
    INCENTIVE_MAP;
  const isUserRole = checkUserRole(user.role, ROLES.USER);
  const isUserWithFullTransparency =
    isUserRole && organizationSettings?.global_see_himself;
  const isUserWithoutFullTransparency =
    isUserRole && !organizationSettings?.global_see_himself;
  const hasFullTransparency = !isUserRole;

  return [
    ...(hasFullTransparency
      ? [
          {
            id: 1,
            name: translations.onboardingYourTeam,
            src: 'https://www.tella.tv/video/cm10lv88z000c03lc281g89uu/embed?b=0&title=0&a=1&loop=0&t=0&muted=0&wt=0',
            hasTooltip: false,
          },
          {
            id: 2,
            name: translations.jobRoles,
            src: 'https://www.tella.tv/video/cm158rq2s000003mgg7690pz3/embed?b=0&title=0&a=1&loop=0&t=0&muted=0&wt=0',
            hasTooltip: incentiveIndexes.includes(LEVEL_AND_JOB),
          },
          {
            id: 3,
            name: translations.attributes,
            src: 'https://www.tella.tv/video/cm15kf4tk000k03k28s1r01y9/embed?b=0&title=0&a=1&loop=0&t=0&muted=0&wt=0',
            hasTooltip: false,
          },
          {
            id: 4,
            name: translations.reviews,
            src: 'https://www.tella.tv/video/cm1j91e46000m03l35mbwhvxy/embed?b=0&title=0&a=1&loop=0&t=0&muted=0&wt=0',
            hasTooltip: incentiveIndexes.includes(GOALS_AND_EXPECTATIONS),
          },
          {
            id: 5,
            name: translations.skillMetrics,
            src: 'https://www.tella.tv/video/cm1kreewl000d03kxflimcdph/embed?b=0&title=0&a=1&loop=0&t=0&muted=0&wt=0',
            hasTooltip: isSomeValueInList(incentiveIndexes, [
              TEAMS_WELL_BEING,
              GOALS_AND_EXPECTATIONS,
            ]),
          },
          {
            id: 6,
            name: translations.actionPlans,
            src: 'https://www.tella.tv/video/cm1awudjn000603ky2ux81698/embed?b=0&title=0&a=1&loop=0&t=0&muted=0&wt=0',
            hasTooltip: isSomeValueInList(incentiveIndexes, [
              TEAMS_WELL_BEING,
              GOALS_AND_EXPECTATIONS,
              LEARNING,
            ]),
          },
          {
            id: 7,
            name: translations.oneOnOne,
            src: 'https://www.tella.tv/video/cm1ezlv7x000e03l7fgai0wvb/embed?b=0&title=0&a=1&loop=0&t=0&muted=0&wt=0',
            hasTooltip: incentiveIndexes.includes(TEAMS_WELL_BEING),
          },
          {
            id: 8,
            name: translations.managerInsights,
            src: 'https://www.tella.tv/video/cm24qjaqu000c03lahsc4glnn/embed?b=0&title=0&a=1&loop=0&t=0&muted=0&wt=0',
            hasTooltip: incentiveIndexes.includes(GOALS_AND_EXPECTATIONS),
          },
        ]
      : []),
    ...(isUserWithFullTransparency
      ? [
          {
            id: 1,
            name: translations.kadarOverview,
            src: 'https://www.tella.tv/video/cm2wfbo61000003l1bfhx2jbl/embed?b=0&title=0&a=1&loop=0&t=0&muted=0&wt=0',
            hasTooltip: false,
          },
          {
            id: 2,
            name: translations.yourProfile,
            src: 'https://www.tella.tv/video/cm2wh54vs001n03l6fbk98h4c/embed?b=0&title=0&a=1&loop=0&t=0&muted=0&wt=0',
            hasTooltip: false,
          },
        ]
      : []),
    ...(isUserWithoutFullTransparency
      ? [
          {
            id: 1,
            name: translations.kadarOverview,
            src: 'https://www.tella.tv/video/cm2wbko7s000903micxmme4ch/embed?b=0&title=0&a=1&loop=0&t=0&muted=0&wt=0',
            hasTooltip: false,
          },
        ]
      : []),
  ];
};

export const getSeeTypeLabel = (labels, record, user, isOneOnOne = false) => {
  const currentUser = record?.user;
  const isCurrentUser = currentUser?.id === user.id;
  const isAdmin = checkUserRole(user.role, ROLES.ADMIN);
  const directReportTo = currentUser?.report_to === user.id;
  const inUsersBranch = currentUser?.id !== user.report_to;
  const isRecordCreator = record?.moderator
    ? record?.moderator?.id === user.id
    : record?.creator?.id === user.id;

  const isShared =
    !directReportTo && user?.accessible && user?.accessible[currentUser.id];

  if (isCurrentUser) {
    return isOneOnOne ? labels.withUser : labels.forUser;
  } else if (isRecordCreator) {
    return isOneOnOne ? labels.organizedBy : labels.createdBy;
  } else if (isAdmin) {
    return labels.admin;
  } else if (isShared) {
    return labels.userSharedWith;
  } else if (directReportTo) {
    return labels.directReportTo;
  } else if (inUsersBranch) {
    return labels.inUsersBranch;
  }

  return null;
};

export const getUserStatusLabel = (label, user) => {
  const isDeactivatedUser = isUserDeactivated(user);

  if (isDeactivatedUser) {
    return label;
  }

  return null;
};

export const prepareUsersForUpdate = (
  allUsers,
  selectedUsers,
  allFieldUsers,
  recordId,
  fieldName
) => {
  const allUserIds = getObjectToNumberArray(allFieldUsers);
  const allUsersIds = getObjectToNumberArray(allUsers);

  const removeUsersField = allUserIds
    .filter(
      user =>
        !isValueInList(selectedUsers, user) && isValueInList(allUsersIds, user)
    )
    .map(user => {
      return {
        id: user,
        [fieldName]: null,
      };
    });

  const assignUsersField = selectedUsers
    .filter(user => !isValueInList(allFieldUsers, user))
    .map(user => {
      return {
        id: user,
        [fieldName]: recordId,
      };
    });

  return removeUsersField.concat(assignUsersField);
};
