import { createAction, handleActions } from 'redux-actions';
import http from '../../utility/http';
import { API_ABSENCE_SETS } from '../../constants/apiRoutes';

// ------------------------------------
// Constants
// ------------------------------------

const SET_ALL_ABSENCE_SETS = 'SET_ALL_ABSENCE_SETS';

const initialState = [];

// ------------------------------------
// Actions
// ------------------------------------

const setAllAbsenceSets = createAction(SET_ALL_ABSENCE_SETS);

// ------------------------------------
// Reducers
// ------------------------------------

const reducers = {
  [SET_ALL_ABSENCE_SETS]: (state, { payload }) => {
    return [...payload];
  },
};

export const getAllAbsenceSets = dispatch => {
  return http.get(API_ABSENCE_SETS).then(({ data }) => {
    dispatch(setAllAbsenceSets(data));
  });
};

// ------------------------------------
// "plain" action functions
// ------------------------------------

export const clearAllAbsenceSets = () => setAllAbsenceSets(initialState);

export default handleActions(reducers, initialState);
