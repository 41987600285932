import { useState, useCallback, useEffect, useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { Typography, makeStyles } from '@material-ui/core';
import PeoplePageContainer from '../../shared/peoplePageContainer';
import NotificationCard from '../../shared/notificationCard';
import CustomButton from '../../shared/customButton';
import ConditionalTooltip from '../../shared/conditionalTooltip';
import SectionTitle from '../../shared/sectionTitle';
import GridTable from '../../shared/gridTable';
import CustomFormDrawer from '../../shared/customFormDrawer';
import AbsenceTypesCards from '../../shared/absenceTypesCards';
import StatusIndicator from '../../shared/statusIndicator';
import Tooltip from '../../shared/tooltip';
import DotsMenu from '../../shared/dotsMenu';
import ActionButton from '../../shared/actionButton';
import AlertDialog from '../../shared/alertDialog';
import SelectField from '../../shared/selectField';
import { personAbsencesSelector } from '../../../store/selectors/personAbsencesSelector';
import {
  getPersonAbsenceBalance,
  getPersonAbsenceRequests,
  getPersonUpcomingTimeOff,
  clearPersonAbsences,
} from '../../../store/modules/personAbsences';
import http from '../../../utility/http';
import { useTranslations } from '../../../utility/useTranslations';
import { personSelector } from '../../../store/selectors/personSelector';
import { useCustomEffect } from '../../../utility/hooks';
import { showSuccessMessage, hasNextPage } from '../../../utility/uiUtils';
import {
  isUserDeactivated,
  checkUserRole,
  isArrayEmpty,
  isArray,
} from '../../../utility/helpers';
import { getYearFromDate } from '../../../utility/dateUtils';
import {
  getTranslatedAbsenceTypes,
  getUserAbsenceTypes,
  getManageLeaveRequestFields,
  getManageLeaveRequestInitialData,
  getTranslatedAbsenceRequestStatuses,
  getLeaveRequestMenuItems,
  getYearOptions,
  getUserAvailableAbsenceTypes,
  getFilteredUserAbsenceTypes,
} from '../../../utility/absence';
import { sticky } from '../../../constants/helperCssRules';
import { ROLES } from '../../../constants/rolesAndPermissionList';
import {
  APP_PAGES,
  PARAMS,
  PEOPLE_ABSENCE_REQUESTS_DEFAULT_PARAMS,
} from '../../../constants/pages';
import {
  API_ABSENCE_REQUESTS,
  api_absence_request,
  api_absence_request_status,
  api_user_absences,
} from '../../../constants/apiRoutes';
import { ACTION_BUTTON_TYPES } from '../../shared/actionButton/config';
import { ABSENCE_REQUEST_STATUS_TYPES } from '../../../constants/statuses';
import {
  getRollOverBalanceField,
  getUpcomingTimeOffTableHeaders,
  getUpcomingTimeOffTableData,
  getLeaveRequestsTableHeaders,
  getLeaveRequestsTableActionsHeader,
  getLeaveRequestsTableData,
} from './config';

const useStyles = makeStyles(
  ({ palette: { primary }, spacing, breakpoints }) => ({
    header: {
      paddingBottom: spacing(4),
      ...sticky(primary.white, 170),
    },
    description: {
      marginBottom: spacing(4),
    },
    timeOffSection: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    titleWrapper: {
      display: 'flex',
      alignItems: 'center',
    },
    title: {
      marginRight: spacing(4),
    },
    year: {
      minWidth: 100,
      width: 100,
    },
    yearMenu: {
      zIndex: 1000,
    },
    disabledUserAction: {
      cursor: 'not-allowed',
    },
    section: {
      marginTop: spacing(10),
    },
    sectionSpacing: {
      marginBottom: spacing(4),
    },
    sectionDescription: {
      marginTop: spacing(2),
      marginBottom: spacing(4),
    },
    form: {
      display: 'grid',
      gridColumnGap: 12,
      gridTemplateColumns: 'repeat(2, minmax(0, 1fr)) 85px',
      gridTemplateAreas: `
        "type type type"  
        "from to amount"
        "description description description"
      `,
    },
    typeField: {
      gridArea: 'type',
    },
    fromField: {
      gridArea: 'from',
    },
    toField: {
      gridArea: 'to',
    },
    amountField: {
      gridArea: 'amount',
    },
    descriptionField: {
      gridArea: 'description',
    },
    tableHeader: {
      borderRadius: 4,
    },
    tableHeaderCell: {
      color: primary.indigo2,
      padding: spacing(1.5, 2),
      [breakpoints.up('xLg')]: {
        padding: spacing(1.5, 2),
      },
    },
    tableRowCell: {
      padding: spacing(4, 2),
      [breakpoints.up('xLg')]: {
        padding: spacing(4, 2),
      },
    },
    cellValue: {
      color: primary.indigo,
    },
    actionsCell: {
      overflow: 'visible',
      paddingLeft: 0,
      paddingTop: spacing(0),
      paddingBottom: spacing(0),
      paddingRight: spacing(2),

      [breakpoints.up('xLg')]: {
        paddingRight: spacing(2),
      },
    },
    icon: {
      cursor: 'default',
    },
    actionsWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
    statusActions: {
      display: 'grid',
      gridTemplateColumns: 'repeat(2, 32px)',
      gridColumnGap: 4,
    },
    dotsMenu: {
      marginLeft: spacing(1),
    },
    numberOfDaysField: {
      width: 75,
    },
  })
);

const PeopleAbsencesPage = ({ auth, dispatch, ...rest }) => {
  const { id } = useParams();
  const userId = (!Number.isNaN(Number(id)) && +id) || -1;
  const { page: defaultPage } = PEOPLE_ABSENCE_REQUESTS_DEFAULT_PARAMS;

  const classes = useStyles();
  const translations = useTranslations(APP_PAGES.PEOPLE_ABSCENCES);

  const user = useSelector(personSelector);
  const { balance, requests, upcomingTimeOff } = useSelector(
    personAbsencesSelector
  );
  const yearOptions = getYearOptions();

  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [isManageRequestOpened, setIsManageRequestOpened] = useState(false);
  const [requestsPage, setRequestsPage] = useState(1);
  const [upcomingTimeOffPage, setUpcomingTimeOffPage] = useState(1);
  const [isDeleteRequestOpened, setIsDeleteRequestOpened] = useState(false);
  const [leaveRequest, setLeaveRequest] = useState(null);
  const [isApproveRequestOpened, setIsApproveRequestOpened] = useState(false);
  const [isRejectRequestOpened, setIsRejectRequestOpened] = useState(false);
  const [isEditBalanceOpened, setIsEditBalanceOpened] = useState(false);
  const [absenceType, setAbsenceType] = useState(null);
  const [currentYear, setCurrentYear] = useState(yearOptions[1].value);

  const isAdmin = checkUserRole(auth.role, ROLES.ADMIN);
  const isCurrentUser = user?.id === auth.id;
  const isDeactivatedUser = isUserDeactivated(user);
  const isCurrentYearSelected = currentYear === getYearFromDate(new Date());
  const canRequestLeave = isAdmin || isCurrentUser;
  const absenceTypes = getTranslatedAbsenceTypes(translations.absenceTypes);
  const userAbsenceTypes = getUserAbsenceTypes(absenceTypes, balance);
  const userAbsenceTypesFiltered =
    getFilteredUserAbsenceTypes(userAbsenceTypes);
  const availableUserAbsenceTypes =
    getUserAvailableAbsenceTypes(userAbsenceTypes);
  const requestStatuses = getTranslatedAbsenceRequestStatuses(
    translations.requestStatuses
  );
  const manageRequestInitialData = getManageLeaveRequestInitialData(
    leaveRequest,
    user
  );
  const manageRequestFields = getManageLeaveRequestFields(
    classes,
    currentYear,
    !!leaveRequest
  );
  const upcomingTimeOffTableHeaders = getUpcomingTimeOffTableHeaders(
    translations.table,
    classes
  );
  const rollOverBalanceField = getRollOverBalanceField(classes);
  const leaveRequestsTableHeaders = getLeaveRequestsTableHeaders(
    classes,
    translations.table
  );
  const leaveRequestsTableActions =
    isAdmin || isCurrentUser
      ? getLeaveRequestsTableActionsHeader(classes, translations.table, isAdmin)
      : [];
  const hasLoadMoreRequests = hasNextPage(requests);
  const hasLoadMoreTimeOff = hasNextPage(upcomingTimeOff);
  const yearParams = useMemo(
    () => ({ [PARAMS.YEAR]: currentYear }),
    [currentYear]
  );

  const handleGetRequests = ({ newPage, isLoadMore } = {}) => {
    const params = {
      [PARAMS.PAGE]: newPage,
      [PARAMS.YEAR]: currentYear,
    };

    return getPersonAbsenceRequests(dispatch, userId, !!isLoadMore, params);
  };

  const onChangeYear = selectedYear => setCurrentYear(selectedYear);

  const onLoadMoreRequests = () => {
    setRequestsPage(requestsPage + 1);
    handleGetRequests({
      newPage: requestsPage + 1,
      isLoadMore: true,
    });
  };

  const handleGetUpcomingTimeOff = ({ newPage, isLoadMore } = {}) => {
    const params = {
      [PARAMS.PAGE]: newPage,
    };

    if (isCurrentYearSelected) {
      return getPersonUpcomingTimeOff(dispatch, userId, !!isLoadMore, params);
    }
  };

  const onLoadMoreUpcomingTimeOff = () => {
    setUpcomingTimeOffPage(upcomingTimeOffPage + 1);
    handleGetUpcomingTimeOff({
      newPage: upcomingTimeOffPage + 1,
      isLoadMore: true,
    });
  };

  const handleOpenManageRequest = () => setIsManageRequestOpened(true);

  const handleOpenEditRequest = currentRequest => {
    setIsManageRequestOpened(true);
    setLeaveRequest(currentRequest);
  };

  const handleCloseManageRequest = () => {
    setIsManageRequestOpened(false);
    setLeaveRequest(null);
  };

  const onSaveRequest = async ({
    absence_type,
    description,
    absence_from,
    absence_to,
  }) => {
    const data = {
      absence_type,
      description,
      absence_from,
      absence_to,
      user: userId,
      year: currentYear,
    };

    if (leaveRequest) {
      await http.patch(api_absence_request(leaveRequest.id), data);
      showSuccessMessage(translations.manageLeaveRequest.successMessages.edit);
      setUpcomingTimeOffPage(defaultPage);
      setRequestsPage(defaultPage);
      handleGetUpcomingTimeOff({
        newPage: defaultPage,
      });
      handleGetRequests({
        newPage: defaultPage,
      });
      return getPersonAbsenceBalance(dispatch, userId, currentYear);
    }

    await http.post(API_ABSENCE_REQUESTS, data);
    showSuccessMessage(translations.manageLeaveRequest.successMessages.add);
    setUpcomingTimeOffPage(defaultPage);
    setRequestsPage(defaultPage);
    handleGetUpcomingTimeOff({
      newPage: defaultPage,
    });
    handleGetRequests({
      newPage: defaultPage,
    });
    return getPersonAbsenceBalance(dispatch, userId, currentYear);
  };

  const handleOpenDeleteRequest = currentRequest => {
    setIsDeleteRequestOpened(true);
    setLeaveRequest(currentRequest);
  };

  const handleCloseDeleteRequest = () => {
    setIsDeleteRequestOpened(false);
  };

  const onDeleteRequest = async () => {
    try {
      await http.delete(api_absence_request(leaveRequest.id));
      showSuccessMessage(translations.deleteRequestDialog.deleteSuccess);
      setUpcomingTimeOffPage(defaultPage);
      setRequestsPage(defaultPage);
      handleGetUpcomingTimeOff({
        newPage: defaultPage,
      });
      handleGetRequests({
        newPage: defaultPage,
      });
      getPersonAbsenceBalance(dispatch, userId, currentYear);
    } finally {
      setIsDeleteRequestOpened(false);
      setLeaveRequest(null);
      setIsManageRequestOpened(false);
    }
  };

  const handleOpenApproveRequest = currentRequest => () => {
    setIsApproveRequestOpened(true);
    setLeaveRequest(currentRequest);
  };

  const handleCloseApproveRequest = () => {
    setIsApproveRequestOpened(false);
    setLeaveRequest(null);
  };

  const onApproveRequest = async () => {
    try {
      await http.patch(api_absence_request_status(leaveRequest.id), {
        [PARAMS.STATUS]: ABSENCE_REQUEST_STATUS_TYPES.APPROVED,
      });
      showSuccessMessage(translations.approveRequestDialog.approveSuccess);
      setUpcomingTimeOffPage(defaultPage);
      setRequestsPage(defaultPage);
      handleGetUpcomingTimeOff({
        newPage: defaultPage,
      });
      handleGetRequests({
        newPage: defaultPage,
      });
      getPersonAbsenceBalance(dispatch, userId, currentYear);
    } finally {
      handleCloseApproveRequest();
    }
  };

  const handleOpenRejectRequest = currentRequest => () => {
    setIsRejectRequestOpened(true);
    setLeaveRequest(currentRequest);
  };

  const handleCloseRejectRequest = () => {
    setIsRejectRequestOpened(false);
    setLeaveRequest(null);
  };

  const onRejectRequest = async () => {
    try {
      await http.patch(api_absence_request_status(leaveRequest.id), {
        [PARAMS.STATUS]: ABSENCE_REQUEST_STATUS_TYPES.REJECTED,
      });
      showSuccessMessage(translations.rejectRequestDialog.rejectSuccess);
      setRequestsPage(defaultPage);
      handleGetRequests({
        newPage: defaultPage,
      });
    } finally {
      handleCloseRejectRequest();
    }
  };

  const handleOpenEditBalance = userAbsenceType => () => {
    setIsEditBalanceOpened(true);
    setAbsenceType(userAbsenceType);
  };

  const handleCloseEditBalance = () => {
    setIsEditBalanceOpened(false);
    setAbsenceType(null);
  };

  const onSaveBalance = async ({ total_previous_year }) => {
    await http.patch(api_user_absences(userId), {
      absence_type: absenceType.type,
      total_previous_year: +total_previous_year,
    });
    showSuccessMessage(
      translations.updateAbsenceTypeBalance.successMessages.edit
    );
    getPersonAbsenceBalance(dispatch, userId, currentYear);
  };

  const getInitialData = useCallback(async () => {
    try {
      await Promise.all([
        getPersonAbsenceBalance(dispatch, userId, currentYear),
        ...(isCurrentYearSelected
          ? [getPersonUpcomingTimeOff(dispatch, userId, false)]
          : []),
        getPersonAbsenceRequests(dispatch, userId, false, yearParams),
      ]);
    } finally {
      setIsInitialLoad(false);
    }
  }, [dispatch, userId, currentYear, yearParams, isCurrentYearSelected]);

  const cleanup = useCallback(() => {
    dispatch(clearPersonAbsences());
  }, [dispatch]);

  useCustomEffect(() => {
    getInitialData();
  }, [getInitialData]);

  useEffect(() => {
    return cleanup;
  }, [cleanup]);

  const renderStatus = currentStatus => (
    <StatusIndicator status={currentStatus} />
  );

  const renderType = currentType => (
    <Tooltip
      customIconClass={classes.icon}
      text={currentType.name}
      icon={currentType.icon}
    />
  );

  const renderRequestTableActions = (
    request,
    hasEdit,
    hasDelete,
    hasStatusActions
  ) => {
    const menuItems = getLeaveRequestMenuItems(
      translations.menuItems,
      request,
      hasEdit,
      hasDelete,
      handleOpenEditRequest,
      handleOpenDeleteRequest
    );

    return (
      <div className={classes.actionsWrapper}>
        {hasStatusActions && (
          <div className={classes.statusActions}>
            <ActionButton
              type={ACTION_BUTTON_TYPES.CLOSE}
              tooltipText={translations.menuItems.reject}
              onClickHandler={handleOpenRejectRequest(request)}
              isDisabled={isDeactivatedUser}
              disabledTooltipText={
                translations.rejectRequestAbsenceButtonDisabled
              }
              isSquared
            />
            <ActionButton
              type={ACTION_BUTTON_TYPES.ACCEPT}
              tooltipText={translations.menuItems.approve}
              onClickHandler={handleOpenApproveRequest(request)}
              isDisabled={isDeactivatedUser}
              disabledTooltipText={
                translations.approveRequestAbsenceButtonDisabled
              }
              isSquared
            />
          </div>
        )}
        {(hasEdit || hasDelete) && (
          <DotsMenu
            className={classes.dotsMenu}
            menuItems={menuItems}
            isVerticalMenu
          />
        )}
      </div>
    );
  };

  const upcomingTimeOffTableData = getUpcomingTimeOffTableData(
    translations.table,
    upcomingTimeOff?.results || [],
    requestStatuses,
    absenceTypes,
    renderType,
    renderStatus
  );

  const leaveRequestsTableData = getLeaveRequestsTableData(
    translations.table,
    requests?.results || [],
    requestStatuses,
    absenceTypes,
    isDeactivatedUser,
    isAdmin,
    isCurrentUser,
    renderType,
    renderStatus,
    renderRequestTableActions
  );

  return (
    <PeoplePageContainer
      {...rest}
      translations={translations}
      auth={auth}
      userId={userId}
      dispatch={dispatch}
      shouldPassProps={false}
    >
      {!isInitialLoad && (
        <div>
          <div className={classes.header}>
            <Typography className={classes.description} variant="body2">
              {translations.description}
            </Typography>
            <div className={classes.timeOffSection}>
              <div className={classes.titleWrapper}>
                <SectionTitle
                  className={classes.title}
                  variant="h1"
                  title={translations.timeOff}
                />
                <SelectField
                  className={classes.year}
                  menuClass={classes.yearMenu}
                  value={currentYear}
                  options={yearOptions}
                  onChange={onChangeYear}
                  isSearchDisabled
                  shouldRemoveLabel
                />
              </div>
              {canRequestLeave &&
                !isArrayEmpty(balance) &&
                !!availableUserAbsenceTypes.length && (
                  <ConditionalTooltip
                    className={classes.disabledUserAction}
                    message={translations.requestAbsenceButtonDisabled}
                    addTooltip={isDeactivatedUser}
                  >
                    <CustomButton
                      type="addRoundedNew"
                      disabled={isDeactivatedUser}
                      onClick={handleOpenManageRequest}
                    >
                      {isAdmin
                        ? translations.addAbsenceButton
                        : translations.requestAbsenceButton}
                    </CustomButton>
                  </ConditionalTooltip>
                )}
            </div>
          </div>
          <AbsenceTypesCards
            translations={translations.absenceCards}
            abscenceTypes={userAbsenceTypesFiltered}
            canManageBalance={
              isAdmin && isCurrentYearSelected && !isDeactivatedUser
            }
            onManageBalance={handleOpenEditBalance}
          />
          <NotificationCard
            title={translations.noResults.title}
            content={translations.noResults.message}
            shouldFade={!isInitialLoad && isArrayEmpty(balance)}
          />
          {isCurrentYearSelected && (
            <SectionTitle
              className={classNames(classes.section, classes.sectionSpacing)}
              variant="h4"
              title={translations.upcomingTimeOffSection}
            />
          )}
          {isCurrentYearSelected &&
            isArray(upcomingTimeOff.results) &&
            !isArrayEmpty(upcomingTimeOff.results) && (
              <GridTable
                translations={translations.table}
                customHeaderClass={classes.tableHeader}
                headers={upcomingTimeOffTableHeaders}
                rows={upcomingTimeOffTableData}
                hasLoadMore={hasLoadMoreTimeOff}
                onLoadMore={onLoadMoreUpcomingTimeOff}
              />
            )}
          <NotificationCard
            content={translations.noUpcomingTimeOff}
            shouldFade={
              !isInitialLoad &&
              isCurrentYearSelected &&
              isArrayEmpty(upcomingTimeOffTableData)
            }
          />
          <SectionTitle
            className={classes.section}
            variant="h4"
            title={translations.timeline.title}
          />
          <Typography className={classes.sectionDescription} variant="body2">
            {translations.timeline.description}
          </Typography>
          {isArray(requests.results) && !isArrayEmpty(requests.results) && (
            <GridTable
              translations={translations.table}
              customHeaderClass={classes.tableHeader}
              headers={leaveRequestsTableHeaders}
              headerActions={leaveRequestsTableActions}
              rows={leaveRequestsTableData}
              hasLoadMore={hasLoadMoreRequests}
              onLoadMore={onLoadMoreRequests}
            />
          )}
          <NotificationCard
            content={translations.noTimeline}
            shouldFade={!isInitialLoad && isArrayEmpty(leaveRequestsTableData)}
          />
          <CustomFormDrawer
            customFormClass={classes.form}
            translations={translations.manageLeaveRequest}
            isOpened={isManageRequestOpened}
            initialData={manageRequestInitialData}
            isInitialValid={!!leaveRequest}
            fields={manageRequestFields}
            absenceTypes={availableUserAbsenceTypes}
            onDelete={handleOpenDeleteRequest}
            onClose={handleCloseManageRequest}
            onSave={onSaveRequest}
            hasCancelButton
          />
          <CustomFormDrawer
            translations={translations.updateAbsenceTypeBalance}
            titleText={`${absenceType?.name} ${translations.updateAbsenceTypeBalance.balance}`}
            isOpened={isEditBalanceOpened}
            initialData={absenceType || {}}
            isInitialValid={!!absenceType}
            fields={rollOverBalanceField}
            onClose={handleCloseEditBalance}
            onSave={onSaveBalance}
            hideDelete
            hasCancelButton
          />
          <AlertDialog
            translations={translations.deleteRequestDialog}
            isOpened={isDeleteRequestOpened}
            onClose={handleCloseDeleteRequest}
            onConfirm={onDeleteRequest}
            isWarning
          />
          <AlertDialog
            translations={translations.approveRequestDialog}
            isOpened={isApproveRequestOpened}
            onClose={handleCloseApproveRequest}
            onConfirm={onApproveRequest}
            isWarning
          />
          <AlertDialog
            translations={translations.rejectRequestDialog}
            isOpened={isRejectRequestOpened}
            onClose={handleCloseRejectRequest}
            onConfirm={onRejectRequest}
            isWarning
          />
        </div>
      )}
    </PeoplePageContainer>
  );
};

PeopleAbsencesPage.propTypes = {
  auth: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default memo(PeopleAbsencesPage);
