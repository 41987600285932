import PropTypes from 'prop-types';
import classNames from 'classnames';
import { TransitionGroup } from 'react-transition-group';
import {
  Fade,
  FormControl,
  FormLabel,
  FormHelperText,
  makeStyles,
} from '@material-ui/core';
import CustomDatePicker from '../customDatePicker';
import CustomButton from '../customButton';
import Tooltip from '../tooltip';
import ActionButton from '../actionButton';
import { formatDate } from '../customDateRange/config';
import { SINGLE_DAY_SAVE_FORMAT } from '../../../constants/date';
import { ACTION_BUTTON_TYPES } from '../actionButton/config';

const useStyles = makeStyles(({ spacing }) => ({
  label: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 14,
    lineHeight: '18px',
    marginBottom: spacing(2),
  },
  highlightedLabel: {
    fontFamily: 'ProximaNova-Bold',
  },
  tooltipIcon: {
    marginLeft: spacing(1),
    width: 14,
    height: 14,
  },
  content: {
    position: 'relative',
  },
  dates: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: spacing(6),
  },
  date: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: spacing(2),
    '&:last-of-type': {
      marginBottom: 0,
    },
  },
  dateFieldWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  dateField: {
    width: '100%',
    maxWidth: 'calc(100% - 30px)',
  },
  closeIconButton: {
    height: 24,
    width: 24,
  },
  closeIcon: {
    height: 16,
    width: 16,
  },
  addButton: {
    display: 'flex',
  },
  error: {
    position: 'absolute',
    bottom: 44,
  },
}));

const MultipleDatePicker = ({
  labelClass,
  label,
  placeholder,
  tooltipInfo,
  addDateButtonLabel,
  value,
  isHighlightedLabel,
  isFullWidth,
  errors,
  errorMessages,
  onChange,
  ...rest
}) => {
  const classes = useStyles();

  const error = errors?.find(currentError => currentError !== null);
  const hasError = !!error;
  const errorMessage = errorMessages?.[error];

  const handleChange = index => selectedDate =>
    onChange([
      ...value.slice(0, index),
      formatDate(selectedDate, SINGLE_DAY_SAVE_FORMAT),
      ...value.slice(index + 1, value.length),
    ]);

  const handleAdd = () => onChange([...value, null]);

  const handleRemove = index => () =>
    onChange([
      ...value.slice(0, index),
      ...value.slice(index + 1, value.length),
    ]);

  return (
    <FormControl fullWidth={isFullWidth}>
      {label && (
        <FormLabel
          classes={{
            root: classNames(
              classes.label,
              {
                [classes.highlightedLabel]: isHighlightedLabel,
              },
              labelClass
            ),
          }}
        >
          <span>{label}</span>
          {tooltipInfo && (
            <Tooltip customIconClass={classes.tooltipIcon} text={tooltipInfo} />
          )}
        </FormLabel>
      )}
      <div className={classes.content}>
        <TransitionGroup className={classes.dates}>
          {value.map((date, index) => (
            <Fade
              key={`date_field_${index}`}
              className={classes.date}
              in
              unmountOnExit
            >
              <div className={classes.dateFieldWrapper}>
                <div className={classes.dateField}>
                  <CustomDatePicker
                    placeholder={placeholder}
                    value={date}
                    onChange={handleChange(index)}
                    fullWidth={isFullWidth}
                    error={!!errors?.[index]}
                    {...rest}
                  />
                </div>
                {index !== 0 && (
                  <ActionButton
                    className={classes.closeIconButton}
                    iconClass={classes.closeIcon}
                    type={ACTION_BUTTON_TYPES.CLOSE}
                    isDisabled={index === 0}
                    onClickHandler={handleRemove(index)}
                  />
                )}
              </div>
            </Fade>
          ))}
        </TransitionGroup>
        <div className={classes.addButton}>
          <CustomButton type="addDarkRoundedOutlined" onClick={handleAdd}>
            {addDateButtonLabel}
          </CustomButton>
        </div>
        {hasError && errorMessage && (
          <FormHelperText className={classes.error} error={hasError}>
            {errorMessage}
          </FormHelperText>
        )}
      </div>
    </FormControl>
  );
};

MultipleDatePicker.defaultProps = {
  labelClass: undefined,
  label: '',
  placeholder: '',
  tooltipInfo: '',
  value: [],
  isHighlightedLabel: false,
  isFullWidth: false,
  errors: [],
  errorMessages: {},
};

MultipleDatePicker.propTypes = {
  labelClass: PropTypes.string,
  label: PropTypes.string,
  tooltipInfo: PropTypes.string,
  placeholder: PropTypes.string,
  addDateButtonLabel: PropTypes.string.isRequired,
  value: PropTypes.arrayOf(PropTypes.string),
  isHighlightedLabel: PropTypes.bool,
  isFullWidth: PropTypes.bool,
  errors: PropTypes.arrayOf(PropTypes.string),
  errorMessages: PropTypes.object,
  onChange: PropTypes.func.isRequired,
};

export default MultipleDatePicker;
