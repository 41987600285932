import { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Collapse, makeStyles, alpha } from '@material-ui/core';
import TextBoxWithTooltip from '../../textBoxWithTooltip';
import UserCount from '../../userCount';
import DotsMenu from '../../dotsMenu';
import AbsenceTypes from './absenceTypes';
import ActionButton from '../../actionButton';
import PlaceholderButton from '../../placeholderButton';
import Holidays from './holidays';
import { isArrayEmpty } from '../../../../utility/helpers';
import { ABSENCE_TYPES } from '../../../../constants/absence';
import { ACTION_BUTTON_TYPES } from '../../actionButton/config';
import { getMenuItems } from './config';
import { getUserStatusLabel } from '../../../../utility/uiUtils';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  root: {
    backgroundColor: primary.bluish8,
    borderRadius: 8,
    borderLeft: `3px solid ${primary.blue1}`,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: '100%',
    position: 'relative',
    transition: 'box-shadow .3s ease',
  },
  rootNotExpanded: {
    '&:hover': {
      boxShadow: `0px 2px 8px 0px ${alpha(primary.black, 0.17)}`,
    },
  },
  header: {
    boxSizing: 'border-box',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: spacing(4, 8, 4, 8),
    width: '100%',
    maxWidth: '100%',
  },
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: 'calc(100% - 62px)',
  },
  count: {
    flexShrink: 0,
    marginLeft: spacing(2),
  },
  countBadge: {
    backgroundColor: primary.white,
  },
  actionsWrapper: {
    display: 'flex',
    alignItems: 'center',
    flexShrink: 0,
    position: 'absolute',
    top: 18,
    right: 24,
  },
  content: {
    margin: spacing(4, 8, 8, 8),
    minHeight: 210,
    width: '100%',
    maxWidth: 'calc(100% - 64px)',
  },
  holidays: {
    marginBottom: spacing(6),
  },
  absenceTypes: {
    marginBottom: spacing(6),
  },
  addButton: {
    backgroundColor: primary.white,
    border: 'none',
    borderRadius: 16,
    width: 370,
    minWidth: 370,
    minHeight: 210,
    transition: 'box-shadow .3s ease',
    '&:hover': {
      border: 'none',
      boxShadow: `0px 2px 8px 0px ${alpha(primary.black, 0.12)}`,
    },
  },
  holidaysWrapper: {
    borderRadius: 16,
    display: 'flex',
    alignItems: 'center',
    minHeight: 210,
    maxWidth: '100%',
    marginBottom: spacing(6),
  },
  arrowButton: {
    marginLeft: spacing(1.5),
    width: 24,
    height: 24,
  },
  expandIcon: {
    transform: 'rotate(0deg)',
    transition: 'transform .3s cubic-bezier(0.77, 0.2, 0.05, 1)',
  },
  expandIconOpened: {
    transform: 'rotate(180deg)',
  },
}));

const AbsenceSet = ({
  translations,
  set,
  onEditSet,
  onDuplicateSet,
  onDeleteSet,
  onGetSetUsers,
  onAssignSetUsers,
  onAddType,
  onEditType,
  onDeleteType,
  onGetTypeUsers,
  onAssignTypeUsers,
  onAddExtension,
  onEditExtension,
  onDeleteExtension,
  onGetExtensionUsers,
  onAssignExtensionUsers,
  onAddHoliday,
  onEditHoliday,
  onDeleteHoliday,
  onGoToProfilePage,
}) => {
  const { name, user_count, types, holidays } = set;
  const classes = useStyles();

  const [isExpanded, setIsExpanded] = useState(false);

  const hasAddTimeOff = types?.length < ABSENCE_TYPES.length;
  const setMenuItems = getMenuItems(
    translations.menuItemLabels,
    set,
    onEditSet,
    onDeleteSet,
    onDuplicateSet,
    true
  );

  const onToggleExpand = () => setIsExpanded(prevExpanded => !prevExpanded);

  const handleRootClick = e => {
    e.stopPropagation();
  };

  return (
    <div
      className={classNames(classes.root, {
        [classes.rootNotExpanded]: !isExpanded,
      })}
      onClick={handleRootClick}
    >
      <div className={classes.header} onClick={onToggleExpand}>
        <div className={classes.titleWrapper}>
          <TextBoxWithTooltip variant="h4" text={name} />
          <UserCount
            className={classes.count}
            customUserCountBadgeClassName={classes.countBadge}
            addButtonLabel={translations.assign}
            userCount={user_count}
            shouldDisablePortal={false}
            getUsers={onGetSetUsers}
            onAdd={onAssignSetUsers}
            goToUserProfile={onGoToProfilePage}
            shouldHandleTypeLabel
            handleTypeLabel={employee =>
              getUserStatusLabel(translations.deactivated, employee)
            }
            hasAddButton
          />
        </div>
        <div className={classes.actionsWrapper}>
          <DotsMenu menuItems={setMenuItems} isVerticalMenu />
          <ActionButton
            className={classes.arrowButton}
            type={ACTION_BUTTON_TYPES.ARROW}
            iconClass={classNames(classes.expandIcon, {
              [classes.expandIconOpened]: isExpanded,
            })}
          />
        </div>
      </div>
      <Collapse in={isExpanded}>
        <div className={classes.content}>
          <Holidays
            className={classes.holidays}
            translations={translations}
            holidays={holidays}
            onAdd={onAddHoliday}
            onEdit={onEditHoliday}
            onDelete={onDeleteHoliday}
          />
          <AbsenceTypes
            className={classNames({
              [classes.absenceTypes]: hasAddTimeOff && !isArrayEmpty(types),
            })}
            translations={translations}
            types={types}
            hasSetUsers={user_count !== 0}
            onEditType={onEditType}
            onDeleteType={onDeleteType}
            onGetTypeUsers={onGetTypeUsers}
            onAssignTypeUsers={onAssignTypeUsers}
            onAddExtension={onAddExtension}
            onEditExtension={onEditExtension}
            onDeleteExtension={onDeleteExtension}
            onGetExtensionUsers={onGetExtensionUsers}
            onAssignExtensionUsers={onAssignExtensionUsers}
            onGoToProfilePage={onGoToProfilePage}
          />
          {hasAddTimeOff && (
            <PlaceholderButton
              className={classes.addButton}
              label={translations.addTimeOfTypeLabel}
              onAdd={onAddType}
            />
          )}
        </div>
      </Collapse>
    </div>
  );
};

AbsenceSet.propTypes = {
  translations: PropTypes.object.isRequired,
  set: PropTypes.object.isRequired,
  onEditSet: PropTypes.func.isRequired,
  onDuplicateSet: PropTypes.func.isRequired,
  onDeleteSet: PropTypes.func.isRequired,
  onGetSetUsers: PropTypes.func.isRequired,
  onAssignSetUsers: PropTypes.func.isRequired,
  onAddType: PropTypes.func.isRequired,
  onEditType: PropTypes.func.isRequired,
  onDeleteType: PropTypes.func.isRequired,
  onGetTypeUsers: PropTypes.func.isRequired,
  onAssignTypeUsers: PropTypes.func.isRequired,
  onAddExtension: PropTypes.func.isRequired,
  onEditExtension: PropTypes.func.isRequired,
  onDeleteExtension: PropTypes.func.isRequired,
  onGetExtensionUsers: PropTypes.func.isRequired,
  onAssignExtensionUsers: PropTypes.func.isRequired,
  onAddHoliday: PropTypes.func.isRequired,
  onEditHoliday: PropTypes.func.isRequired,
  onDeleteHoliday: PropTypes.func.isRequired,
  onGoToProfilePage: PropTypes.func.isRequired,
};

export default AbsenceSet;
