import PropTypes from 'prop-types';
import { Typography, makeStyles, alpha } from '@material-ui/core';
import UserCount from '../../../../../../userCount';
import UserCountBadge from '../../../../../../userCountBadge';
import DotsMenu from '../../../../../../dotsMenu';
import TextBoxWithTooltip from '../../../../../../textBoxWithTooltip';
import { getMenuItems } from '../../../../config';
import { getUserStatusLabel } from '../../../../../../../../utility/uiUtils';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  root: {
    boxSizing: 'border-box',
    backgroundColor: primary.bluish9,
    border: `1px dashed ${primary.bluish5}`,
    borderRadius: 16,
    display: 'flex',
    flexDirection: 'column',
    padding: spacing(4),
    width: 300,
    minWidth: 300,
    height: 194,
    transition: 'box-shadow .3s ease',
    '&:hover': {
      boxShadow: `0px 2px 8px 0px ${alpha(primary.black, 0.12)}`,
    },
  },
  info: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  actionsWrapper: {
    display: 'flex',
    alignItems: 'center',
    flexShrink: 0,
  },
  actionsMenu: {
    flexShrink: 0,
    marginLeft: spacing(1.5),
  },
  description: {
    height: 14,
    marginTop: spacing(1),
    marginBottom: spacing(7),
  },
  daysCountLabel: {
    color: primary.bluish4,
  },
  userCount: {
    flexShrink: 0,
    backgroundColor: 'unset',
    cursor: 'default',
  },
}));

const Extension = ({
  translations,
  extension,
  hasTypeUsers,
  onEdit,
  onDelete,
  onGetUsers,
  onAssignUsers,
  onGoToProfilePage,
}) => {
  const classes = useStyles();

  const { name, description, user_count, number_of_days } = extension;

  const extensionMenuItems = getMenuItems(
    translations.menuItemLabels,
    extension,
    onEdit,
    onDelete
  );

  return (
    <div className={classes.root}>
      <div className={classes.info}>
        <TextBoxWithTooltip variant="subtitle1" text={name} />
        <div className={classes.actionsWrapper}>
          {hasTypeUsers ? (
            <UserCount
              addButtonLabel={translations.assign}
              userCount={user_count}
              shouldDisablePortal={false}
              getUsers={onGetUsers}
              onAdd={onAssignUsers}
              goToUserProfile={onGoToProfilePage}
              shouldHandleTypeLabel
              handleTypeLabel={employee =>
                getUserStatusLabel(translations.deactivated, employee)
              }
              hasAddButton
            />
          ) : (
            <UserCountBadge
              className={classes.userCount}
              userCount={user_count}
            />
          )}
          <div className={classes.actionsMenu}>
            <DotsMenu menuItems={extensionMenuItems} isVerticalMenu />
          </div>
        </div>
      </div>
      <TextBoxWithTooltip
        className={classes.description}
        variant="caption"
        text={description}
      />
      <div>
        <Typography className={classes.daysCountLabel} variant="caption">
          {translations.daysCountLabel}
        </Typography>
        <Typography variant="h3">
          {`+ ${number_of_days} ${
            number_of_days === 1 ? translations.day : translations.days
          }`}
        </Typography>
      </div>
    </div>
  );
};

Extension.propTypes = {
  translations: PropTypes.object.isRequired,
  extension: PropTypes.object.isRequired,
  hasTypeUsers: PropTypes.bool.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onGetUsers: PropTypes.func.isRequired,
  onAssignUsers: PropTypes.func.isRequired,
  onGoToProfilePage: PropTypes.func.isRequired,
};

export default Extension;
