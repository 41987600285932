import { useState, useCallback, useEffect, useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Typography, makeStyles } from '@material-ui/core';
import PageContainer from '../../shared/pageContainer';
import Search from '../../shared/search';
import CustomButton from '../../shared/customButton';
import Filters from '../../shared/filters';
import NotificationCard from '../../shared/notificationCard';
import GridTable from '../../shared/gridTable';
import StatusIndicator from '../../shared/statusIndicator';
import Tooltip from '../../shared/tooltip';
import UserAvatar from '../../shared/userAvatar';
import ActionButton from '../../shared/actionButton';
import AlertDialog from '../../shared/alertDialog';
import DotsMenu from '../../shared/dotsMenu';
import CustomFormDrawer from '../../shared/customFormDrawer';
import SelectField from '../../shared/selectField';
import { leaveRequestsSelector } from '../../../store/selectors/leaveRequestsSelector';
import { personAbsencesSelector } from '../../../store/selectors/personAbsencesSelector';
import {
  getAllLeaveRequests,
  clearAllLeaveRequests,
} from '../../../store/modules/leaveRequests';
import {
  getPersonAbsenceBalance,
  clearPersonAbsences,
} from '../../../store/modules/personAbsences';
import { useTranslations } from '../../../utility/useTranslations';
import http from '../../../utility/http';
import {
  getYearOptions,
  getTranslatedAbsenceRequestStatuses,
  getTranslatedAbsenceTypes,
  getLeaveRequestsPageFilters,
  getLeaveRequestMenuItems,
  getUserAbsenceTypes,
  getManageLeaveRequestInitialData,
  getManageLeaveRequestFields,
  getUserAvailableAbsenceTypes,
} from '../../../utility/absence';
import {
  isArray,
  isArrayEmpty,
  isObjectEmpty,
  trimString,
  getItemById,
  checkUserRole,
  isUserDeactivated,
} from '../../../utility/helpers';
import { useAvailableUsers } from '../../../utility/hooks';
import {
  parseQueryParams,
  parseDuplicateParameters,
  hasNextPage,
  showSuccessMessage,
} from '../../../utility/uiUtils';
import { hasSelectedFilters } from '../../shared/filters/config';
import {
  APP_PAGES,
  PARAMS,
  LEAVE_REQUESTS_DEFAULT_PARAMS,
  PAGE_WHITELISTED_PARAMS,
} from '../../../constants/pages';
import { ABSENCE_REQUEST_STATUS_TYPES } from '../../../constants/statuses';
import { ROLES } from '../../../constants/rolesAndPermissionList';
import { INITIAL_LEAVE_REQUESTS_SORT } from '../../../constants/absence';
import { ACTION_BUTTON_TYPES } from '../../shared/actionButton/config';
import {
  API_ABSENCE_REQUESTS,
  api_absence_request_status,
  api_absence_request,
} from '../../../constants/apiRoutes';
import { sticky } from '../../../constants/helperCssRules';
import {
  getLeaveRequestsPageTableActionsHeader,
  getLeaveRequestsPageTableHeaders,
  getLeaveRequestsTableData,
  getNoResultsLabels,
} from './config';

const useStyles = makeStyles(
  ({ breakpoints, palette: { primary }, spacing }) => ({
    stickyHeader: {
      padding: spacing(8, 0, 6, 0),
      ...sticky(primary.white, 105),
    },
    description: {
      marginBottom: spacing(6),
    },
    actions: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
    },
    filters: {
      display: 'flex',
      alignItems: 'center',
      marginRight: spacing(2),
    },
    searchWrapper: {
      display: 'flex',
      alignItems: 'center',
    },
    search: {
      width: 300,
      marginRight: spacing(2),
    },
    addLeaveButton: {
      width: 'max-content',
    },
    table: {
      '& .grid-table-header': {
        ...sticky(primary.bluish8, 243, 998),
      },
      '& .actions-cell': {
        overflow: 'visible',
        paddingLeft: 0,
        paddingTop: spacing(2),
        paddingBottom: spacing(2),
        paddingRight: spacing(1),

        [breakpoints.up('xLg')]: {
          paddingRight: spacing(3),
        },
      },
    },
    userLabel: {
      fontFamily: 'ProximaNova-Bold',
      fontSize: 16,
      lineHeight: '20px',
    },
    icon: {
      cursor: 'default',
    },
    actionsWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
    year: {
      minWidth: 100,
      width: 100,
      marginRight: spacing(2),
    },
    yearMenu: {
      zIndex: 1000,
    },
    statusActions: {
      display: 'grid',
      gridTemplateColumns: 'repeat(2, 32px)',
      gridColumnGap: 8,
    },
    dotsMenu: {
      marginLeft: spacing(2),
    },
    form: {
      display: 'grid',
      gridColumnGap: 12,
      gridTemplateColumns: 'repeat(2, minmax(0, 1fr)) 85px',
      gridTemplateAreas: `
        "user user user"  
        "type type type"  
        "from to amount"
        "description description description"
      `,
    },
    userField: {
      gridArea: 'user',
    },
    typeField: {
      gridArea: 'type',
    },
    fromField: {
      gridArea: 'from',
    },
    toField: {
      gridArea: 'to',
    },
    amountField: {
      gridArea: 'amount',
    },
    descriptionField: {
      gridArea: 'description',
    },
  })
);

const LeaveRequstsPage = ({ location, auth, navigate, dispatch, ...rest }) => {
  const classes = useStyles();
  const translations = useTranslations(APP_PAGES.LEAVE_REQUESTS_PAGE);
  const { page: defaultPage } = LEAVE_REQUESTS_DEFAULT_PARAMS;
  const { PAGE, ORDERING, STATUS, TYPE, SEARCH, YEAR } = PARAMS;

  const leaveRequests = useSelector(leaveRequestsSelector);
  const { balance } = useSelector(personAbsencesSelector);

  const yearOptions = getYearOptions();

  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [ordering, setOrdering] = useState(INITIAL_LEAVE_REQUESTS_SORT);
  const [selectedFilters, setSelectedFilters] = useState({
    [STATUS]: [],
    [TYPE]: [],
  });
  const [leaveRequest, setLeaveRequest] = useState(null);
  const [isManageRequestOpened, setIsManageRequestOpened] = useState(false);
  const [isDeleteRequestOpened, setIsDeleteRequestOpened] = useState(false);
  const [isApproveRequestOpened, setIsApproveRequestOpened] = useState(false);
  const [isRejectRequestOpened, setIsRejectRequestOpened] = useState(false);
  const [currentYear, setCurrentYear] = useState(yearOptions[1].value);

  const isAdmin = checkUserRole(auth.role, ROLES.ADMIN);
  const absenceTypes = getTranslatedAbsenceTypes(translations.absenceTypes);
  const userAbsenceTypes = getUserAbsenceTypes(absenceTypes, balance);
  const availableUserAbsenceTypes =
    getUserAvailableAbsenceTypes(userAbsenceTypes);
  const statuses = getTranslatedAbsenceRequestStatuses(translations.statuses);
  const filters = getLeaveRequestsPageFilters(
    translations,
    absenceTypes,
    statuses
  );
  const gridHeaderActions = getLeaveRequestsPageTableActionsHeader(
    translations.columns
  );
  const gridHeaders = getLeaveRequestsPageTableHeaders(translations.columns);
  const isActiveFilter = hasSelectedFilters(selectedFilters);
  const hasLoadMore = hasNextPage(leaveRequests);
  const noResults = getNoResultsLabels(
    translations.noResults,
    isLoading,
    search || isActiveFilter
  );
  const usersParams = useMemo(
    () => ({ [PARAMS.HAS_ABSENCE_TYPE]: currentYear }),
    [currentYear]
  );
  const allUsers = useAvailableUsers(usersParams);
  const manageRequestInitialData = getManageLeaveRequestInitialData(
    leaveRequest,
    leaveRequest?.user?.id
  );

  const handleGetUserAbsenceTypes = userId =>
    getPersonAbsenceBalance(dispatch, userId, currentYear);

  const manageRequestFields = getManageLeaveRequestFields(
    classes,
    currentYear,
    !!leaveRequest,
    true,
    handleGetUserAbsenceTypes
  );

  const handlePageChange = ({
    newPage,
    newOrdering,
    newFilters,
    newSearch,
    newYear,
    isLoadMore,
  } = {}) => {
    const params = {
      [PAGE]: newPage,
      [ORDERING]: newOrdering?.sortKey,
      ...newFilters,
      ...(newSearch ? { [SEARCH]: newSearch } : {}),
      [YEAR]: newYear,
    };

    const query = parseDuplicateParameters(params);

    if (location.search !== `?${query}`) {
      navigate(`/leave-requests/?${query}`, { replace: true });
    }

    return getAllLeaveRequests(dispatch, isLoadMore, params);
  };

  const getInitialData = async () => {
    try {
      const params = parseQueryParams(
        location.search,
        PAGE_WHITELISTED_PARAMS.LEAVE_REQUESTS
      );

      if (!isObjectEmpty(params)) {
        const [ascending, descending] = params[ORDERING].split('-');
        const isAscending = !!ascending;
        const initialOrdering = {
          column: getItemById(
            gridHeaders,
            isAscending ? ascending : descending,
            'sortAs'
          )?.rowKey,
          asc: isAscending,
          sortKey: params[ORDERING],
        };
        const initialSearch = params[SEARCH]?.toString() || '';
        const initialFilters = {
          [STATUS]: params[STATUS] ? [params[STATUS]] : [],
          [TYPE]: params[TYPE] ? [params[TYPE]] : [],
        };
        const initialYear = params[YEAR] || currentYear;

        setOrdering(initialOrdering);
        setSearch(initialSearch);
        setSelectedFilters(initialFilters);
        setCurrentYear(initialYear);
        await Promise.all([
          handlePageChange({
            newPage: page,
            newOrdering: initialOrdering,
            newFilters: initialFilters,
            newSearch: initialSearch,
            newYear: initialYear,
          }),
        ]);
      } else {
        await Promise.all([
          handlePageChange({
            newPage: page,
            newOrdering: ordering,
            newFilters: selectedFilters,
            newSearch: search,
            newYear: currentYear,
          }),
        ]);
      }
    } finally {
      setIsInitialLoad(false);
    }
  };

  const cleanup = useCallback(() => {
    dispatch(clearAllLeaveRequests());
    dispatch(clearPersonAbsences());
  }, [dispatch]);

  const onChangeYear = selectedYear => {
    setPage(defaultPage);
    setCurrentYear(selectedYear);
    handlePageChange({
      newPage: defaultPage,
      newSearch: search,
      newOrdering: ordering,
      newFilters: selectedFilters,
      newYear: selectedYear,
    });
  };

  const onSearch = async text => {
    const searchTerm = trimString(text);

    try {
      setPage(defaultPage);
      setSearch(searchTerm);
      setIsLoading(true);
      await handlePageChange({
        newPage: defaultPage,
        newSearch: searchTerm,
        newOrdering: ordering,
        newFilters: selectedFilters,
        newYear: currentYear,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const onSort = newOrdering => {
    setPage(defaultPage);
    setOrdering(newOrdering);
    handlePageChange({
      newPage: defaultPage,
      newSearch: search,
      newOrdering,
      newFilters: selectedFilters,
      newYear: currentYear,
    });
  };

  const onFiltersChange = async newFilters => {
    try {
      setIsLoading(true);
      setPage(defaultPage);
      setSelectedFilters(newFilters);
      await handlePageChange({
        newPage: defaultPage,
        newSearch: search,
        newOrdering: ordering,
        newFilters,
        newYear: currentYear,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const onLoadMore = () => {
    setPage(page + 1);
    handlePageChange({
      newPage: page + 1,
      newSearch: search,
      newOrdering: ordering,
      newFilters: selectedFilters,
      newYear: currentYear,
      isLoadMore: true,
    });
  };

  const handleOpenManageRequest = () => setIsManageRequestOpened(true);

  const handleOpenEditRequest = async currentRequest => {
    await getPersonAbsenceBalance(
      dispatch,
      currentRequest?.user?.id,
      currentYear
    );
    setIsManageRequestOpened(true);
    setLeaveRequest(currentRequest);
  };

  const handleCloseManageRequest = () => {
    setIsManageRequestOpened(false);
    setLeaveRequest(null);
    dispatch(clearPersonAbsences());
  };

  const onSaveRequest = async ({
    absence_type,
    description,
    absence_from,
    absence_to,
    user,
  }) => {
    const data = {
      absence_type,
      description,
      absence_from,
      absence_to,
      user: user.id,
      year: currentYear,
    };

    if (leaveRequest) {
      await http.patch(api_absence_request(leaveRequest.id), data);
      showSuccessMessage(translations.manageLeaveRequest.successMessages.edit);
      setPage(defaultPage);
      return handlePageChange({
        newPage: defaultPage,
        newSearch: search,
        newOrdering: ordering,
        newFilters: selectedFilters,
        newYear: currentYear,
      });
    }

    await http.post(API_ABSENCE_REQUESTS, data);
    showSuccessMessage(translations.manageLeaveRequest.successMessages.add);
    setPage(defaultPage);
    return handlePageChange({
      newPage: defaultPage,
      newSearch: search,
      newOrdering: ordering,
      newFilters: selectedFilters,
      newYear: currentYear,
    });
  };

  const handleOpenDeleteRequest = currentRequest => {
    setIsDeleteRequestOpened(true);
    setLeaveRequest(currentRequest);
  };

  const handleCloseDeleteRequest = () => {
    setIsDeleteRequestOpened(false);
  };

  const onDeleteRequest = async () => {
    try {
      await http.delete(api_absence_request(leaveRequest.id));
      showSuccessMessage(translations.deleteRequestDialog.deleteSuccess);
      setPage(defaultPage);
      await handlePageChange({
        newPage: defaultPage,
        newSearch: search,
        newOrdering: ordering,
        newFilters: selectedFilters,
        newYear: currentYear,
      });
    } finally {
      setIsDeleteRequestOpened(false);
      setLeaveRequest(null);
      setIsManageRequestOpened(false);
    }
  };

  const handleOpenApproveRequest = currentRequest => () => {
    setIsApproveRequestOpened(true);
    setLeaveRequest(currentRequest);
  };

  const handleCloseApproveRequest = () => {
    setIsApproveRequestOpened(false);
    setLeaveRequest(null);
  };

  const onApproveRequest = async () => {
    try {
      await http.patch(api_absence_request_status(leaveRequest.id), {
        [PARAMS.STATUS]: ABSENCE_REQUEST_STATUS_TYPES.APPROVED,
      });
      showSuccessMessage(translations.approveRequestDialog.approveSuccess);
      setPage(defaultPage);
      await handlePageChange({
        newPage: defaultPage,
        newSearch: search,
        newOrdering: ordering,
        newFilters: selectedFilters,
        newYear: currentYear,
      });
    } finally {
      handleCloseApproveRequest();
    }
  };

  const handleOpenRejectRequest = currentRequest => () => {
    setIsRejectRequestOpened(true);
    setLeaveRequest(currentRequest);
  };

  const handleCloseRejectRequest = () => {
    setIsRejectRequestOpened(false);
    setLeaveRequest(null);
  };

  const onRejectRequest = async () => {
    try {
      await http.patch(api_absence_request_status(leaveRequest.id), {
        [PARAMS.STATUS]: ABSENCE_REQUEST_STATUS_TYPES.REJECTED,
      });
      showSuccessMessage(translations.rejectRequestDialog.rejectSuccess);
      setPage(defaultPage);
      await handlePageChange({
        newPage: defaultPage,
        newSearch: search,
        newOrdering: ordering,
        newFilters: selectedFilters,
        newYear: currentYear,
      });
    } finally {
      handleCloseRejectRequest();
    }
  };

  useEffect(() => {
    getInitialData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return cleanup;
  }, [cleanup]);

  const renderUser = user => (
    <UserAvatar
      labelClass={classes.userLabel}
      user={user}
      clickableCaption
      caption
      small
    />
  );

  const renderStatus = currentStatus => (
    <StatusIndicator status={currentStatus} />
  );

  const renderType = currentType => (
    <Tooltip
      customIconClass={classes.icon}
      text={currentType.name}
      icon={currentType.icon}
    />
  );

  const renderActions = (request, hasStatusActions, hasEdit) => {
    const menuItems = getLeaveRequestMenuItems(
      translations.menuItems,
      request,
      hasEdit,
      isAdmin,
      handleOpenEditRequest,
      handleOpenDeleteRequest
    );
    const isDeactivatedUser = isUserDeactivated(request?.user);
    return (
      <div className={classes.actionsWrapper}>
        {hasStatusActions && (
          <div className={classes.statusActions}>
            <ActionButton
              type={ACTION_BUTTON_TYPES.CLOSE}
              tooltipText={translations.menuItems.reject}
              onClickHandler={handleOpenRejectRequest(request)}
              isDisabled={isDeactivatedUser}
              disabledTooltipText={
                translations.rejectRequestAbsenceButtonDisabled
              }
              isSquared
            />
            <ActionButton
              type={ACTION_BUTTON_TYPES.ACCEPT}
              tooltipText={translations.menuItems.approve}
              onClickHandler={handleOpenApproveRequest(request)}
              isDisabled={isDeactivatedUser}
              disabledTooltipText={
                translations.approveRequestAbsenceButtonDisabled
              }
              isSquared
            />
          </div>
        )}
        {isAdmin && (
          <DotsMenu className={classes.dotsMenu} menuItems={menuItems} />
        )}
      </div>
    );
  };

  const leaveRequestsTableData = getLeaveRequestsTableData(
    translations,
    isAdmin,
    leaveRequests?.results,
    absenceTypes,
    statuses,
    renderUser,
    renderStatus,
    renderType,
    renderActions
  );

  return (
    <PageContainer
      {...rest}
      translations={translations}
      location={location}
      auth={auth}
      navigate={navigate}
      dispatch={dispatch}
      shouldPassProps={false}
      isFullWidthContent
    >
      {!isInitialLoad && (
        <div>
          <div className={classes.stickyHeader}>
            <div className={classes.description}>
              <Typography variant="body2">
                {translations.description}
              </Typography>
            </div>
            <div className={classes.actions}>
              <div className={classes.filters}>
                <Filters
                  translations={translations.filters}
                  selectedFilters={selectedFilters}
                  filters={filters}
                  onApplyFilters={onFiltersChange}
                />
              </div>
              <div className={classes.searchWrapper}>
                <Search
                  className={classes.search}
                  placeholder={translations.search}
                  value={search}
                  onChange={onSearch}
                />
                <SelectField
                  className={classes.year}
                  menuClass={classes.yearMenu}
                  value={currentYear}
                  options={yearOptions}
                  onChange={onChangeYear}
                  isSearchDisabled
                  shouldRemoveLabel
                />
                <CustomButton
                  className={classes.addLeaveButton}
                  type="addRoundedNew"
                  onClick={handleOpenManageRequest}
                >
                  {translations.addLeaveButtonLabel}
                </CustomButton>
              </div>
            </div>
          </div>
          {isArray(leaveRequests.results) &&
            !isArrayEmpty(leaveRequests.results) && (
              <GridTable
                className={classes.table}
                translations={translations}
                initialSort={ordering}
                headerActions={gridHeaderActions}
                headers={gridHeaders}
                hasLoadMore={hasLoadMore}
                rows={leaveRequestsTableData}
                onLoadMore={onLoadMore}
                onSort={onSort}
              />
            )}
          <NotificationCard
            title={noResults.title}
            content={noResults.content}
            shouldFade={
              !isLoading &&
              isArray(leaveRequests?.results) &&
              isArrayEmpty(leaveRequests.results)
            }
          />
          <CustomFormDrawer
            customFormClass={classes.form}
            translations={translations.manageLeaveRequest}
            isOpened={isManageRequestOpened}
            initialData={manageRequestInitialData}
            isInitialValid={!!leaveRequest}
            fields={manageRequestFields}
            absenceTypes={availableUserAbsenceTypes}
            allUsers={allUsers}
            onDelete={handleOpenDeleteRequest}
            onClose={handleCloseManageRequest}
            onSave={onSaveRequest}
            hasCancelButton
          />
          <AlertDialog
            translations={translations.deleteRequestDialog}
            isOpened={isDeleteRequestOpened}
            onClose={handleCloseDeleteRequest}
            onConfirm={onDeleteRequest}
            isWarning
          />
          <AlertDialog
            translations={translations.approveRequestDialog}
            isOpened={isApproveRequestOpened}
            onClose={handleCloseApproveRequest}
            onConfirm={onApproveRequest}
            isWarning
          />
          <AlertDialog
            translations={translations.rejectRequestDialog}
            isOpened={isRejectRequestOpened}
            onClose={handleCloseRejectRequest}
            onConfirm={onRejectRequest}
            isWarning
          />
        </div>
      )}
    </PageContainer>
  );
};

LeaveRequstsPage.propTypes = {
  location: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default memo(LeaveRequstsPage);
