import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Typography, withStyles } from '@material-ui/core';
import { sticky } from '../../../constants/helperCssRules';
import CustomButton from '../../shared/customButton';
import ManageActionPlan from '../../shared/manageActionPlan';
import ActionPlanCards from '../../shared/actionPlanCards';
import Filters from '../../shared/filters';
import NotificationCard from '../../shared/notificationCard';
import AlertDialog from '../../shared/alertDialog';
import ManageTasks from '../../shared/manageTasks';
import ConditionalTooltip from '../../shared/conditionalTooltip';
import http from '../../../utility/http';
import {
  parseDuplicateParameters,
  hasNextPage,
  parseQueryParams,
  showSuccessMessage,
} from '../../../utility/uiUtils';
import { onSaveCreatableTag } from '../../../utility/tagUtils';
import { validateFreemiumAction } from '../../../utility/subscriptionHelper';
import {
  isArray,
  isObjectEmpty,
  canManagePerson,
  isArrayEmpty,
  isUserProfileAccessible,
  isUserDeactivated,
  trimString,
  canSeeEmployeeProfile,
} from '../../../utility/helpers';
import {
  getActionPlanStatuses,
  getActionPlanFilterStatuses,
  getActionPlansPageFilters,
} from '../../../utility/actionPlans';
import { prepareTasksForSave } from '../../../utility/tasks';
import { hasSelectedFilters } from '../../shared/filters/config';
import { GENERAL_CATEGORY_ID } from '../../../constants/tags';
import { FREEMIUM_LIMIT_TYPES } from '../../../constants/appConfig';
import { api_action_plan } from '../../../constants/apiRoutes';
import {
  PARAMS,
  PEOPLE_ACTION_PLANS_DEFAULT_PARAMS,
  PAGE_WHITELISTED_PARAMS,
} from '../../../constants/pages';

const styles = ({ palette: { primary }, spacing }) => ({
  stickyHeader: {
    ...sticky(primary.white, 170),
  },
  pageDescription: {
    marginBottom: spacing(4),
  },
  filterContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingBottom: spacing(4),
    width: '100%',
  },
  statusFilter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  loadMoreWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: spacing(4),
  },
  loadMore: {
    border: `1px solid ${primary.bluish3}`,
    borderRadius: 44,
    color: primary.bluish4,
    lineHeight: '24px',
    justifySelf: 'center',
    padding: '4px 16px',
    maxHeight: 32,
  },
  addButtonWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: spacing(2),
  },
  addButton: {
    width: 'max-content',
  },
  disabledUserAction: {
    cursor: 'not-allowed',
  },
});

const { PAGE, DUE_DATE, TAGS, COMPLETION_STATUS } = PARAMS;

class PeopleActionPlansPage extends PureComponent {
  state = {
    isInitialLoad: true,
    [PAGE]: PEOPLE_ACTION_PLANS_DEFAULT_PARAMS[PAGE],
    isFilterLoading: false,
    actionPlan: {},
    isManagePlanOpened: false,
    isDeleteOpened: false,
    isDismissRequestOpened: false,
    isManageTasksOpened: false,
    filters: {
      [COMPLETION_STATUS]: [],
      [TAGS]: [],
      [DUE_DATE]: [],
    },
  };

  componentDidMount() {
    this.getInitialData();
  }

  componentWillUnmount() {
    const {
      clearAllActionPlans,
      clearTagsCategories,
      clearAllUsers,
      clearPageQuickFilters,
      clearCourses,
      clearCourseEnrolledUsers,
    } = this.props;
    clearTagsCategories();
    clearAllActionPlans();
    clearAllUsers();
    clearPageQuickFilters();
    clearCourses();
    clearCourseEnrolledUsers();
  }

  getInitialData = () => {
    const { location, getPageQuickFilters, getTagsCategories, getAllUsers } =
      this.props;
    const params = parseQueryParams(
      location.search,
      PAGE_WHITELISTED_PARAMS.PEOPLE_ACTION_PLANS
    );

    if (!isObjectEmpty(params)) {
      return this.setState(
        {
          filters: {
            [COMPLETION_STATUS]: params[COMPLETION_STATUS]
              ? [params[COMPLETION_STATUS]]
              : [],
            [TAGS]: params[TAGS]
              ? [...(isArray(params[TAGS]) ? params[TAGS] : [params[TAGS]])]
              : [],
            [DUE_DATE]: params[DUE_DATE] ? [params[DUE_DATE]] : [],
          },
        },
        () => {
          return Promise.all([
            this.handlePageChange(false),
            getTagsCategories(),
            getPageQuickFilters(),
            getAllUsers(),
          ]).then(() => {
            this.setState({ isInitialLoad: false });
          });
        }
      );
    }

    return Promise.all([
      this.handlePageChange(),
      getTagsCategories(),
      getPageQuickFilters(),
      getAllUsers(),
    ]).then(() => {
      this.setState({ isInitialLoad: false });
    });
  };

  handlePageChange = (isLoadMore = false) => {
    const { history, location, user, getUserActionPlans } = this.props;
    const { page, filters } = this.state;
    const params = {
      page,
      ...filters,
    };
    const query = parseDuplicateParameters(params);

    if (location.search !== `?${query}`) {
      history.replace(`/people/${user.id}/action-plans/?${query}`);
    }

    return getUserActionPlans(user.id, params, isLoadMore);
  };

  onLoadMore = () => {
    return this.setState(
      prevState => ({ ...prevState, [PAGE]: prevState[PAGE] + 1 }),
      () => this.handlePageChange(true)
    );
  };

  handleApplyFilters = filters =>
    this.setState(
      {
        [PAGE]: PEOPLE_ACTION_PLANS_DEFAULT_PARAMS[PAGE],
        filters,
        isFilterLoading: true,
      },
      () =>
        this.handlePageChange().then(() =>
          this.setState({ isFilterLoading: false })
        )
    );

  handleOpenManagePlan = () =>
    this.setState({
      isManagePlanOpened: true,
    });

  handleEditPlan = actionPlan =>
    this.setState({
      isManagePlanOpened: true,
      actionPlan,
    });

  handleCloseManagePlan = () => {
    this.setState({
      isManagePlanOpened: false,
      isDeleteOpened: false,
      actionPlan: {},
    });
  };

  onSaveActionPlan = () => {
    this.setState({ [PAGE]: PEOPLE_ACTION_PLANS_DEFAULT_PARAMS[PAGE] }, () =>
      this.handlePageChange()
    );
  };

  handleOpenManageTasks = actionPlan =>
    this.setState({ isManageTasksOpened: true, actionPlan });

  handleCloseManageTasks = () =>
    this.setState({ isManageTasksOpened: false, actionPlan: {} });

  onSaveTasks = async values => {
    const { translations, user } = this.props;
    const { actionPlan } = this.state;

    await http.patch(api_action_plan(actionPlan.id), {
      tasks: prepareTasksForSave(values.tasks),
      user: user.id,
    });
    showSuccessMessage(translations.manageTasksDialog.successMessage);

    this.setState({ [PAGE]: PEOPLE_ACTION_PLANS_DEFAULT_PARAMS[PAGE] }, () =>
      this.handlePageChange()
    );
  };

  handleActionPlanDelete = actionPlan =>
    this.setState({ isDeleteOpened: true, actionPlan });

  handleOpenActionPlanDelete = () => this.setState({ isDeleteOpened: true });

  handleCloseActionPlanDelete = () => this.setState({ isDeleteOpened: false });

  handleDismissProposal = actionPlan => {
    this.setState({ actionPlan, isDismissRequestOpened: true });
  };

  handleCloseDismissDialog = () => {
    this.setState({ actionPlan: {}, isDismissRequestOpened: false });
  };

  onActionPlanDelete = () => {
    const { translations } = this.props;
    const { actionPlan, isDismissRequestOpened } = this.state;
    const successMessage = isDismissRequestOpened
      ? translations.dismissActionPlanProposalDialog.deleteSuccess
      : translations.deleteActionPlanDialog.deleteSuccess;

    return http.delete(api_action_plan(actionPlan.id)).then(() => {
      showSuccessMessage(successMessage);
      this.setState(
        {
          [PAGE]: PEOPLE_ACTION_PLANS_DEFAULT_PARAMS[PAGE],
          isDeleteOpened: false,
          isManagePlanOpened: false,
          isDismissRequestOpened: false,
          actionPlan: {},
        },
        () => this.handlePageChange()
      );
    }, this.handleCloseManagePlan);
  };

  handleGoToPlanDetails = actionPlan => {
    const { history } = this.props;

    history.push(`/action-plans/${actionPlan.id}/details`);
  };

  onCreateTag = async tagName => {
    const { addMultipleTags } = this.props;
    const tags = await onSaveCreatableTag(tagName);
    const [tag] = tags.create;

    addMultipleTags({ categoryId: GENERAL_CATEGORY_ID, tags });

    return tag;
  };

  handleCoursesSearch = value => {
    const { getCourses } = this.props;
    const text = trimString(value);
    const params = {
      ...(text ? { search: text } : {}),
    };

    return getCourses(params);
  };

  canSeeUserProfileCheck = user => {
    const { auth, organizationSettings } = this.props;

    return canSeeEmployeeProfile(
      auth,
      user.id,
      organizationSettings.global_see_himself
    );
  };

  goToUserProfilePage = userId => this.props.navigate(`/people/${userId}`);

  render() {
    const {
      classes,
      translations,
      actionPlans,
      user,
      auth,
      courses,
      categories,
      pageQuickFilters,
      allUsers,
      organizationSettings,
      getCourses,
      getCourseEnrolledUsers,
      setDialogVisibility,
      getOrganizationSettings,
    } = this.props;
    const {
      isInitialLoad,
      isFilterLoading,
      filters,
      isManagePlanOpened,
      isDeleteOpened,
      isManageTasksOpened,
      isDismissRequestOpened,
      actionPlan,
    } = this.state;
    const canAddActionPlan = canManagePerson(auth, user?.id);
    const statuses = getActionPlanStatuses(translations.statuses);
    const statusFilters = getActionPlanFilterStatuses(translations.statuses);
    const showLoadMore = hasNextPage(actionPlans);
    const filterIsActive = hasSelectedFilters(filters);
    const isDeactivatedUser = isUserDeactivated(user);

    return (
      <div>
        {!isInitialLoad && (
          <div className={classes.stickyHeader}>
            <Typography variant="body2" className={classes.pageDescription}>
              {auth.id === user.id
                ? translations.descriptionOwnProfile
                : translations.pageDescription}
            </Typography>
            <div className={classes.filterContainer}>
              <Filters
                translations={translations.filters}
                selectedFilters={filters}
                filters={getActionPlansPageFilters(
                  translations,
                  statusFilters,
                  categories,
                  pageQuickFilters
                )}
                onApplyFilters={this.handleApplyFilters}
              />
              {canAddActionPlan && (
                <div className={classes.addButtonWrapper}>
                  <ConditionalTooltip
                    className={classes.disabledUserAction}
                    message={translations.disabledAddActionTooltip}
                    addTooltip={isDeactivatedUser}
                  >
                    <CustomButton
                      className={classes.addButton}
                      type="addRoundedNew"
                      aria-label="Add"
                      disabled={isDeactivatedUser}
                      onClick={validateFreemiumAction(
                        this.handleOpenManagePlan,
                        FREEMIUM_LIMIT_TYPES.PLANS,
                        organizationSettings,
                        setDialogVisibility,
                        getOrganizationSettings
                      )}
                    >
                      {translations.addActionPlan}
                    </CustomButton>
                  </ConditionalTooltip>
                </div>
              )}
            </div>
          </div>
        )}
        <ActionPlanCards
          translations={translations.actionPlans}
          actionPlans={actionPlans?.results || []}
          statuses={statuses}
          currentUser={auth}
          user={user}
          isDisabled={isDeactivatedUser}
          isUserProfileAccessible={isUserProfileAccessible(
            auth,
            organizationSettings.global_see_himself
          )}
          canSeeUserProfileCheck={this.canSeeUserProfileCheck}
          goToUserProfilePage={this.goToUserProfilePage}
          onCardClick={this.handleGoToPlanDetails}
          onEdit={this.handleEditPlan}
          onDelete={this.handleActionPlanDelete}
          onManageTasks={this.handleOpenManageTasks}
          onDismissProposal={this.handleDismissProposal}
        />
        <div className={classes.loadMoreWrapper}>
          {showLoadMore && (
            <CustomButton
              className={classes.loadMore}
              type="addWithTextRounded"
              onClick={this.onLoadMore}
            >
              {translations.loadMore}
            </CustomButton>
          )}
        </div>
        <NotificationCard
          title={filterIsActive ? '' : translations.noResultsTitle}
          content={
            filterIsActive
              ? translations.noSearchResultsMessage
              : translations.noResultsMessage
          }
          shouldFade={
            !isInitialLoad &&
            !isFilterLoading &&
            isArray(actionPlans.results) &&
            isArrayEmpty(actionPlans.results)
          }
        />
        <ManageActionPlan
          translations={translations.manageActionPlan}
          isOpen={isManagePlanOpened}
          isInitialValid={!isObjectEmpty(actionPlan)}
          currentUser={auth}
          statuses={statuses}
          user={user}
          actionPlan={actionPlan}
          categories={categories}
          allUsers={allUsers}
          courses={courses.results}
          getCourses={getCourses}
          getCourseEnrolledUsers={getCourseEnrolledUsers}
          onCoursesSearch={this.handleCoursesSearch}
          onCreateTag={this.onCreateTag}
          onDelete={this.handleOpenActionPlanDelete}
          onClose={this.handleCloseManagePlan}
          onConfirm={this.onSaveActionPlan}
        />
        {actionPlan && (
          <ManageTasks
            translations={translations.manageTasksDialog}
            isOpened={isManageTasksOpened}
            record={actionPlan}
            availableUsers={allUsers}
            onClose={this.handleCloseManageTasks}
            onSave={this.onSaveTasks}
          />
        )}
        <AlertDialog
          isOpened={isDeleteOpened}
          translations={translations.deleteActionPlanDialog}
          onClose={this.handleCloseActionPlanDelete}
          onConfirm={this.onActionPlanDelete}
          isWarning
        />
        <AlertDialog
          translations={translations.dismissActionPlanProposalDialog}
          isOpened={isDismissRequestOpened}
          onClose={this.handleCloseDismissDialog}
          onConfirm={this.onActionPlanDelete}
          isWarning
        />
      </div>
    );
  }
}

PeopleActionPlansPage.propTypes = {
  classes: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired,
  translations: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  actionPlans: PropTypes.object.isRequired,
  organizationSettings: PropTypes.object.isRequired,
  allUsers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  categories: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  pageQuickFilters: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  getUserActionPlans: PropTypes.func.isRequired,
  getTagsCategories: PropTypes.func.isRequired,
  getAllUsers: PropTypes.func.isRequired,
  setDialogVisibility: PropTypes.func.isRequired,
  addMultipleTags: PropTypes.func.isRequired,
  clearAllActionPlans: PropTypes.func.isRequired,
  clearTagsCategories: PropTypes.func.isRequired,
  clearAllUsers: PropTypes.func.isRequired,
  getPageQuickFilters: PropTypes.func.isRequired,
  clearPageQuickFilters: PropTypes.func.isRequired,
  clearCourses: PropTypes.func.isRequired,
  clearCourseEnrolledUsers: PropTypes.func.isRequired,
  getOrganizationSettings: PropTypes.func.isRequired,
};
export default withStyles(styles)(PeopleActionPlansPage);
