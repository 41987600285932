import { createTheme, alpha } from '@material-ui/core';

const SPACING = 4;

export const primaryPalette = {
  black: '#000000',
  blue1: '#346EF1',
  blue2: '#1E87F0',
  blue3: '#3F97EF',
  blue4: '#20AEF6',
  blue5: '#A2C9F1',
  blue6: '#E9F4FF',
  blue7: '#EBEFFD',
  blue8: '#F9FCFF',
  blue9: '#EAF9FC',
  blue10: '#FEF7FF',
  bluish1: '#303F47',
  bluish2: '#37474F',
  bluish3: '#53646C',
  bluish4: '#78909C',
  bluish5: '#AFBDC5',
  bluish6: '#C1CACE',
  bluish7: '#D6DADC',
  bluish8: '#F2F5F6',
  bluish9: '#F8FAFB',
  cyan: '#39D5D8',
  green1: '#97C05C',
  green2: '#AAD26F',
  green3: '#DCEDC8',
  green4: '#E8FFC6',
  green5: '#CCEB9E',
  green6: '#B0D877',
  green7: '#95C44F',
  green8: '#79B128',
  green9: '#5D9D00',
  green10: '#4A7E00',
  green11: '#406A04',
  green12: '#385E00',
  green13: '#304F04',
  main: '#346EF1',
  orange: '#F2994A',
  pink1: '#F06292',
  purple: '#9575CD',
  red1: '#E2231A',
  red2: '#EB5757',
  red3: '#E57373',
  red4: '#FFCDD2',
  red5: '#FFF0F1',
  red6: '#FCF4EA',
  red7: '#E9E9E9',
  white: '#FFFFFF',
  yellow1: '#FFD54F',
  yellow2: '#FDEB3B',
  yellow3: '#FFF9C4',
  yellow4: '#FCFBEA',
  yellow5: '#F4FCEA',
  gray2: '#4F4F4F',
  indigo: '#41395F',
  indigo2: '#110837',
};

const chartPalette = {
  white: '#FFFFFF',
  blue: '#1E87F0',
  gray: '#9E9E9E',
  textGray: '#BCBCBC',
  yellow: '#FFD829',
  scoreYellow: '#FFD900',
  orange: '#FF8A65',
};

const KadarTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 601,
      md: 960,
      lg: 1280,
      xLg: 1440,
      xl: 1600,
      xxl: 2560,
    },
  },
  palette: {
    primary: primaryPalette,
    text: {
      primary: primaryPalette.bluish1,
    },
    chart: chartPalette,
  },
  props: {
    MuiInput: {
      disableUnderline: true,
    },
    MuiTypography: {
      color: 'textPrimary',
      variantMapping: {
        h1: 'h1',
        h2: 'h2',
        h3: 'h3',
        h4: 'h4',
        h5: 'h5',
        body1: 'p',
        body2: 'p',
        caption: 'p',
        subtitle1: 'p', // body1 bold
        subtitle2: 'p', // body2 bold
        overline: 'p', // caption bold
      },
    },
  },
  typography: {
    fontFamily: 'ProximaNova-Regular',
    h1: {
      fontFamily: 'ProximaNova-Bold',
      fontSize: 32,
      lineHeight: '40px',
      fontWeight: 'normal',
      letterSpacing: 'normal',
      textTransform: 'none',
    },
    h2: {
      fontFamily: 'ProximaNova-Bold',
      fontSize: 28,
      lineHeight: '30px',
      fontWeight: 'normal',
      letterSpacing: 'normal',
      textTransform: 'none',
    },
    h3: {
      fontFamily: 'ProximaNova-Bold',
      fontSize: 24,
      lineHeight: '28px',
      fontWeight: 'normal',
      letterSpacing: 'normal',
      textTransform: 'none',
    },
    h4: {
      fontFamily: 'ProximaNova-Bold',
      fontSize: 20,
      lineHeight: '24px',
      fontWeight: 'normal',
      letterSpacing: 'normal',
      textTransform: 'none',
    },
    h5: {
      fontFamily: 'ProximaNova-Bold',
      fontSize: 18,
      lineHeight: '20px',
      fontWeight: 'normal',
      letterSpacing: 'normal',
      textTransform: 'none',
    },
    body1: {
      fontFamily: 'ProximaNova-Regular',
      fontSize: 16,
      lineHeight: '20px',
      fontWeight: 'normal',
      letterSpacing: 'normal',
      textTransform: 'none',
    },
    subtitle1: {
      fontFamily: 'ProximaNova-Bold',
      fontSize: 16,
      lineHeight: '20px',
      fontWeight: 'normal',
      letterSpacing: 'normal',
      textTransform: 'none',
    },
    body2: {
      fontFamily: 'ProximaNova-Regular',
      fontSize: 14,
      lineHeight: '18px',
      fontWeight: 'normal',
      letterSpacing: 'normal',
      textTransform: 'none',
    },
    subtitle2: {
      fontFamily: 'ProximaNova-Bold',
      fontSize: 14,
      lineHeight: '18px',
      fontWeight: 'normal',
      letterSpacing: 'normal',
      textTransform: 'none',
    },
    caption: {
      fontFamily: 'ProximaNova-Regular',
      fontSize: 12,
      lineHeight: '14px',
      fontWeight: 'normal',
      letterSpacing: 'normal',
      textTransform: 'none',
    },
    overline: {
      fontFamily: 'ProximaNova-Bold',
      fontSize: 12,
      lineHeight: '14px',
      fontWeight: 'normal',
      letterSpacing: 'normal',
      textTransform: 'none',
    },
  },
  spacing: SPACING,
  overrides: {
    MuiBackdrop: {
      root: {
        backgroundColor: alpha(primaryPalette.black, 0.8),
      },
    },
    MuiFormControlLabel: {
      root: {
        marginLeft: 0,
        marginRight: 0,
      },
    },
    MuiButton: {
      root: {
        fontFamily: 'ProximaNova-Semibold',
        borderRadius: '4px',
        color: primaryPalette.white,
        textTransform: 'uppercase',
        backgroundColor: primaryPalette.blue1,
        '&:hover': {
          backgroundColor: primaryPalette.blue1,
          '@media (hover: none)': {
            backgroundColor: primaryPalette.blue1,
          },
          '&$disabled': {
            backgroundColor: primaryPalette.bluish7,
          },
        },
        '&:disabled': {
          color: primaryPalette.white,
          backgroundColor: primaryPalette.bluish7,
        },
      },
      sizeSmall: {
        padding: '4px 16px',
        fontSize: '12px',
        lineHeight: '14px',
        minHeight: '27px',
      },
      sizeLarge: {
        padding: '14px 32px',
        fontSize: '14px',
        lineHeight: '21px',
        minHeight: '53px',
      },
      textPrimary: {
        color: primaryPalette.white,
        backgroundColor: primaryPalette.blue1,
        '&:hover': {
          backgroundColor: primaryPalette.blue1,
        },
      },
      textSecondary: {
        color: primaryPalette.white,
        backgroundColor: primaryPalette.bluish4,
        '&:hover': {
          backgroundColor: primaryPalette.bluish4,
        },
      },
      outlined: {
        minHeight: '23px',
        backgroundColor: primaryPalette.white,
        border: `2px solid ${primaryPalette.blue1}`,
        color: primaryPalette.blue1,
        '&:hover': {
          backgroundColor: primaryPalette.white,
        },
      },
    },
    MuiFab: {
      root: {
        height: 40,
        width: 40,
        backgroundColor: primaryPalette.blue2,
        '&:hover': {
          backgroundColor: primaryPalette.blue2,
        },
        '&:active': {
          boxShadow: 'none',
        },
      },
    },
    MuiIconButton: {
      root: {
        height: 30,
        width: 30,
        padding: 0,
      },
    },
    MuiAvatar: {
      root: {
        width: 32,
        height: 32,
        fontSize: 14,
        fontFamily: 'ProximaNova-Regular',
        fontWeight: 600,
      },
      colorDefault: {
        backgroundColor: primaryPalette.pink1,
      },
    },
    MuiInput: {
      root: {
        boxSizing: 'border-box',
        backgroundColor: primaryPalette.white,
        fontFamily: 'ProximaNova-Regular',
        color: primaryPalette.bluish1,
        fontSize: 16,
        lineHeight: '22px',
        border: `1px solid ${primaryPalette.bluish6}`,
        transition: 'border-color 400ms cubic-bezier(0.4, 0, 0.2, 1)',
        borderRadius: 4,
        paddingLeft: 11,
        paddingRight: 11,
        minHeight: 40,
        '&$focused': {
          transition: 'border-color 400ms cubic-bezier(0.4, 0, 0.2, 1)',
          border: `1px solid ${primaryPalette.blue1}`,
        },
        '&$disabled': {
          color: primaryPalette.bluish5,
          backgroundColor: primaryPalette.bluish9,
          border: `1px solid ${primaryPalette.bluish6}`,
        },
        '&$error': {
          border: `1px solid ${primaryPalette.red1}`,
        },
        '&$multiline': {
          paddingTop: 0,
          paddingBottom: 0,
        },
      },
      input: {
        padding: '8px 0',
        height: 'auto',
        fontSize: 16,
        lineHeight: '22px',
        '&$disabled': {
          color: primaryPalette.bluish5,
          backgroundColor: primaryPalette.bluish9,
          '&::placeholder ': {
            color: primaryPalette.bluish5,
          },
        },
        '&::placeholder ': {
          color: primaryPalette.bluish4,
          fontFamily: 'ProximaNova-Regular',
          fontSize: 16,
          lineHeight: '22px',
          opacity: 1,
        },
      },
    },
    MuiFormLabel: {
      root: {
        marginBottom: 8,
        color: primaryPalette.bluish1,
        fontFamily: 'ProximaNova-Regular',
        fontSize: 14,
        letterSpacing: 'normal',
        lineHeight: '18px',
        textTransform: 'none',
        minHeight: '0px',
        marginTop: '0px',
        '&$focused': {
          color: primaryPalette.bluish1,
        },
      },
      focused: {},
    },
    MuiFormHelperText: {
      root: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 8,
        color: primaryPalette.bluish1,
        fontFamily: 'ProximaNova-Regular',
        fontSize: 14,
        letterSpacing: 'normal',
        lineHeight: '18px',
        textTransform: 'none',
        minHeight: '0px',
        marginTop: '0px',
        cursor: 'default',
        '&$error': {
          color: primaryPalette.red1,
          fontSize: 12,
          lineHeight: '12px',
          paddingTop: 4,
          paddingBottom: 4,
          textTransform: 'none',
          letterSpacing: 'normal',
          margin: 0,
        },
      },
    },
    MuiTabs: {
      flexContainer: {
        borderBottom: `2px solid ${primaryPalette.bluish8}`,
      },
      indicator: {
        backgroundColor: primaryPalette.blue2,
      },
    },
    MuiTab: {
      root: {
        fontFamily: 'ProximaNova-Regular',
        fontSize: 14,
        fontWeight: 600,
        letterSpacing: 2,
        lineHeight: '17px',
        marginRight: 28,
        '&$selected': {
          color: primaryPalette.blue4,
        },
      },
      textColorInherit: {
        color: primaryPalette.bluish7,
      },
    },
    MuiSwitch: {
      root: {
        width: 32,
        height: 20,
        padding: 0,
        margin: 0,
      },
      switchBase: {
        justifyContent: 'flex-start',
        height: 18,
        padding: 1,
        '&$checked': {
          transform: 'translateX(14px)',
          color: primaryPalette.white,
          '& $input': {
            left: -15,
          },
          '& + $track': {
            backgroundColor: primaryPalette.blue1,
            opacity: 1,
            border: 'none',
          },
        },
        '&$focusVisible $thumb': {
          color: primaryPalette.blue1,
          border: `6px solid ${primaryPalette.white}`,
        },
      },
      thumb: {
        width: 16,
        height: 16,
      },
      track: {
        boxSizing: 'border-box',
        height: 20,
        borderRadius: 10,
        border: `1px solid ${primaryPalette.bluish6}`,
        backgroundColor: primaryPalette.bluish6,
        opacity: 1,
        transition: 'background-color .25s ease, border .25s ease',
      },
      input: {
        left: 0,
        width: '100%',
      },
      checked: {},
      focusVisible: {},
    },
    MuiMenuItem: {
      root: {
        fontSize: 14,
        fontFamily: 'ProximaNova-Regular',
        padding: 8,
      },
    },
    MuiDialog: {
      paper: {
        minWidth: 1000,
      },
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: primaryPalette.bluish5,
        fontFamily: 'ProximaNova-Regular',
        height: 'auto',
      },
    },
    MuiPickersDay: {
      day: {
        color: primaryPalette.bluish2,
        fontFamily: 'ProximaNova-Regular',
        '&$daySelected': {
          backgroundColor: primaryPalette.blue2,
          '&:hover': {
            backgroundColor: primaryPalette.blue4,
          },
        },
      },
    },
    MuiPickersToolbarButton: {
      toolbarBtn: {
        backgroundColor: 'transparent',
      },
    },
    MuiPickersBasePicker: {
      pickerView: {
        minHeight: 'auto',
      },
    },
    MuiPickersYear: {
      root: {
        color: primaryPalette.bluish2,
        fontFamily: 'ProximaNova-Regular',
        '&$yearSelected': {
          color: primaryPalette.blue2,
          margin: '5px 0',
        },
      },
    },
    MuiLinearProgress: {
      root: {
        height: 8,
        borderRadius: 2,
      },
      colorPrimary: {
        backgroundColor: primaryPalette.bluish8,
      },
      barColorPrimary: {
        backgroundColor: primaryPalette.green1,
        borderRadius: 2,
      },
    },
    MuiAccordionSummary: {
      root: {
        padding: 0,
        minHeight: 'auto',
        justifyContent: 'flex-start',
        '&$expanded': {
          minHeight: 'auto',
        },
      },
      content: {
        margin: 0,
        flexGrow: 0,
        '&$expanded': {
          margin: '0 0 10px 0',
        },
        '& > :last-child': {
          paddingRight: 8,
        },
      },
      expandIcon: {
        position: 'static',
        width: 8,
        height: 14,
        transform: 'translateY(-1px) rotate(0deg)',
        transformOrigin: 'center center',
        '&$expanded': {
          transform: 'translateY(-50%) rotate(180deg)',
          transformOrigin: 'center center',
        },
      },
    },
    MuiAccordionDetails: {
      root: {
        padding: 0,
      },
    },
    MuiRadio: {
      root: {
        padding: 0,
        width: 18,
        height: 18,
        '&:hover': {
          backgroundColor: 'transparent',
        },
        '&$colorSecondary': {
          color: primaryPalette.bluish1,
        },
        '&$colorSecondary$checked': {
          color: primaryPalette.bluish1,
          '&:hover': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
    MuiSlider: {
      root: {
        color: primaryPalette.blue1,
        height: 8,
        padding: '16px 0',
        transition: 'color .2s ease',
        '&$disabled': {
          color: primaryPalette.bluish7,
        },
      },
      thumb: {
        backgroundColor: primaryPalette.white,
        border: `2px solid ${primaryPalette.blue1}`,
        marginTop: -8,
        marginLeft: -12,
        height: 24,
        width: 24,
        transition: 'border-color .2s ease',
        '&:focus, &:hover, &$active': {
          boxShadow: 'inherit',
        },
        '&$disabled': {
          border: `2px solid ${primaryPalette.bluish7}`,
          marginTop: -8,
          marginLeft: -12,
          height: 24,
          width: 24,
        },
      },
      active: {},
      valueLabel: {
        left: 'calc(-50% + 4px)',
      },
      track: {
        height: 8,
        borderRadius: 4,
      },
      rail: {
        height: 8,
        borderRadius: 4,
      },
    },
    MuiCheckbox: {
      root: {
        '&:hover': {
          backgroundColor: 'transparent',
        },
        '&$colorSecondary$checked:hover': {
          backgroundColor: 'transparent',
        },
      },
    },
    MuiPaper: {
      root: {
        '&:focus': {
          outline: 'none',
        },
      },
    },
    MuiTimeline: {
      root: {
        margin: 0,
        padding: 0,
      },
    },
    MuiTimelineItem: {
      missingOppositeContent: {
        '&:before': {
          flex: 0,
          padding: 0,
        },
      },
    },
    MuiTimelineContent: {
      root: {
        padding: 0,
      },
    },
    MuiTimelineDot: {
      root: {
        backgroundColor: 'transparent',
        boxShadow: 'none',
        border: 'none',
        borderStyle: 'none',
        padding: 0,
        marginTop: 0,
        marginBottom: 0,
      },
    },
    MuiTimelineConnector: {
      root: {
        backgroundColor: primaryPalette.bluish8,
        margin: '8px 0',
        minHeight: 17,
        width: 4,
      },
    },
    MuiStepper: {
      root: {
        padding: 0,
      },
    },
    MuiStepLabel: {
      labelContainer: {
        display: 'flex',
      },
      iconContainer: {
        paddingRight: 12,
      },
      label: {
        color: primaryPalette.bluish2,
        fontFamily: 'ProximaNova-Regular',
        fontSize: 16,
        lineHeight: '20px',

        '&$active': {
          color: 'transparent',
          fontWeight: 'normal',
          textShadow: `0px 0px 1px ${primaryPalette.blue2}`,
        },
      },
      active: {},
    },
    MuiStepIcon: {
      root: {
        color: primaryPalette.bluish4,
      },
    },
    MuiStepConnector: {
      vertical: {
        padding: 0,
        marginLeft: 13,
      },
      lineVertical: {
        borderLeftWidth: 6,
        color: primaryPalette.bluish8,
        opacity: 0.4,
        minHeight: 20,
      },
    },
    MuiDrawer: {
      paper: {
        width: 450,
        backgroundColor: primaryPalette.white,
        overflowY: 'hidden',
      },
    },
  },
});

export default KadarTheme;
