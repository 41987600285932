import { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, ClickAwayListener } from '@material-ui/core';
import ArrowTooltip from '../arrowTooltip';
import CustomScrollBar from '../customScrollBar';
import CustomButton from '../customButton';
import { isArray, isArrayEmpty } from '../../../utility/helpers';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  tooltipLabel: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: 220,
  },
  tooltipTitle: {
    color: `${primary.bluish2}`,
    wordBreak: 'break-word',
    textAlign: 'left',
    marginBottom: spacing(2),
  },
  popper: {
    pointerEvents: 'auto',
    minWidth: 308,
  },
  labelItemContainer: {
    paddingBottom: spacing(2),
    paddingTop: spacing(2),
    cursor: 'pointer',
    borderBottom: `1px solid ${primary.bluish7}`,
    '&:last-of-type': {
      marginBottom: 0,
      borderBottom: 'none',
    },
  },
  labelItemContainerDisabled: {
    cursor: 'default',
  },
  customTooltipClass: {
    minWidth: 308,
    padding: spacing(6),
  },
  childrenContainer: {
    cursor: 'pointer',
  },
  childrenContainerDisabled: {
    cursor: 'default',
  },
  scrollY: {
    right: -15,
  },
  tooltipButtonWrapper: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    marginTop: spacing(2),
  },
}));

const AsyncListTooltip = ({
  customTooltipClass,
  customPopperClass,
  customTooltipLabelClass,
  title,
  tooltipButtonLabel,
  labelData,
  getLabelData,
  labelComponent: Item,
  labelComponentProps,
  renderLabelItem,
  children,
  tooltipButtonProps,
  isArrowHidden,
  isDisabled,
  hasTooltipButton,
  isTooltipDisabled,
  shouldDisablePortal,
  isItemClickable,
  isCustomContent,
  onToggleView,
  onTooltipButtonClickHandler,
  onItemClickHandler,
  ...rest
}) => {
  const classes = useStyles();

  const [isOpened, setIsOpened] = useState(false);
  const [items, setItems] = useState(null);

  const handleCloseTooltip = () => {
    setIsOpened(false);
    onToggleView(true);
  };

  const handleTooltipButtonClick = e => {
    e.preventDefault();
    e.stopPropagation();
    handleCloseTooltip();
    return onTooltipButtonClickHandler();
  };

  const handleOpenTooltip = async e => {
    if (!isOpened) {
      e.preventDefault();
      e.stopPropagation();
      let tooltipItems;
      if (!isArrayEmpty(labelData)) {
        tooltipItems = labelData;
      } else {
        tooltipItems = await getLabelData().then(({ data }) => data);
      }

      onToggleView(false);
      setIsOpened(true);
      setItems(tooltipItems?.results || tooltipItems);
    } else {
      handleCloseTooltip();
    }
  };

  const renderContent = () => {
    if (isCustomContent) {
      return renderLabelItem(items);
    }

    return (
      <>
        {items.map((item, index) => {
          const isAccessible = !isDisabled && isItemClickable(item);

          return (
            <div
              key={`list_tooltip_item_${index}`}
              className={classNames(classes.labelItemContainer, {
                [classes.labelItemContainerDisabled]: !isAccessible,
              })}
              onClick={
                isAccessible
                  ? e => {
                      e.preventDefault();
                      e.stopPropagation();
                      onItemClickHandler(item.id);
                    }
                  : e => e.stopPropagation()
              }
            >
              {Item ? (
                <Item data={item} {...labelComponentProps} />
              ) : (
                renderLabelItem(item, isAccessible)
              )}
            </div>
          );
        })}
      </>
    );
  };

  const renderTooltipLabel = () =>
    ((isArray(items) && !isArrayEmpty(items)) || hasTooltipButton) && (
      <>
        {title && (
          <Typography variant="h5" className={classes.tooltipTitle}>
            {`${title}:`}
          </Typography>
        )}
        {isArray(items) && !isArrayEmpty(items) && (
          <div
            className={classNames(
              classes.tooltipLabel,
              customTooltipLabelClass
            )}
          >
            <CustomScrollBar
              verticalScroll
              removeScrollX
              passContentHeight
              passContentWidth
              customScrollBarYClass={classes.scrollY}
            >
              {renderContent()}
            </CustomScrollBar>
          </div>
        )}
        {hasTooltipButton && (
          <div className={classes.tooltipButtonWrapper}>
            <CustomButton
              type="addDarkRoundedOutlined"
              onClick={handleTooltipButtonClick}
              {...tooltipButtonProps}
            >
              {tooltipButtonLabel}
            </CustomButton>
          </div>
        )}
      </>
    );

  return (
    <ClickAwayListener
      mouseEvent="onMouseDown"
      onClickAway={handleCloseTooltip}
    >
      <div>
        <ArrowTooltip
          open={isOpened}
          tooltipPopperClassName={classNames(classes.popper, customPopperClass)}
          tooltipLabel={renderTooltipLabel()}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          shouldDisablePortal={shouldDisablePortal}
          interactive
          tooltipClassName={classNames(
            classes.customTooltipClass,
            customTooltipClass
          )}
          isArrowHidden={isArrowHidden}
          {...rest}
        >
          <div
            className={classNames(classes.childrenContainer, {
              [classes.childrenContainerDisabled]: isTooltipDisabled,
            })}
            onClick={!isTooltipDisabled ? handleOpenTooltip : () => {}}
          >
            {children}
          </div>
        </ArrowTooltip>
      </div>
    </ClickAwayListener>
  );
};

AsyncListTooltip.defaultProps = {
  title: '',
  tooltipButtonLabel: '',
  isDisabled: false,
  hasTooltipButton: false,
  labelData: [],
  labelComponentProps: {},
  isArrowHidden: false,
  isCustomContent: false,
  customTooltipClass: '',
  customPopperClass: null,
  customTooltipLabelClass: '',
  shouldDisablePortal: true,
  isTooltipDisabled: false,
  labelComponent: null,
  tooltipButtonProps: {},
  renderLabelItem: () => {},
  getLabelData: () => {},
  onItemClickHandler: () => {},
  onToggleView: () => {},
  onTooltipButtonClickHandler: () => {},
  isItemClickable: () => true,
};

AsyncListTooltip.propTypes = {
  customTooltipClass: PropTypes.string,
  customPopperClass: PropTypes.string,
  customTooltipLabelClass: PropTypes.string,
  tooltipButtonProps: PropTypes.object,
  isCustomContent: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isTooltipDisabled: PropTypes.bool,
  title: PropTypes.string,
  tooltipButtonLabel: PropTypes.string,
  labelData: PropTypes.array,
  labelComponentProps: PropTypes.object,
  hasTooltipButton: PropTypes.bool,
  isArrowHidden: PropTypes.bool,
  onToggleView: PropTypes.func,
  onItemClickHandler: PropTypes.func,
  isItemClickable: PropTypes.func,
  getLabelData: PropTypes.func,
  renderLabelItem: PropTypes.func,
  labelComponent: PropTypes.elementType,
  shouldDisablePortal: PropTypes.bool,
  onTooltipButtonClickHandler: PropTypes.func,
};

export default AsyncListTooltip;
