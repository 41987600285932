import { createAction, handleActions } from 'redux-actions';
import http from '../../utility/http';
import { parseDuplicateParameters } from '../../utility/uiUtils';
import { API_ABSENCE_REQUESTS } from '../../constants/apiRoutes';
import { LEAVE_REQUESTS_DEFAULT_PARAMS } from '../../constants/pages';

// ------------------------------------
// Constants
// ------------------------------------

const SET_ALL_LEAVE_REQUESTS = 'SET_ALL_LEAVE_REQUESTS';
const LOAD_MORE_LEAVE_REQUESTS = 'LOAD_MORE_LEAVE_REQUESTS';

const initialState = {};

// ------------------------------------
// Actions
// ------------------------------------

const setAllLeaveRequests = createAction(SET_ALL_LEAVE_REQUESTS);
const loadMoreLeaveRequests = createAction(LOAD_MORE_LEAVE_REQUESTS);

// ------------------------------------
// Reducers
// ------------------------------------

const reducers = {
  [SET_ALL_LEAVE_REQUESTS]: (state, { payload }) => {
    return { ...payload };
  },
  [LOAD_MORE_LEAVE_REQUESTS]: (state, { payload }) => {
    return {
      ...state,
      ...payload,
      results: state.results
        ? [...state.results, ...payload.results]
        : [...payload.results],
    };
  },
};

export const getAllLeaveRequests = (
  dispatch,
  isLoadMore = false,
  params = {}
) => {
  return http
    .get(API_ABSENCE_REQUESTS, {
      params: { ...LEAVE_REQUESTS_DEFAULT_PARAMS, ...params },
      paramsSerializer: data => parseDuplicateParameters(data),
    })
    .then(({ data }) => {
      dispatch(
        isLoadMore ? loadMoreLeaveRequests(data) : setAllLeaveRequests(data)
      );
    });
};

// ------------------------------------
// "plain" action functions
// ------------------------------------

export const clearAllLeaveRequests = () => setAllLeaveRequests(initialState);

export default handleActions(reducers, initialState);
