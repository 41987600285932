import { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Typography, makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import ConditionalTooltip from '../conditionalTooltip';
import UserCount from '../userCount';
import ListWithTooltip from '../listWithTooltip';
import RichTextPreview from '../richTextPreview';
import ActionButton from '../actionButton';
import { useCustomEffect } from '../../../utility/hooks';
import { isEllipsisActive } from '../../../utility/helpers';
import { ellipsis } from '../../../constants/helperCssRules';
import { ACTION_BUTTON_TYPES } from '../actionButton/config';

const VISIBLE_ITEMS = 3;

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  main: {
    cursor: 'pointer',
    boxSizing: 'border-box',
    border: `1px solid ${primary.bluish7}`,
    borderRadius: 4,
    width: '100%',
    minWidth: 280,
    minHeight: 241,
    padding: spacing(6),
    transition: 'border .2s ease',
    '&:hover': {
      border: `1px solid ${primary.blue1}`,
    },
  },
  readOnly: {
    cursor: 'auto',
  },
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  title: {
    maxWidth: 158,
    ...ellipsis(),
  },
  userCount: {
    marginLeft: spacing(1),
  },
  labelWrapper: {
    lineHeight: '20px',
    marginBottom: spacing(1),
  },
  label: {
    color: primary.bluish4,
  },
  descriptionWrapper: {
    margin: spacing(2, 0),
    height: 95,
  },
  descriptionLabelWrapper: {
    minHeight: 30,
  },
  description: {
    color: primary.bluish3,
    '-webkit-line-clamp': 3,
    wordBreak: 'break-word',
  },
  tags: {
    marginTop: spacing(2),
  },
  tagsList: {
    gridTemplateColumns: `repeat(${VISIBLE_ITEMS}, minmax(0,min-content)) minmax(auto, min-content)`,
  },
  disabledUserCount: {
    backgroundColor: 'unset',
    cursor: 'default',
  },
  disabledButton: {
    pointerEvents: 'none',
  },
}));

const JobTitleItem = ({
  data,
  labels,
  getUsers,
  goToUserProfilePage,
  canSeeUserProfile,
  isReadOnly,
  handleAddUser,
  hasAddButton,
  handlePreviewClick,
  onJobTitleClick,
}) => {
  const classes = useStyles();
  const titleRef = useRef(null);

  const [hasTitleTooltip, setHasTitleTooltip] = useState(false);

  const peopleCount = data.people_count;
  const isButtonDisabled = !peopleCount && !hasAddButton;

  useCustomEffect(() => {
    setHasTitleTooltip(isEllipsisActive(titleRef?.current));
  }, [data.name]);

  const renderTitle = () => (
    <div className={classes.titleWrapper}>
      <ConditionalTooltip addTooltip={hasTitleTooltip} message={data.name}>
        <Typography
          ref={titleRef}
          variant="subtitle2"
          className={classes.title}
        >
          {data.name}
        </Typography>
      </ConditionalTooltip>
      <UserCount
        className={classNames(classes.userCount, {
          [classes.disabledButton]: isButtonDisabled,
        })}
        customUserCountBadgeClassName={classNames({
          [classes.disabledUserCount]: isButtonDisabled,
        })}
        userCount={peopleCount}
        canSeeUserProfile={canSeeUserProfile}
        getUsers={getUsers}
        goToUserProfile={goToUserProfilePage}
        addButtonLabel={labels.assignTo}
        onAdd={handleAddUser}
        hasAddButton={hasAddButton}
      />
    </div>
  );

  const renderDescription = () => (
    <div className={classes.descriptionWrapper}>
      <div
        className={classNames(
          classes.labelWrapper,
          classes.titleWrapper,
          classes.descriptionLabelWrapper
        )}
      >
        <Typography variant="body2" className={classes.label}>
          {labels.description}
        </Typography>
        {data.description ? (
          <ActionButton
            type={ACTION_BUTTON_TYPES.PREVIEW_DESCRIPTION}
            tooltipText={labels.preview}
            onClickHandler={handlePreviewClick}
          />
        ) : null}
      </div>
      {data.description ? (
        <RichTextPreview
          className={classes.description}
          text={data.description}
          isEllipsis
        />
      ) : (
        <Typography variant="subtitle2" className={classes.description}>
          -
        </Typography>
      )}
    </div>
  );

  const renderTags = () => (
    <ListWithTooltip
      className={classes.tags}
      listClass={classes.tagsList}
      label={labels.tags}
      items={data.tags}
      asyncListProps={{
        isDisabled: true,
      }}
      visibleItemsCount={VISIBLE_ITEMS}
      isTagsList
    />
  );

  return (
    <div
      className={classNames(classes.main, { [classes.readOnly]: isReadOnly })}
      onClick={isReadOnly ? () => {} : onJobTitleClick}
    >
      {renderTitle()}
      {renderDescription()}
      {renderTags()}
    </div>
  );
};

JobTitleItem.propTypes = {
  isReadOnly: PropTypes.bool.isRequired,
  data: PropTypes.object.isRequired,
  labels: PropTypes.object.isRequired,
  canSeeUserProfile: PropTypes.func.isRequired,
  onJobTitleClick: PropTypes.func.isRequired,
  goToUserProfilePage: PropTypes.func.isRequired,
  getUsers: PropTypes.func.isRequired,
  handleAddUser: PropTypes.func.isRequired,
  hasAddButton: PropTypes.bool.isRequired,
};

export default JobTitleItem;
