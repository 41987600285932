import {
  addMonths,
  endOfToday,
  differenceInCalendarDays,
  startOfDay,
  parseISO,
  format,
  isPast,
  isFuture,
  getISOWeek,
  startOfISOWeek,
  endOfISOWeek,
  getISOWeeksInYear,
  differenceInCalendarISOWeeks,
  isMonday,
  isSameMonth,
  subWeeks,
  endOfDay,
  addDays,
  addHours,
  subDays,
  isSameDay,
  isEqual,
  startOfHour,
  getQuarter,
  differenceInMilliseconds,
  startOfYear,
  endOfYear,
  isAfter,
  isSameYear,
  differenceInCalendarYears,
  getYear,
} from 'date-fns';

export const formatDate = (date, formatKey = 'dd MMM y') =>
  date ? format(new Date(date), formatKey) : undefined;

export const getDifferenceInDays = date => {
  const today = startOfDay(new Date());

  return differenceInCalendarDays(parseISO(date), today);
};

export const areEqualInMilliseconds = (date1, date2) => {
  return differenceInMilliseconds(parseISO(date1), parseISO(date2));
};

export const isDateInPast = date => isPast(new Date(date));

export const isDateInFuture = date => isFuture(new Date(date));

export const getWeekNumberInYear = date => getISOWeek(new Date(date));

export const getNumberOfWeeksInYear = date => getISOWeeksInYear(new Date(date));

export const getNumberOfWeeksInRange = (dateStart, dateEnd) =>
  differenceInCalendarISOWeeks(new Date(dateEnd), new Date(dateStart));

export const getStartOfWeek = date => startOfISOWeek(new Date(date));

export const getEndOfWeek = date => endOfISOWeek(new Date(date));

export const getStartOfDay = date => startOfDay(new Date(date));

export const isFirstWeekOfTheMonth = weekStart => {
  const startOfWeek = new Date(weekStart);
  const previousWeek = subWeeks(startOfWeek, 1);

  return isMonday(startOfWeek) && !isSameMonth(startOfWeek, previousWeek);
};

export const getTimeRangeFromToday = (months = 6) => ({
  start: formatDate(addMonths(new Date(), -months), 'yyyy-LL-dd'),
  end: formatDate(endOfToday(), 'yyyy-LL-dd'),
});

export const getStartEndOfDayUtc = (date, isEnd = false) => {
  const day = new Date(date);

  return isEnd ? endOfDay(day).toISOString() : startOfDay(day).toISOString();
};

export const addDaysFromSpecificHour = (daysCount = 7, startHours = 12) =>
  addDays(addHours(startOfDay(new Date()), startHours), daysCount);

export const addNumberOfDays = (date, daysCount = 7) =>
  addDays(new Date(date), daysCount);

export const substractNumberOfDays = (date, daysCount = 1) =>
  subDays(new Date(date), daysCount);

export const checkIsSameDay = (firstDate, secondDate) =>
  isSameDay(new Date(firstDate), new Date(secondDate));
export const checkIsSameYear = (laterDate, earlierDate) =>
  isSameYear(new Date(laterDate), new Date(earlierDate));

export const isSameDate = (firstDate, secondDate) =>
  isEqual(new Date(firstDate), new Date(secondDate));

export const addHoursToCurrentStartHour = (hours = 1) =>
  addHours(startOfHour(new Date()), hours);

export const getQuarterFromDate = date => getQuarter(new Date(date));

export const checkIsDateAfterPrevious = (date, previousDate) =>
  isAfter(new Date(date), new Date(previousDate));

export const getStartOfYear = date =>
  startOfYear(date ? new Date(date) : new Date());

export const getEndOfYear = date =>
  endOfYear(date ? new Date(date) : new Date());

export const getDifferenceInCalendarYears = (laterDate, earlierDate) => {
  return differenceInCalendarYears(new Date(laterDate), new Date(earlierDate));
};

export const getYearFromDate = date => getYear(date);
