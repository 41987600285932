import { ReactComponent as StatusIcon } from '../assets/icons/status-icon.svg';
import { ReactComponent as TypeIcon } from '../assets/icons/attribute-type.svg';
import { ReactComponent as EditIcon } from '../assets/icons/edit-dark.svg';
import { ReactComponent as DeleteIcon } from '../assets/icons/delete-outlined.svg';
import { isEmpty, forbiddenCharacters, validateLength } from './validation';
import {
  formatDate,
  checkIsDateAfterPrevious,
  checkIsSameDay,
  isSameDate,
  checkIsSameYear,
  getDifferenceInCalendarYears,
} from './dateUtils';
import http from './http';
import { api_user_absence_available_days } from '../constants/apiRoutes';
import {
  ABSENCE_TYPES,
  ACCRUAL_TYPES,
  ABSENCE_TYPES_KEYS,
} from '../constants/absence';
import { SINGLE_DAY_SAVE_FORMAT } from '../constants/date';
import { ABSENCE_REQUEST_STATUSES } from '../constants/statuses';
import { PARAMS } from '../constants/pages';
import { ABSENCE_FEATURE_INITIAL_DATE } from '../constants/appConfig';
import { FIELD_TYPES } from '../constants/fieldTypes';

export const getYearOptions = () => {
  const currentYear = +formatDate(new Date(), 'yyyy');

  return Array.from(
    {
      length:
        getDifferenceInCalendarYears(new Date(), ABSENCE_FEATURE_INITIAL_DATE) +
        2,
    },
    (_, i) => ({
      label: currentYear - i + 1,
      value: currentYear - i + 1,
    })
  );
};

export const getTranslatedAbsenceTypes = (
  labels,
  disabledLabel,
  usedTypes = []
) =>
  ABSENCE_TYPES.map(absenceType => ({
    ...absenceType,
    name: labels[absenceType.key].name,
    description: labels[absenceType.key].description,
    isDisabled: usedTypes.some(usedType => usedType.type === absenceType.id),
    disabledLabel,
  }));

export const getUserAbsenceTypes = (absenceTypes, userAbsenceTypes = []) =>
  userAbsenceTypes.map(userAbsenceType => {
    const { name, description, icon, color } = absenceTypes.find(
      type => type.id === userAbsenceType.type
    );

    return {
      ...userAbsenceType,
      name,
      description,
      icon,
      color,
    };
  });

export const getFilteredUserAbsenceTypes = userAbsenceTypes =>
  userAbsenceTypes.filter(
    aType =>
      !aType.is_manual_total || (aType.is_manual_total && aType.available_days)
  );

export const getUserAvailableAbsenceTypes = userAbsenceTypes =>
  userAbsenceTypes.filter(aType => !aType.is_manual_total);

export const getTranslatedAccrualTypes = labels =>
  ACCRUAL_TYPES.map(accrualType => ({
    ...accrualType,
    name: labels[accrualType.key],
  }));

export const getTranslatedAbsenceRequestStatuses = labels =>
  ABSENCE_REQUEST_STATUSES.map(reqStatus => ({
    ...reqStatus,
    name: labels[reqStatus.key],
  }));

export const prepareAbsenceSetsData = (
  translations,
  sets,
  absenceTypes,
  accrualTypes
) => {
  return sets.map(set => ({
    ...set,
    types: set?.types.map(absenceType => {
      const {
        name,
        description,
        icon,
        color,
        isDisabled,
        disabledLabel,
        hasExtensions,
      } = absenceTypes.find(aType => aType.id === absenceType.type);
      const currentAccrual = accrualTypes.find(
        aType => aType.value === absenceType.accrual
      );

      return {
        ...absenceType,
        name,
        description,
        icon,
        color,
        isDisabled,
        disabledLabel,
        ...(currentAccrual
          ? {
              accrualLabel: `${
                translations.accrual
              } ${currentAccrual.name.toLowerCase()}`,
            }
          : {}),
        hasExtensions,
      };
    }),
  }));
};

export const getManageLeaveRequestInitialData = (leaveRequest, user = null) => {
  if (leaveRequest) {
    return {
      ...leaveRequest,
      absence_type: leaveRequest.type,
      amount: Math.abs(leaveRequest.used_days),
    };
  }

  return {
    user,
    amount: 0,
    absence_type: null,
    description: '',
    absence_from: null,
    absence_to: null,
  };
};

export const getManageLeaveRequestFields = (
  classes,
  currentYear,
  isEdit = false,
  hasUserField = false,
  getAbsenceTypes = () => {}
) => [
  ...(hasUserField
    ? [
        {
          name: 'user',
          translationKey: 'user',
          fieldWrapperClass: classes.userField,
          type: FIELD_TYPES.SELECT,
          optionsKey: 'allUsers',
          isUser: true,
          shouldReturnOption: true,
          parser: {
            value: 'id',
            label: 'first_name',
          },
          isDisabled: isEdit,
          required: true,
          dependants: [
            {
              name: 'absence_type',
              callbackFnc: (prevUser, currentUser) =>
                getAbsenceTypes(currentUser?.id),
            },
            {
              name: 'absence_type',
              value: null,
            },
            {
              name: 'absence_from',
              value: null,
            },
            {
              name: 'absence_to',
              value: null,
            },
          ],
          validators: [
            {
              type: 'required',
              validator: isEmpty,
            },
          ],
        },
      ]
    : []),
  {
    name: 'absence_type',
    type: FIELD_TYPES.SELECT,
    translationKey: 'type',
    fieldWrapperClass: classes.typeField,
    required: true,
    optionsKey: 'absenceTypes',
    parser: {
      value: 'type',
      label: 'name',
    },
    validators: [
      {
        type: 'required',
        validator: isEmpty,
      },
    ],
  },
  {
    name: 'absence_from',
    type: FIELD_TYPES.DATE_PICKER,
    translationKey: 'absenceFrom',
    fieldWrapperClass: classes.fromField,
    required: true,
    valueFormat: SINGLE_DAY_SAVE_FORMAT,
    getMinDate: () => new Date(currentYear, 0, 1),
    getMaxDate: () => new Date(currentYear + 1, 11, 31),
    dependants: [
      {
        name: 'absence_to',
        resetFnc: (prevFromValue, currentFromValue, currentToValue) =>
          checkIsSameDay(currentFromValue, currentToValue) ||
          checkIsDateAfterPrevious(currentToValue, currentFromValue)
            ? currentToValue
            : currentFromValue,
      },
      {
        name: 'amount',
        resetFnc: async (
          prevFromValue,
          currentFromValue,
          currentAmountValue,
          initialValues,
          values
        ) => {
          if (
            currentFromValue &&
            currentFromValue === initialValues?.absence_from &&
            values?.absence_to &&
            values.absence_to === initialValues?.absence_to
          ) {
            return initialValues?.amount;
          }

          if (values.user) {
            const params = {
              [PARAMS.FROM]: currentFromValue,
              [PARAMS.TO]:
                values?.absence_to &&
                (checkIsSameDay(currentFromValue, values.absence_to) ||
                  checkIsDateAfterPrevious(values.absence_to, currentFromValue))
                  ? values.absence_to
                  : currentFromValue,
              ...(values.id ? { id: values.id } : {}),
            };
            const { data } = await http.get(
              api_user_absence_available_days(values.user.id),
              { params }
            );

            return data.days;
          }

          return currentAmountValue;
        },
      },
    ],
    validators: [
      {
        type: 'required',
        validator: isEmpty,
      },
    ],
  },
  {
    name: 'absence_to',
    type: FIELD_TYPES.DATE_PICKER,
    translationKey: 'absenceTo',
    fieldWrapperClass: classes.toField,
    required: true,
    valueFormat: SINGLE_DAY_SAVE_FORMAT,
    getMinDate: (fieldValue, values) =>
      values.absence_from || new Date(currentYear, 0, 1),
    getMaxDate: () => new Date(currentYear + 1, 11, 31),
    dependants: [
      {
        name: 'absence_from',
        resetFnc: (prevFromValue, currentToValue, currentFromValue) =>
          currentFromValue === null ? currentToValue : currentFromValue,
      },
      {
        name: 'amount',
        resetFnc: async (
          prevFromValue,
          currentToValue,
          currentAmountValue,
          initialValues,
          values
        ) => {
          if (
            currentToValue &&
            currentToValue === initialValues?.absence_to &&
            values?.absence_from &&
            values.absence_from === initialValues?.absence_from
          ) {
            return initialValues?.amount;
          }

          if (values.user) {
            const params = {
              [PARAMS.FROM]: values?.absence_from
                ? values.absence_from
                : currentToValue,
              [PARAMS.TO]: currentToValue,
              ...(values.id ? { id: values.id } : {}),
            };

            const { data } = await http.get(
              api_user_absence_available_days(values.user.id),
              { params }
            );

            return data.days;
          }

          return currentAmountValue;
        },
      },
    ],
    validators: [
      {
        type: 'required',
        validator: isEmpty,
      },
    ],
  },
  {
    name: 'amount',
    type: FIELD_TYPES.TEXT,
    isDisabled: true,
    inputType: 'number',
    required: true,
    shouldValidateDisabledField: true,
    validators: [
      {
        type: 'minDays',
        validator: value => value !== 0,
      },
    ],
  },
  {
    name: 'description',
    type: FIELD_TYPES.TEXT,
    fieldWrapperClass: classes.descriptionField,
    multiline: true,
    rows: 5,
    validators: [
      {
        type: 'forbiddenCharacters',
        validator: forbiddenCharacters,
      },
      {
        type: 'descriptionMaxLength',
        validator: fieldValue => validateLength(fieldValue, 0, 255),
      },
    ],
  },
];

export const getLeaveRequestsPageFilters = (
  translations,
  absenceTypes,
  statuses
) => {
  const { STATUS, TYPE } = PARAMS;
  const defaultOption = { id: null, name: translations.all };

  return [
    {
      id: TYPE,
      name: translations.filters.labels.timeOffType,
      icon: TypeIcon,
      isSingleSelect: true,
      items: [defaultOption, ...absenceTypes],
    },
    {
      id: STATUS,
      name: translations.filters.labels[STATUS],
      icon: StatusIcon,
      isSingleSelect: true,
      items: [defaultOption, ...statuses],
    },
  ];
};

export const getLeaveRequestMenuItems = (
  labels,
  request,
  hasEdit,
  hasDelete,
  onEdit,
  onDelete
) => {
  return [
    {
      type: 'edit',
      label: labels.edit,
      shouldItemRender: () => hasEdit,
      action: () => onEdit(request),
      icon: EditIcon,
    },
    {
      type: 'delete',
      label: labels.delete,
      shouldItemRender: () => hasDelete,
      action: () => onDelete(request),
      icon: DeleteIcon,
    },
  ];
};

export const getUpcomingTimeOffPeriodLabel = (fromDate, toDate) => {
  const DAY_MONTH_FORMAT = 'dd MMM';

  if (isSameDate(fromDate, toDate)) {
    return formatDate(fromDate);
  }

  if (checkIsSameYear(toDate, fromDate)) {
    return `${formatDate(fromDate, DAY_MONTH_FORMAT)} - ${formatDate(toDate)}`;
  }

  return `${formatDate(fromDate)} - ${formatDate(toDate)}`;
};

export const getRequestPeriodLabel = (
  translations,
  fromDate,
  toDate,
  usedDays
) => {
  const DAY_MONTH_FORMAT = 'dd MMM';

  if (isSameDate(fromDate, toDate)) {
    return `${formatDate(fromDate)} (${usedDays} ${
      usedDays === 1 ? translations.day : translations.days
    })`;
  }

  if (checkIsSameYear(toDate, fromDate)) {
    return `${formatDate(fromDate, DAY_MONTH_FORMAT)} - ${formatDate(
      toDate
    )} (${usedDays} ${usedDays === 1 ? translations.day : translations.days})`;
  }

  return `${formatDate(fromDate)} - ${formatDate(toDate)} (${usedDays} ${
    usedDays === 1 ? translations.day : translations.days
  })`;
};

export const absenceTypeHasAditionalSettingsCheck = (
  type,
  shouldRender = true
) =>
  shouldRender &&
  type !== ABSENCE_TYPES_KEYS.PARENTAL_LEAVE &&
  type !== ABSENCE_TYPES_KEYS.UNPAID_LEAVE;
