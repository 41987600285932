import { useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Typography, makeStyles, alpha } from '@material-ui/core';
import UserCount from '../../../../userCount';
import UserCountBadge from '../../../../userCountBadge';
import PlaceholderButton from '../../../../placeholderButton';
import DotsMenu from '../../../../dotsMenu';
import ConditionalTooltip from '../../../../conditionalTooltip';
import Extensions from './extensions';
import DraggableScroll from '../../../../draggableScroll';
import ScrollIndicators from '../../../../scrollIndicators';
import { ReactComponent as CheckboxIcon } from '../../../../../../assets/icons/check_box.svg';
import { ReactComponent as RemoveIcon } from '../../../../../../assets/icons/remove_circle.svg';
import { absenceTypeHasAditionalSettingsCheck } from '../../../../../../utility/absence';
import { useDraggableScrollPosition } from '../../../../../../utility/hooks';
import { formatDate } from '../../../../../../utility/dateUtils';
import { getMenuItems } from '../../config';
import { ABSENCE_TYPES_KEYS } from '../../../../../../constants/absence';
import { getUserStatusLabel } from '../../../../../../utility/uiUtils';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  root: {
    boxSizing: 'border-box',
    backgroundColor: ({ color }) => color,
    borderRadius: 16,
    display: 'flex',
    height: 210,
    maxWidth: '100%',
    boxShadow: `0px 2px 8px 0px ${alpha(primary.black, 0.07)}`,
  },
  rootNoAdditionalSettings: {
    height: 82,
  },
  info: {
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    padding: spacing(4),
    height: '100%',
    width: 370,
    flexShrink: 0,
  },
  infoHeaderWrapper: {
    marginBottom: spacing(4),
  },
  infoHeaderTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    marginTop: spacing(0.75),
  },
  icon: {
    marginRight: spacing(1),
  },
  actionsWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  actionsMenu: {
    marginLeft: spacing(1.5),
  },
  description: {
    marginTop: spacing(1),
  },
  countBadge: {
    backgroundColor: primary.bluish8,
  },
  userCount: {
    flexShrink: 0,
    backgroundColor: 'unset',
    cursor: 'default',
  },
  daysCountLabel: {
    color: primary.bluish4,
  },
  infoExtensionsWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 'auto',
  },
  expirationWrapper: {
    display: 'flex',
    marginTop: 'auto',
    width: 'fit-content',
  },
  infoExtension: {
    display: 'flex',
    alignItems: 'center',
  },
  extensionIcon: {
    flexShrink: 0,
    marginBottom: spacing(0.5),
    marginRight: spacing(1.5),
  },
  extensions: {
    display: 'flex',
    width: '100%',
    maxWidth: 'calc(100% - 370px)',
    position: 'relative',
  },
  scrollWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  itemsWrapper: {
    display: 'flex',
    width: 'auto',
  },
  arrow: {
    top: 90,
  },
  addExtensionButton: {
    backgroundColor: primary.bluish9,
    border: `1px dashed ${primary.bluish5}`,
    borderRadius: 16,
    width: 300,
    minWidth: 300,
    minHeight: 194,
    margin: spacing(0, 2),
    transition: 'box-shadow .3s ease',
    '&:hover': {
      border: `1px dashed ${primary.bluish5}`,
      boxShadow: `0px 2px 8px 0px ${alpha(primary.black, 0.12)}`,
    },
  },
}));

const AbsenceType = ({
  translations,
  type,
  hasSetUsers,
  onEditType,
  onDeleteType,
  onGetTypeUsers,
  onAssignTypeUsers,
  onAddExtension,
  onEditExtension,
  onDeleteExtension,
  onGetExtensionUsers,
  onAssignExtensionUsers,
  onGoToProfilePage,
}) => {
  const scrollRef = useRef(null);
  const itemsRef = useRef(null);

  const {
    color,
    description,
    name,
    icon: Icon,
    number_of_days,
    user_count,
    is_roll_over,
    accrualLabel,
    restart_balance_date,
    absence_extensions,
    hasExtensions,
  } = type;
  const classes = useStyles({ color });
  const hasAdditionalSettings = absenceTypeHasAditionalSettingsCheck(type.type);
  const isVacation = type.type === ABSENCE_TYPES_KEYS.VACATION_LEAVE;

  const [
    activeScroll,
    handleChangeScrollPosition,
    onScrollToRight,
    ,
    onScrollToStart,
  ] = useDraggableScrollPosition(scrollRef, itemsRef);

  const timeOffMenuItems = getMenuItems(
    translations.menuItemLabels,
    type,
    onEditType,
    onDeleteType
  );

  return (
    <div
      className={classNames(classes.root, {
        [classes.rootNoAdditionalSettings]: !hasAdditionalSettings,
      })}
    >
      <div className={classes.info}>
        <div
          className={classNames({
            [classes.infoHeaderWrapper]: hasAdditionalSettings,
          })}
        >
          <div className={classes.infoHeaderTitle}>
            <div className={classes.titleWrapper}>
              <Icon className={classes.icon} />
              <Typography className={classes.title} variant="h5">
                {name}
              </Typography>
            </div>
            <div className={classes.actionsWrapper}>
              {hasSetUsers ? (
                <UserCount
                  customUserCountBadgeClassName={classes.countBadge}
                  addButtonLabel={translations.assign}
                  userCount={user_count}
                  shouldDisablePortal={false}
                  getUsers={onGetTypeUsers}
                  onAdd={onAssignTypeUsers}
                  goToUserProfile={onGoToProfilePage}
                  shouldHandleTypeLabel
                  handleTypeLabel={employee =>
                    getUserStatusLabel(translations.deactivated, employee)
                  }
                  hasAddButton
                />
              ) : (
                <UserCountBadge
                  className={classes.userCount}
                  userCount={user_count}
                />
              )}
              <div className={classes.actionsMenu}>
                <DotsMenu menuItems={timeOffMenuItems} isVerticalMenu />
              </div>
            </div>
          </div>
          <Typography className={classes.description} variant="body2">
            {description}
          </Typography>
        </div>
        {hasAdditionalSettings && (
          <div>
            <Typography className={classes.daysCountLabel} variant="body2">
              {translations.daysCountLabel}
            </Typography>
            <Typography variant="h1">
              {`${number_of_days} ${
                number_of_days === 1 ? translations.day : translations.days
              }`}
            </Typography>
            <Typography variant="body2">{accrualLabel}</Typography>
          </div>
        )}
        {isVacation && (
          <ConditionalTooltip
            className={classes.expirationWrapper}
            message={`${translations.rollOverBalanceExpiration} ${formatDate(
              restart_balance_date
            )}`}
            addTooltip={is_roll_over}
          >
            <div className={classes.infoExtensionsWrapper}>
              <div className={classes.infoExtension}>
                {is_roll_over ? (
                  <CheckboxIcon className={classes.extensionIcon} />
                ) : (
                  <RemoveIcon className={classes.extensionIcon} />
                )}
                <Typography variant="subtitle2">
                  {translations.rollOver}
                </Typography>
              </div>
            </div>
          </ConditionalTooltip>
        )}
      </div>
      <div className={classes.extensions}>
        <DraggableScroll
          ref={el => {
            scrollRef.current = el;
          }}
          className={classes.scrollWrapper}
          onEndScroll={handleChangeScrollPosition}
        >
          <div ref={itemsRef} className={classes.itemsWrapper}>
            <div className={classes.itemsWrapper}>
              <Extensions
                translations={translations}
                extensions={absence_extensions}
                hasTypeUsers={user_count !== 0}
                onEdit={onEditExtension}
                onDelete={onDeleteExtension}
                onGetUsers={onGetExtensionUsers}
                onAssignUsers={onAssignExtensionUsers}
                onGoToProfilePage={onGoToProfilePage}
              />
              {hasExtensions && (
                <PlaceholderButton
                  className={classes.addExtensionButton}
                  label={translations.addTypeExtension}
                  onAdd={onAddExtension(type)()}
                />
              )}
            </div>
          </div>
        </DraggableScroll>
        <ScrollIndicators
          arrowLeftClass={classes.arrow}
          arrowRightClass={classes.arrow}
          activeScroll={activeScroll}
          onScrollToRight={onScrollToRight}
          onScrollToStart={onScrollToStart}
        />
      </div>
    </div>
  );
};

AbsenceType.propTypes = {
  translations: PropTypes.object.isRequired,
  type: PropTypes.object.isRequired,
  hasSetUsers: PropTypes.bool.isRequired,
  onEditType: PropTypes.func.isRequired,
  onDeleteType: PropTypes.func.isRequired,
  onGetTypeUsers: PropTypes.func.isRequired,
  onAssignTypeUsers: PropTypes.func.isRequired,
  onAddExtension: PropTypes.func.isRequired,
  onEditExtension: PropTypes.func.isRequired,
  onDeleteExtension: PropTypes.func.isRequired,
  onGetExtensionUsers: PropTypes.func.isRequired,
  onAssignExtensionUsers: PropTypes.func.isRequired,
  onGoToProfilePage: PropTypes.func.isRequired,
};

export default AbsenceType;
