import PropTypes from 'prop-types';
import { Typography, makeStyles } from '@material-ui/core';
import ActionButton from '../../actionButton';
import { absenceTypeHasAditionalSettingsCheck } from '../../../../utility/absence';
import { ACTION_BUTTON_TYPES } from '../../actionButton/config';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  root: {
    boxSizing: 'border-box',
    borderRadius: 16,
    backgroundColor: ({ color }) => color || primary.bluish8,
    display: 'flex',
    alignItems: 'center',
    padding: spacing(2, 4),
    position: 'relative',
  },
  iconWrapper: {
    backgroundColor: primary.white,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexShrink: 0,
    marginRight: spacing(3),
    width: 56,
    height: 56,
  },
  content: {
    maxWidth: 'calc(100% - 68px)',
  },
  name: {
    color: primary.indigo,
    textTransform: 'capitalize',
  },
  balance: {
    color: primary.black,
    textTransform: 'capitalize',
    marginBottom: spacing(1),
  },
  actionButton: {
    position: 'absolute',
    right: 8,
    top: 8,
  },
}));

const AbsenceTypeCard = ({
  translations,
  type,
  canManageBalance,
  onManageBalance,
}) => {
  const {
    name,
    color,
    available_days,
    used_days,
    is_roll_over,
    icon: Icon,
    can_edit_balance_previous_year,
  } = type;
  const classes = useStyles({ color });

  const hasAvailableDays = absenceTypeHasAditionalSettingsCheck(type.type);

  return (
    <div className={classes.root}>
      <div className={classes.iconWrapper}>{Icon ? <Icon /> : null}</div>
      <div className={classes.content}>
        {hasAvailableDays ? (
          <Typography className={classes.balance} variant="h3">
            {`${available_days} ${
              Math.abs(available_days) !== 1
                ? translations.days
                : translations.day
            }`}
          </Typography>
        ) : (
          <Typography className={classes.balance} variant="h3">
            {`${Math.abs(used_days)} ${
              Math.abs(used_days) !== 1 ? translations.days : translations.day
            }`}
          </Typography>
        )}
        <Typography className={classes.name} variant="subtitle1">
          {`${name} ${
            hasAvailableDays ? translations.available : translations.used
          }`}
        </Typography>
      </div>
      {canManageBalance && is_roll_over && can_edit_balance_previous_year && (
        <ActionButton
          className={classes.actionButton}
          type={ACTION_BUTTON_TYPES.EDIT}
          tooltipText={translations.updateBalance}
          onClickHandler={onManageBalance}
        />
      )}
    </div>
  );
};

AbsenceTypeCard.propTypes = {
  translations: PropTypes.object.isRequired,
  type: PropTypes.object.isRequired,
  canManageBalance: PropTypes.bool.isRequired,
  onManageBalance: PropTypes.func.isRequired,
};

export default AbsenceTypeCard;
