import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  FormControl,
  FormHelperText,
  InputAdornment,
  Typography,
  Input,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '../tooltip';
import { isObjectEmpty } from '../../../utility/helpers';
import { ellipsis } from '../../../constants/helperCssRules';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',

    '& textarea': {
      boxSizing: 'border-box',
      height: '100%',
      paddingRight: 11,
    },
  },
  rootMultiline: {
    paddingRight: 0,
  },
  input: {
    width: 'auto',
    flexGrow: 1,
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    wordBreak: 'break-word',
  },
  labelExplanation: {
    margin: spacing(-1, -1, 1, 0),
    color: primary.bluish4,
    ...ellipsis(),
  },
  labelIcon: {
    width: 13,
    height: 13,
    marginLeft: spacing(1.5),
    cursor: 'pointer',
  },
  labelContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  adornmentIcon: {
    cursor: 'pointer',
    height: 'auto',
  },
  hasIcon: {
    paddingLeft: 0,
  },
}));

const ADORNMENT_START = 'start';

const InputField = forwardRef(
  (
    {
      customRootClass,
      customLabelClass,
      customInputClass,
      customFocusClass,
      customErrorClass,
      customDisabledClass,
      label,
      labelExplanation,
      labelHelp,
      refs,
      error,
      errorMessage,
      adornmentIcon: AdornmentIcon,
      startAdornment,
      multiline,
      isIconOnStart,
      fullWidth,
      large,
      onInputBlur,
      onInputFocus,
      ...rest
    },
    ref
  ) => {
    const classes = useStyles();

    const renderAdornment = () => (
      <InputAdornment
        className={classes.adornmentIcon}
        position={ADORNMENT_START}
      >
        <AdornmentIcon />
      </InputAdornment>
    );

    const renderInput = () => (
      <Input
        classes={{
          root: classNames(
            classes.root,
            { [classes.rootMultiline]: multiline },
            customRootClass
          ),
          input: classNames(
            classes.input,
            {
              [classes.hasIcon]: isIconOnStart && AdornmentIcon,
            },
            customInputClass
          ),
          focused: customFocusClass,
          disabled: customDisabledClass,
        }}
        multiline={multiline}
        inputRef={ref}
        startAdornment={
          isIconOnStart && AdornmentIcon ? renderAdornment() : startAdornment
        }
        onBlur={e => onInputBlur(e.target.value)}
        onFocus={e => onInputFocus(e.target.value)}
        {...rest}
      />
    );

    return (
      <FormControl fullWidth={fullWidth}>
        {label && (
          <div className={classes.labelContainer}>
            <FormHelperText
              className={classNames(classes.label, customLabelClass)}
            >
              <span>{label}</span>
              {!isObjectEmpty(labelHelp) && (
                <Tooltip
                  customIconClass={classes.labelIcon}
                  text={labelHelp.tooltipText}
                />
              )}
            </FormHelperText>
          </div>
        )}
        {labelExplanation ? (
          <Typography variant="body2" className={classes.labelExplanation}>
            {labelExplanation}
          </Typography>
        ) : null}
        {renderInput()}
        {error && errorMessage && (
          <FormHelperText className={customErrorClass} error={error}>
            {errorMessage}
          </FormHelperText>
        )}
      </FormControl>
    );
  }
);

InputField.displayName = 'InputField';

InputField.defaultProps = {
  id: undefined,
  label: '',
  labelExplanation: '',
  disabled: false,
  error: false,
  errorMessage: '',
  fullWidth: false,
  customErrorClass: '',
  large: false,
  labelHelp: {},
  customLabelClass: '',
  customRootClass: '',
  customFocusClass: '',
  customDisabledClass: '',
  onInputBlur: () => {},
  onInputFocus: () => {},
  customInputClass: '',
  isIconOnStart: false,
  adornmentIcon: null,
  startAdornment: undefined,
};

InputField.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  labelExplanation: PropTypes.string,
  customFocusClass: PropTypes.string,
  customDisabledClass: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  fullWidth: PropTypes.bool,
  customErrorClass: PropTypes.string,
  large: PropTypes.bool,
  customLabelClass: PropTypes.string,
  labelHelp: PropTypes.object,
  adornmentIcon: PropTypes.object,
  startAdornment: PropTypes.node,
  customRootClass: PropTypes.string,
  onInputBlur: PropTypes.func,
  onInputFocus: PropTypes.func,
  customInputClass: PropTypes.string,
  isIconOnStart: PropTypes.bool,
};

export default InputField;
