import { ReactComponent as EditIcon } from '../../../../assets/icons/edit-dark.svg';
import { ReactComponent as DuplicateIcon } from '../../../../assets/icons/content_copy.svg';
import { ReactComponent as DeleteIcon } from '../../../../assets/icons/delete-outlined.svg';
import { absenceTypeHasAditionalSettingsCheck } from '../../../../utility/absence';

export const getMenuItems = (
  labels,
  data,
  onEdit,
  onDelete,
  onDuplicate,
  hasDuplicate = false
) => {
  return [
    {
      type: 'edit',
      label: labels.edit,
      shouldItemRender: () => absenceTypeHasAditionalSettingsCheck(data?.type),
      action: () => onEdit(data),
      icon: EditIcon,
    },
    ...(hasDuplicate
      ? [
          {
            type: 'duplicate',
            label: labels.duplicate,
            shouldItemRender: () => true,
            action: () => onDuplicate(data),
            icon: DuplicateIcon,
          },
        ]
      : []),
    {
      type: 'delete',
      label: labels.delete,
      shouldItemRender: () => true,
      action: () => onDelete(data),
      icon: DeleteIcon,
    },
  ];
};

export const getTotalHolidaysCount = holidays =>
  holidays.reduce((acc, holiday) => {
    return acc + holiday.dates.length;
  }, 0);
