export const APP_PAGES = {
  APP: 'App',
  ABSCENCE_MANAGEMENT: 'AbsenceSettingsPage',
  RESET_PASSWORD: 'ResetPasswordPage',
  FORGOTTEN_PASSWORD: 'ForgottenPasswordPage',
  RESET_LINK: 'ResetLinkSentPage',
  LOGIN_PAGE: 'LoginPage',
  ACTION_PLANS: 'ActionPlansPage',
  ACTION_PLAN_DETAILS: 'ActionPlanDetailsPage',
  ATTRIBUTES: 'AttributesPage',
  COMPANY_OVERVIEW: 'CompanyOverviewPage',
  COMPETENCE_MAP: 'CompetenceMapPage',
  COMPARE: 'CompareReportPage',
  COURSES: 'CoursesPage',
  COURSE_DETAILS: 'CourseDetailsPage',
  DASHBOARD: 'DashboardPage',
  SIGN_UP: 'SignUpPage',
  ATTRIBUTE_REPORT: 'AttributeReportPage',
  JOB_TITLES: 'JobTitlesPage',
  LEAVE_REQUESTS_PAGE: 'LeaveRequestsPage',
  LEVELING: 'LevelingPage',
  FILL_SURVEY: 'FillSurveyPage',
  NOT_FOUND: 'PageNotFound',
  ONE_ON_ONE: 'OneOnOnePage',
  ONE_ON_ONE_DETAILS: 'OneOnOneDetailsPage',
  ONE_ON_ONE_REPORT: 'OneOnOneReportPage',
  ORGANIZATION: 'OrganizationPage',
  ORGANIZATION_SETTINGS: 'OrganizationSettingsPage',
  SLACK_INTEGRATION: 'SlackIntegrationPage',
  PEOPLE: 'PeoplePage',
  PEOPLE_ABSCENCES: 'PeopleAbsencesPage',
  PEOPLE_ACTION_PLANS: 'PeopleActionPlansPage',
  PEOPLE_CAREER: 'PeopleCareerPage',
  PEOPLE_COURSES: 'PeopleCoursesPage',
  PEOPLE_INFO: 'PeopleProfilePage',
  PEOPLE_ONE_ON_ONE: 'PeopleOneOnOnePage',
  PEOPLE_PRIVATE_NOTES: 'PeopleNotesPage',
  PEOPLE_INSIGHTS: 'PeopleInsightsPage',
  PEOPLE_TEAM: 'PeopleTeamPage',
  PRIVATE_NOTES: 'PrivateNotesPage',
  PRICING: 'PricingPlansPage',
  RELEASE_NOTES: 'ReleaseNotesPage',
  SKILLS_MATRIX: 'SkillsMatrixPage',
  SURVEYS: 'SurveysPage',
  SURVEY_REPORT: 'SurveyReportPage',
  SURVEY_SETTINGS: 'SurveySettingsPage',
  SURVEY_SUMMARY: 'SurveySummaryPage',
  SURVEY_SUMMARY_DETAILS: 'SurveySummaryDetailsPage',
  TAGS: 'TagsPage',
  TAG_CATEGORY: 'TagCategoryPage',
  THANK_YOU: 'ThankYouPage',
  UNAUTHORIZED_ACCESS: 'UnauthorizedAccessPage',
  UPGRADE_REQUIRED: 'UpgradeRequired',
  LIMIT_REACHED: 'LimitReached',
  INSTRUCTION_GUIDE: 'InstructionGuide',
};

export const APP_COMPONENTS = {
  ATTRIBUTE_CHIP: 'attributeChip',
  SELECT_FIELD: 'selectField',
};

export const PARAMS = {
  ABSENCE_SET: 'absence_set',
  ATTRIBUTE: 'attribute',
  AVAILABLE_MANAGERS: 'available_managers', // multiple (user ids) user endpoints
  WITHOUT_ACTION_PLANS: 'has_plans',
  COURSE_ATTRIBUTE: 'course__attribute',
  COURSE_LEVEL: 'course__level',
  COURSE_TAGS: 'course__tags',
  CREATOR: 'creator',
  CREATED_FOR: 'created_for',
  COMPLETION_STATUS: 'completion_status',
  EXCLUDE: 'exclude', // multiple (user ids) user endpoints
  EXCLUDE_ROLE: 'exclude_role', // single ('ADMIN', 'MODERATOR', 'USER', 'ASSOCIATE') user endpoints
  EXCLUDE_STATUS: 'exclude_status', // single ('IDLE', 'INVITED', 'WITH_ACCESS', 'SUSPENDED') user endpoints
  EXCLUDE_USER_STATUS: 'exclude_user_status', // single ('IDLE', 'INVITED', 'WITH_ACCESS', 'SUSPENDED') user endpoints
  JOB_TITLE: 'position', // multiple (user ids) user endpoints
  LEVEL: 'level',
  HAS_ABSENCE_TYPE: 'has_absence_type', // add year
  PAGE: 'page',
  PAGE_SIZE: 'page_size',
  REPORT: 'report',
  REPORT_TO: 'report_to', // multiple (user ids) user endpoints
  REVIEWERS: 'reviewers',
  SCOPE: 'scope', // multiple ('ME', 'DIR_REPORTS,'ALL_REPORTS', 'ASSOCIATES', 'SHARED_COLLAB', 'SHARED_VIEW') user endpoints
  STATUS: 'status', // multiple ('IDLE', 'INVITED', 'WITH_ACCESS', 'SUSPENDED') user endpoints
  SURVEY_TYPE: 'survey_type',
  SURVEY_SUMMARY_TYPE: 'survey__type',
  TAGS: 'tags', // multiple (ids) user endpoints
  TRACK: 'level__track', // multiple (ids) user endpoints
  TYPE: 'type',
  IS_ACTIVE: 'is_active',
  ROLE: 'role', // multiple ('ADMIN', 'MODERATOR', 'USER', 'ASSOCIATE') user endpoints
  ORDERING: 'ordering',
  START_AT: 'from_date',
  END_AT: 'to_date',
  FROM: 'from',
  TO: 'to',
  START: 'start',
  SEARCH: 'search',
  END: 'end',
  USED_AFTER: 'used_after',
  USED_BEFORE: 'used_before',
  START_AFTER: 'created_at_after',
  END_AFTER: 'created_at_before',
  SENT_AT_AFTER: 'sent_at_after',
  SENT_AT_BEFORE: 'sent_at_before',
  MEETING_TIME_START: 'meeting_time_after',
  MEETING_TIME_END: 'meeting_time_before',
  MODERATOR: 'moderator',
  SUBJECT: 'subject',
  IMPRESSION: 'meeting_impression',
  USER: 'user',
  IS_FINISHED: 'is_finished',
  DUE_DATE: 'due_date',
  YEAR: 'year',
};

const DEFAULT_PAGE_TABLE_PARAMS = [
  PARAMS.PAGE,
  PARAMS.PAGE_SIZE,
  PARAMS.SEARCH,
];

export const PAGE_WHITELISTED_PARAMS = {
  ACTION_PLANS: [
    ...DEFAULT_PAGE_TABLE_PARAMS,
    PARAMS.ORDERING,
    PARAMS.TAGS,
    PARAMS.USER,
    PARAMS.DUE_DATE,
    PARAMS.COMPLETION_STATUS,
  ],
  ATTRIBUTES: [
    ...DEFAULT_PAGE_TABLE_PARAMS,
    PARAMS.ORDERING,
    PARAMS.IS_ACTIVE,
    PARAMS.TYPE,
    PARAMS.TAGS,
  ],
  JOB_TITLES: [...DEFAULT_PAGE_TABLE_PARAMS, PARAMS.TAGS],
  LEARNING: [
    ...DEFAULT_PAGE_TABLE_PARAMS,
    PARAMS.LEVEL,
    PARAMS.ATTRIBUTE,
    PARAMS.TAGS,
  ],
  LEAVE_REQUESTS: [
    ...DEFAULT_PAGE_TABLE_PARAMS,
    PARAMS.ORDERING,
    PARAMS.STATUS,
    PARAMS.TYPE,
    PARAMS.YEAR,
  ],
  TAGS: [PARAMS.SEARCH],
  ONE_ON_ONE: [
    ...DEFAULT_PAGE_TABLE_PARAMS,
    PARAMS.TAGS,
    PARAMS.IMPRESSION,
    PARAMS.MODERATOR,
    PARAMS.COMPLETION_STATUS,
    PARAMS.MEETING_TIME_START,
    PARAMS.MEETING_TIME_END,
  ],
  PEOPLE: [
    ...DEFAULT_PAGE_TABLE_PARAMS,
    PARAMS.ORDERING,
    PARAMS.ROLE,
    PARAMS.SCOPE,
    PARAMS.TAGS,
    PARAMS.STATUS,
    PARAMS.JOB_TITLE,
    PARAMS.TRACK,
    PARAMS.LEVEL,
    PARAMS.WITHOUT_ACTION_PLANS,
  ],
  PEOPLE_ACTION_PLANS: [
    PARAMS.PAGE,
    PARAMS.PAGE_SIZE,
    PARAMS.COMPLETION_STATUS,
    PARAMS.TAGS,
    PARAMS.DUE_DATE,
  ],
  PEOPLE_LEARNING: [
    ...DEFAULT_PAGE_TABLE_PARAMS,
    PARAMS.COURSE_LEVEL,
    PARAMS.COURSE_ATTRIBUTE,
    PARAMS.STATUS,
    PARAMS.COURSE_TAGS,
  ],
  PEOPLE_ONE_ON_ONE: [
    PARAMS.PAGE,
    PARAMS.PAGE_SIZE,
    PARAMS.COMPLETION_STATUS,
    PARAMS.TAGS,
  ],
  PRIVATE_NOTES: [
    PARAMS.PAGE,
    PARAMS.PAGE_SIZE,
    PARAMS.START_AT,
    PARAMS.END_AT,
  ],
  SURVEYS: [
    ...DEFAULT_PAGE_TABLE_PARAMS,
    PARAMS.ORDERING,
    PARAMS.TYPE,
    PARAMS.REVIEWERS,
    PARAMS.CREATOR,
    PARAMS.CREATED_FOR,
    PARAMS.STATUS,
  ],
  SURVEY_SECTIONS: [...DEFAULT_PAGE_TABLE_PARAMS],
  SURVEY_SUMMARY: [
    ...DEFAULT_PAGE_TABLE_PARAMS,
    PARAMS.CREATOR,
    PARAMS.ORDERING,
    PARAMS.SURVEY_SUMMARY_TYPE,
  ],
};

export const ACTION_PLANS_DEFAULT_PARAMS = {
  [PARAMS.PAGE]: 1,
  [PARAMS.PAGE_SIZE]: 10,
  [PARAMS.ORDERING]: '-modified_on',
};

export const PEOPLE_ACTION_PLANS_DEFAULT_PARAMS = {
  [PARAMS.PAGE]: 1,
  [PARAMS.PAGE_SIZE]: 10,
};

export const COURSES_DEFAULT_PARAMS = {
  [PARAMS.PAGE]: 1,
  [PARAMS.PAGE_SIZE]: 1000,
};

export const ATTRIBUTES_DEFAULT_PARAMS = {
  [PARAMS.PAGE]: 1,
  [PARAMS.PAGE_SIZE]: 10,
  [PARAMS.ORDERING]: '-modified_on',
};

export const JOB_TITLES_DEFAULT_PARAMS = {
  [PARAMS.PAGE]: 1,
  [PARAMS.PAGE_SIZE]: 1000,
  [PARAMS.ORDERING]: 'name',
  [PARAMS.IS_ACTIVE]: true,
};

export const ONE_ON_ONE_DEFAULT_PARAMS = {
  [PARAMS.PAGE]: 1,
  [PARAMS.PAGE_SIZE]: 10,
};

export const PEOPLE_ONE_ON_ONE_DEFAULT_PARAMS = {
  [PARAMS.PAGE]: 1,
  [PARAMS.PAGE_SIZE]: 30,
};

export const PRIVATE_NOTES_DEFAULT_PARAMS = {
  [PARAMS.PAGE]: 1,
  [PARAMS.PAGE_SIZE]: 10,
};

export const PEOPLE_DEFAULT_PARAMS = {
  [PARAMS.PAGE]: 1,
  [PARAMS.PAGE_SIZE]: 10,
  [PARAMS.ORDERING]: 'first_name,id',
};

export const SURVEYS_DEFAULT_PARAMS = {
  [PARAMS.PAGE]: 1,
  [PARAMS.PAGE_SIZE]: 10,
  [PARAMS.ORDERING]: '-sent_at',
};

export const SURVEYS_SUMMARY_DEFAULT_PARAMS = {
  [PARAMS.PAGE]: 1,
  [PARAMS.PAGE_SIZE]: 10,
  [PARAMS.ORDERING]: '-created_at',
};

export const SURVEY_GROUPS_DEFAULT_PARAMS = {
  [PARAMS.PAGE]: 1,
  [PARAMS.PAGE_SIZE]: 10,
  [PARAMS.ORDERING]: 'title',
};

export const LEAVE_REQUESTS_DEFAULT_PARAMS = {
  [PARAMS.PAGE]: 1,
  [PARAMS.PAGE_SIZE]: 10,
  [PARAMS.ORDERING]: '-created_at',
};

export const PEOPLE_ABSENCE_REQUESTS_DEFAULT_PARAMS = {
  [PARAMS.PAGE]: 1,
  [PARAMS.PAGE_SIZE]: 10,
  [PARAMS.ORDERING]: '-created_at',
};

export const PEOPLE_ABSENCE_UPCOMING_TIME_OFF_DEFAULT_PARAMS = {
  [PARAMS.PAGE]: 1,
  [PARAMS.PAGE_SIZE]: 3,
};
