import { primaryPalette } from '../styles/theme';

export const RESET_PASSWORD_URL = 'set-password';

export const UDEMY_BASE_URL = 'https://udemy.com';

export const KADAR_LANDING = 'https://www.kadar.app/';

export const STRIPE_PRO_PRICE_ID =
  process.env.REACT_APP_STRIPE_BUSINESS_PRICE_ID;

export const ABSENCE_FEATURE_INITIAL_DATE = '01 Jan 2024';

export const SLACK_CLIENT_ID = process.env.REACT_APP_SLACK_CLIENT_ID;

export const BOOK_A_DEMO_LINK =
  'https://calendly.com/kadar-demo/start-now-with-kadar';

export const SUBSCRIPTION_EXCLUDED_URLS = [
  '/terms-of-service',
  `/pricing-plans`,
  '/privacy-policy',
];

export const FEATURE_COLORS = {
  TO_DO: primaryPalette.blue1,
  PLANS: primaryPalette.pink1,
  COURSES: primaryPalette.bluish1,
  ONE_ON_ONE: primaryPalette.purple,
  TEAM: primaryPalette.yellow2,
  TASKS: primaryPalette.green1,
};

export const FREEMIUM_LIMIT_TYPES = {
  ADMINS: 'admins',
  ATTRIBUTES: 'attributes',
  COURSES: 'courses',
  MODERATORS: 'moderators',
  ONE_ON_ONE: '1-1',
  PLANS: 'plans',
  SURVEYS: 'surveys',
};
