import PropTypes from 'prop-types';
import { TransitionGroup } from 'react-transition-group';
import { Fade, makeStyles } from '@material-ui/core';
import AbsenceTypeCard from './absenceTypeCard';

const useStyles = makeStyles(() => ({
  root: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, minmax(0,1fr))',
    gridGap: 12,
    width: '100%',
  },
}));

const AbsenceTypesCards = ({
  translations,
  abscenceTypes,
  canManageBalance,
  onManageBalance,
}) => {
  const classes = useStyles();

  return (
    <TransitionGroup className={classes.root}>
      {abscenceTypes.map(absenceType => {
        return (
          <Fade key={`abscence_type_${absenceType.type}`} in unmountOnExit>
            <div>
              <AbsenceTypeCard
                translations={translations}
                type={absenceType}
                canManageBalance={canManageBalance}
                onManageBalance={onManageBalance(absenceType)}
              />
            </div>
          </Fade>
        );
      })}
    </TransitionGroup>
  );
};

AbsenceTypesCards.defaultProps = {
  abscenceTypes: [],
};

AbsenceTypesCards.propTypes = {
  translations: PropTypes.object.isRequired,
  abscenceTypes: PropTypes.arrayOf(PropTypes.object),
  canManageBalance: PropTypes.bool.isRequired,
  onManageBalance: PropTypes.func.isRequired,
};

export default AbsenceTypesCards;
