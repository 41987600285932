import { formatDate } from '../../../utility/dateUtils';
import {
  getRequestPeriodLabel,
  getUpcomingTimeOffPeriodLabel,
} from '../../../utility/absence';
import { isArrayEmpty } from '../../../utility/helpers';
import { isEmpty } from '../../../utility/validation';
import { HOLIDAY } from '../../../constants/absence';
import { ABSENCE_REQUEST_STATUS_TYPES } from '../../../constants/statuses';
import { FIELD_TYPES } from '../../../constants/fieldTypes';

export const getRollOverBalanceField = classes => [
  {
    name: 'total_previous_year',
    type: FIELD_TYPES.TEXT,
    inputType: 'number',
    translationKey: 'numberOfDays',
    customRootClass: classes.numberOfDaysField,
    inputProps: {
      min: 1,
      max: 365,
    },
    validators: [
      { type: 'required', validator: isEmpty },
      {
        type: 'minMaxDays',
        validator: value => value >= 0,
      },
    ],
  },
];

export const getUpcomingTimeOffTableHeaders = (labels, classes) => [
  {
    id: 1,
    title: labels.period,
    rowKey: 'period',
    headerCellClass: classes.tableHeaderCell,
    rowCellClass: classes.tableRowCell,
    isSortingDisabled: true,
    minWidth: 220,
  },
  {
    id: 2,
    title: labels.description,
    rowKey: 'description',
    headerCellClass: classes.tableHeaderCell,
    rowCellClass: classes.tableRowCell,
    isSortingDisabled: true,
    hasConditionalTooltip: true,
    maxWidth: '1.5fr',
  },
  {
    id: 3,
    title: labels.used,
    rowKey: 'usedDays',
    headerCellClass: classes.tableHeaderCell,
    rowCellClass: classes.tableRowCell,
    isSortingDisabled: true,
    minWidth: 90,
  },
  {
    id: 4,
    title: labels.type,
    rowKey: 'typeComponent',
    headerCellClass: classes.tableHeaderCell,
    rowCellClass: classes.tableRowCell,
    isSortingDisabled: true,
    minWidth: 45,
  },
];

export const getUpcomingTimeOffTableData = (
  translations,
  upcomingTimeOff,
  requestStatuses,
  absenceTypes,
  renderType,
  renderStatus
) => {
  if (isArrayEmpty(upcomingTimeOff)) return [];

  return upcomingTimeOff.reduce((acc, timeOff) => {
    const currentStatus = requestStatuses.find(
      status => status.id === timeOff.status
    );
    const currentType = absenceTypes.find(
      absenceType => absenceType.id === timeOff.type
    ) || { ...HOLIDAY, name: translations[HOLIDAY.key] };
    const used = Math.abs(timeOff.used_days);

    return [
      ...acc,
      {
        ...timeOff,
        typeComponent: renderType(currentType),
        description: timeOff.description || '-',
        period: getUpcomingTimeOffPeriodLabel(
          timeOff.absence_from,
          timeOff.absence_to
        ),
        statusComponent: renderStatus(currentStatus),
        usedDays: used
          ? `${used} ${used === 1 ? translations.day : translations.days}`
          : '-',
      },
    ];
  }, []);
};

export const getLeaveRequestsTableActionsHeader = (
  classes,
  labels,
  isAdmin
) => [
  {
    id: 'actions-column',
    title: labels.actions,
    rowKey: 'actions',
    align: 'right',
    headerCellClass: classes.tableHeaderCell,
    rowCellClass: classes.actionsCell,
    isHeaderAction: true,
    minWidth: isAdmin ? 104 : 60,
    maxWidth: isAdmin ? 104 : 60,
  },
];

export const getLeaveRequestsTableHeaders = (classes, labels) => [
  {
    id: 1,
    title: labels.requestDate,
    rowKey: 'createdAt',
    headerCellClass: classes.tableHeaderCell,
    rowCellClass: classes.tableRowCell,
    cellValueClass: classes.cellValue,
    isSortingDisabled: true,
    minWidth: 125,
    maxWidth: 125,
  },
  {
    id: 2,
    title: labels.description,
    rowKey: 'description',
    headerCellClass: classes.tableHeaderCell,
    rowCellClass: classes.tableRowCell,
    isSortingDisabled: true,
    hasConditionalTooltip: true,
    maxWidth: '1.5fr',
  },
  {
    id: 3,
    title: labels.type,
    rowKey: 'typeComponent',
    headerCellClass: classes.tableHeaderCell,
    rowCellClass: classes.tableRowCell,
    isSortingDisabled: true,
    hasConditionalTooltip: true,
    minWidth: 45,
    maxWidth: 45,
  },
  {
    id: 4,
    title: labels.period,
    rowKey: 'period',
    headerCellClass: classes.tableHeaderCell,
    rowCellClass: classes.tableRowCell,
    isSortingDisabled: true,
    hasConditionalTooltip: true,
    minWidth: 260,
    maxWidth: 260,
  },
  {
    id: 5,
    title: labels.status,
    rowKey: 'statusComponent',
    headerCellClass: classes.tableHeaderCell,
    rowCellClass: classes.tableRowCell,
    isSortingDisabled: true,
    minWidth: 90,
    maxWidth: 90,
  },
];

export const getLeaveRequestsTableData = (
  translations,
  absenceRequests,
  requestStatuses,
  absenceTypes,
  isDeactivatedUser,
  isAdmin,
  isCurrentUser,
  renderType,
  renderStatus,
  renderActions
) => {
  if (isArrayEmpty(absenceRequests) || isArrayEmpty(absenceTypes)) return [];

  return absenceRequests.reduce((acc, absenceRequest) => {
    const hasUsedDays = absenceRequest?.used_days !== 0;
    const currentStatus = requestStatuses.find(
      status => status.id === absenceRequest.status
    );
    const isRequested =
      currentStatus?.id === ABSENCE_REQUEST_STATUS_TYPES.REQUESTED;
    const currentType = absenceTypes.find(
      type => type.id === absenceRequest.type
    );
    const canManageRequest =
      (isAdmin && currentStatus.id !== ABSENCE_REQUEST_STATUS_TYPES.REJECTED) ||
      (isCurrentUser && isRequested);
    const hasEdit = hasUsedDays && canManageRequest && !isDeactivatedUser;
    const hasDelete =
      isAdmin ||
      (isCurrentUser &&
        currentStatus.id !== ABSENCE_REQUEST_STATUS_TYPES.APPROVED);
    const hasStatusActions = hasUsedDays && isAdmin && isRequested;

    return [
      ...acc,
      {
        ...absenceRequest,
        createdAt: formatDate(absenceRequest.created_at),
        typeComponent: renderType(currentType),
        description: absenceRequest.description || '-',
        period: getRequestPeriodLabel(
          translations,
          absenceRequest.absence_from,
          absenceRequest.absence_to,
          Math.abs(absenceRequest.used_days)
        ),
        statusComponent: renderStatus(currentStatus),
        ...(isAdmin || isCurrentUser
          ? {
              actions: renderActions(
                absenceRequest,
                hasEdit,
                hasDelete,
                hasStatusActions
              ),
            }
          : {}),
      },
    ];
  }, []);
};
