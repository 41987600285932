import PropTypes from 'prop-types';
import { Typography, makeStyles, alpha } from '@material-ui/core';
import TextBoxWithTooltip from '../../../../textBoxWithTooltip';
import DotsMenu from '../../../../dotsMenu';
import AsyncListTooltip from '../../../../asyncListTooltip';
import { formatDate } from '../../../../../../utility/dateUtils';
import { getMenuItems } from '../../config';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  root: {
    boxSizing: 'border-box',
    backgroundColor: primary.bluish9,
    border: `1px dashed ${primary.bluish5}`,
    borderRadius: 16,
    display: 'flex',
    flexDirection: 'column',
    padding: spacing(4),
    width: 300,
    minWidth: 300,
    height: 194,
    transition: 'box-shadow .3s ease',
    '&:hover': {
      boxShadow: `0px 2px 8px 0px ${alpha(primary.black, 0.12)}`,
    },
  },
  info: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: spacing(11.5),
  },
  actionsMenu: {
    flexShrink: 0,
    marginLeft: spacing(1.5),
  },
  daysCountLabel: {
    color: primary.bluish4,
  },
  showDaysWrapper: {
    width: 'fit-content',
  },
  showDays: {
    cursor: 'pointer',
    color: primary.blue1,
    display: 'flex',
    width: 'fit-content',
  },
  daysTooltipWrapper: {
    minWidth: 200,
  },
  daysTooltiplabel: {
    maxHeight: 222,
  },
}));

const Holiday = ({ translations, holiday, onEdit, onDelete }) => {
  const classes = useStyles();

  const { name, dates } = holiday;

  const totalDays = dates.length;

  const extensionMenuItems = getMenuItems(
    translations.menuItemLabels,
    holiday,
    onEdit,
    onDelete
  );

  const renderDay = day => (
    <Typography variant="body1">{formatDate(day)}</Typography>
  );

  return (
    <div className={classes.root}>
      <div className={classes.info}>
        <TextBoxWithTooltip variant="subtitle1" text={name} />
        <div className={classes.actionsMenu}>
          <DotsMenu menuItems={extensionMenuItems} isVerticalMenu />
        </div>
      </div>
      <div>
        <Typography className={classes.daysCountLabel} variant="caption">
          {translations.daysCountLabel}
        </Typography>
        <Typography variant="h3">
          {`${totalDays} ${
            totalDays === 1 ? translations.day : translations.days
          }`}
        </Typography>
        <div className={classes.showDaysWrapper}>
          <AsyncListTooltip
            customPopperClass={classes.daysTooltipWrapper}
            customTooltipClass={classes.daysTooltipWrapper}
            customTooltipLabelClass={classes.daysTooltiplabel}
            labelData={dates}
            renderLabelItem={renderDay}
          >
            <Typography className={classes.showDays} variant="body2">
              {translations.showDays}
            </Typography>
          </AsyncListTooltip>
        </div>
      </div>
    </div>
  );
};

Holiday.propTypes = {
  translations: PropTypes.object.isRequired,
  holiday: PropTypes.object.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default Holiday;
