import { memo, useState, useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import CustomFormDrawer from '../customFormDrawer';
import http from '../../../utility/http';
import {
  getObjectToNumberArray,
  isObjectEmpty,
  trimString,
} from '../../../utility/helpers';
import { showSuccessMessage } from '../../../utility/uiUtils';
import { prepareTasksForSave } from '../../../utility/tasks';
import { ACTION_PLAN_FIELDS } from '../../../constants/actionPlan';
import {
  API_ACTION_PLANS,
  api_action_plan,
} from '../../../constants/apiRoutes';
import {
  INITIAL_DATA,
  getAddActionPlanFields,
  getEditActionPlanFields,
  getInitialData,
  getInitialEditData,
  getEditActionPlanSuccessMessage,
  getTitle,
} from './config';

const {
  DESCRIPTION,
  DUE_DATE,
  STATUS,
  USER,
  TAGS,
  TASKS,
  TITLE,
  PROGRESS,
  COURSES,
  IS_PROPOSED,
  IS_PENDING_PROPOSAL,
  CREATOR,
} = ACTION_PLAN_FIELDS;

const useStyles = makeStyles(() => ({
  form: {
    display: 'grid',
    gridColumnGap: 24,
    gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
    gridTemplateAreas: `
      "user ."  
      "title ."
      "date ."
      "status progress"
      "tags courses"
      "description description"
      "creator created"
      "notify notify"
    `,
  },
  title: {
    gridArea: 'title',
  },
  user: {
    gridArea: 'user',
  },
  progress: {
    gridArea: 'progress',
  },
  dueDate: {
    gridArea: 'date',
  },
  status: {
    gridArea: 'status',
  },
  tags: {
    gridArea: 'tags',
  },
  // notify: {
  //   gridArea: 'notify',
  // },
  description: {
    gridArea: 'description',
  },
  creator: {
    gridArea: 'creator',
  },
  createdOn: {
    gridArea: 'created',
  },
  courses: {
    gridArea: 'courses',
  },
}));

const ManageActionPlan = ({
  translations,
  isOpen,
  isInitialValid,
  hasCreatedForField,
  currentUser,
  isPlanProposal,
  actionPlan,
  statuses,
  courses,
  categories,
  user,
  allUsers,
  onCreateTag,
  getCourses,
  getCourseEnrolledUsers,
  onCoursesSearch,
  onClose,
  onDelete,
  onConfirm,
}) => {
  const classes = useStyles();
  const [initialData, setInitialData] = useState(INITIAL_DATA);
  const isCreate = useMemo(() => isObjectEmpty(actionPlan), [actionPlan]);
  const hasCreator = !!actionPlan[CREATOR];
  const isProposed = actionPlan?.[IS_PROPOSED];
  const isPendingProposal = actionPlan?.[IS_PENDING_PROPOSAL];
  const isCreatedForCurrentUser = actionPlan?.[USER]?.id === currentUser?.id;

  const updateInitialData = useCallback(() => {
    let data = {
      ...INITIAL_DATA,
    };

    if (!isObjectEmpty(user)) {
      data = getInitialData(user);
    }
    if (!isObjectEmpty(actionPlan)) {
      data = getInitialEditData(actionPlan);
    }

    setInitialData(data);
  }, [user, actionPlan]);

  useEffect(() => {
    if (isOpen && (actionPlan?.id || user)) {
      updateInitialData();
    }
  }, [isOpen, actionPlan, user, updateInitialData]);

  const handleSave = values => {
    const data = {
      [TITLE]: trimString(values[TITLE]),
      [USER]: values[USER]?.id,
      [DESCRIPTION]: values[DESCRIPTION],
      [DUE_DATE]: values[DUE_DATE],
      [STATUS]: values[STATUS],
      // [NOTIFY]: values[NOTIFY],
      [TAGS]: values[TAGS],
      [TASKS]: values[TASKS],
      [PROGRESS]: values[PROGRESS],
      [COURSES]: getObjectToNumberArray(values[COURSES]),
      [IS_PROPOSED]: isPlanProposal,
      ...(isPlanProposal ? { [IS_PENDING_PROPOSAL]: true } : {}),
    };

    return http.post(API_ACTION_PLANS, data).then(() => {
      showSuccessMessage(
        isPlanProposal ? translations.successProposal : translations.successAdd
      );
      onConfirm();
    });
  };

  const handleEditSave = async updatedPoint => {
    const data = {
      [TITLE]: trimString(updatedPoint[TITLE]),
      [USER]: updatedPoint[USER]?.id,
      [DESCRIPTION]: updatedPoint[DESCRIPTION],
      [STATUS]: updatedPoint[STATUS],
      // [NOTIFY]: updatedPoint[NOTIFY],
      [DUE_DATE]: updatedPoint[DUE_DATE],
      [TAGS]: updatedPoint[TAGS],
      [TASKS]: prepareTasksForSave(updatedPoint[TASKS]),
      [PROGRESS]: updatedPoint[PROGRESS],
      [COURSES]: getObjectToNumberArray(updatedPoint[COURSES]),
      ...(isProposed && isPendingProposal && !isCreatedForCurrentUser
        ? { [CREATOR]: currentUser.id, [IS_PENDING_PROPOSAL]: false }
        : {}),
    };

    return http.patch(api_action_plan(actionPlan.id), data).then(() => {
      showSuccessMessage(
        getEditActionPlanSuccessMessage(translations, actionPlan, currentUser)
      );
      onConfirm();
    });
  };

  return (
    <CustomFormDrawer
      customFormClass={classes.form}
      translations={translations}
      titleText={getTitle(translations, isPlanProposal, isPendingProposal)}
      isOpened={isOpen}
      isInitialValid={
        isPendingProposal ? isCreatedForCurrentUser : isInitialValid
      }
      fields={
        isCreate
          ? getAddActionPlanFields(
              classes,
              // translations,
              // currentUser,
              user,
              hasCreatedForField,
              isPlanProposal,
              onCreateTag,
              getCourses,
              getCourseEnrolledUsers,
              onCoursesSearch
            )
          : getEditActionPlanFields(
              classes,
              // translations,
              // currentUser,
              hasCreatedForField,
              // actionPlan,
              actionPlan[IS_PENDING_PROPOSAL] &&
                actionPlan[USER]?.id === currentUser?.id,
              hasCreator,
              onCreateTag,
              getCourses,
              getCourseEnrolledUsers,
              onCoursesSearch
            )
      }
      allUsers={allUsers}
      categories={categories}
      courses={courses}
      initialData={initialData}
      statuses={statuses}
      onClose={onClose}
      onSave={isCreate ? handleSave : handleEditSave}
      onDelete={onDelete}
      hasCancelButton
      isMiddleWidth
    />
  );
};

ManageActionPlan.defaultProps = {
  currentUser: {},
  actionPlan: {},
  isInitialValid: false,
  hasCreatedForField: false,
  user: {},
  courses: [],
  allUsers: [],
  statuses: [],
  isPlanProposal: false,
  onDelete: () => {},
};

ManageActionPlan.propTypes = {
  translations: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  allUsers: PropTypes.arrayOf(PropTypes.shape({})),
  user: PropTypes.object,
  currentUser: PropTypes.object,
  hasCreatedForField: PropTypes.bool,
  statuses: PropTypes.arrayOf(PropTypes.shape({})),
  courses: PropTypes.arrayOf(PropTypes.shape({})),
  actionPlan: PropTypes.object,
  isPlanProposal: PropTypes.bool,
  categories: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isInitialValid: PropTypes.bool,
  onDelete: PropTypes.func,
  getCourses: PropTypes.func.isRequired,
  getCourseEnrolledUsers: PropTypes.func.isRequired,
  onCoursesSearch: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCreateTag: PropTypes.func.isRequired,
};

export default memo(ManageActionPlan);
