import { formatDate } from '../../../utility/dateUtils';
import { getRequestPeriodLabel } from '../../../utility/absence';
import { getUserProfileLink } from '../../../utility/uiUtils';
import { ABSENCE_REQUEST_STATUS_TYPES } from '../../../constants/statuses';
import { isUserDeactivated } from '../../../utility/helpers';

export const getLeaveRequestsPageTableActionsHeader = labels => [
  {
    id: 'actions-column',
    title: labels.actions,
    rowKey: 'actions',
    align: 'right',
    rowCellClass: 'actions-cell',
    isHeaderAction: true,
    minWidth: 108,
    maxWidth: 116,
  },
];

export const getLeaveRequestsPageTableHeaders = labels => [
  {
    id: 1,
    title: labels.for,
    rowKey: 'user',
    sortAs: 'user__first_name,user__last_name',
    minWidth: 160,
    maxWidth: '1fr',
  },
  {
    id: 2,
    title: labels.createdAt,
    rowKey: 'createdAt',
    sortAs: 'created_at',
    minWidth: 130,
    maxWidth: '1fr',
  },
  {
    id: 3,
    title: labels.description,
    rowKey: 'description',
    hasConditionalTooltip: true,
    isSortingDisabled: true,
    maxWidth: '1.5fr',
  },
  {
    id: 4,
    title: labels.type,
    rowKey: 'typeComponent',
    sortAs: 'type',
    maxWidth: 77,
  },
  {
    id: 5,
    title: labels.period,
    rowKey: 'period',
    hasConditionalTooltip: true,
    sortAs: 'absence_from',
    minWidth: 240,
    maxWidth: '1fr',
  },
  {
    id: 6,
    title: labels.status,
    rowKey: 'statusComponent',
    sortAs: 'status',
    minWidth: 106,
    maxWidth: 106,
  },
];

export const getLeaveRequestsTableData = (
  translations,
  isAdmin,
  leaveRequests,
  absenceTypes,
  statuses,
  renderUser,
  renderStatus,
  renderType,
  renderActions
) => {
  if (!leaveRequests) return [];

  return leaveRequests.map(leaveRequest => {
    const { user } = leaveRequest;
    const isDeactivatedUser = isUserDeactivated(user);
    const currentStatus = statuses.find(
      status => status.id === leaveRequest.status
    );
    const currentType = absenceTypes.find(
      aType => aType.id === leaveRequest.type
    );
    const isRequested =
      currentStatus?.id === ABSENCE_REQUEST_STATUS_TYPES.REQUESTED;
    const hasEdit =
      isAdmin &&
      currentStatus.id !== ABSENCE_REQUEST_STATUS_TYPES.REJECTED &&
      !isDeactivatedUser;
    const hasStatusButtons = isAdmin && isRequested;

    return {
      ...leaveRequest,
      user: renderUser(user),
      createdAt: formatDate(leaveRequest.created_at),
      period: getRequestPeriodLabel(
        translations,
        leaveRequest.absence_from,
        leaveRequest.absence_to,
        Math.abs(leaveRequest.used_days)
      ),
      description: leaveRequest.description || '-',
      statusComponent: renderStatus(currentStatus),
      typeComponent: renderType(currentType),
      actions: renderActions(leaveRequest, hasStatusButtons, hasEdit),
      cellLinks: {
        user: {
          to: `${getUserProfileLink(user?.id, true)}/absences`,
        },
      },
    };
  });
};

export const getNoResultsLabels = (translations, isLoading, isSearchActive) => {
  if (isLoading) {
    return {
      title: '',
      content: '',
    };
  }

  return {
    title: isSearchActive ? '' : translations.title,
    content: isSearchActive ? translations.search : translations.message,
  };
};
