import { useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import ReorderItems from '../../../reorderItems';
import { reorder } from '../../../../../utility/helpers';
import LevelingTrack from './levelingTrack';

const LevelingTracks = ({
  translations,
  levelingTracks,
  levelingFramework,
  onEditTrackTitle,
  onDuplicateTrack,
  handleDeleteTrackDialog,
  handleCreateLevel,
  onReorderLevel,
  onReorderTrack,
  handleMoveTrack,
  isReadOnly,
  isExpandedOnFirstOpen,
  toggleExpansion,
  isAdmin,
}) => {
  const handleReorder = useCallback(
    result => {
      if (
        !result.destination ||
        result.source.index === result.destination.index
      )
        return;

      const updatedOrder = reorder(
        levelingTracks,
        result.source.index,
        result.destination.index
      );

      return onReorderTrack(levelingFramework?.id, updatedOrder);
    },
    [levelingTracks, levelingFramework, onReorderTrack]
  );

  return (
    <div>
      <ReorderItems
        onSave={handleReorder}
        isDragDisabled={isReadOnly || !isAdmin}
      >
        {levelingTracks.map(levelingTrack => (
          <LevelingTrack
            key={`track_group_${levelingTrack.id}`}
            id={levelingTrack.id}
            isAdmin={isAdmin}
            translations={translations}
            levelingFramework={levelingFramework}
            levelingTrack={levelingTrack}
            onEditTrackTitle={onEditTrackTitle}
            onDuplicateTrack={onDuplicateTrack}
            onReorderLevel={onReorderLevel}
            handleCreateLevel={handleCreateLevel}
            handleMoveTrack={handleMoveTrack}
            handleDeleteTrackDialog={handleDeleteTrackDialog}
            isReadOnly={isReadOnly}
            isExpandedOnFirstOpen={isExpandedOnFirstOpen}
            toggleExpansion={toggleExpansion}
          />
        ))}
      </ReorderItems>
    </div>
  );
};
LevelingTracks.defaultProps = {
  onEditTrackTitle: () => {},
  onDuplicateTrack: () => {},
  onReorderLevel: () => {},
  onReorderTrack: () => {},
  handleDeleteTrackDialog: () => {},
  handleCreateLevel: () => {},
  handleMoveTrack: () => {},
  isExpandedOnFirstOpen: false,
  toggleExpansion: () => {},
};

LevelingTracks.propTypes = {
  translations: PropTypes.object.isRequired,
  levelingTracks: PropTypes.arrayOf(PropTypes.object).isRequired,
  levelingFramework: PropTypes.object.isRequired,
  onEditTrackTitle: PropTypes.func,
  onDuplicateTrack: PropTypes.func,
  handleDeleteTrackDialog: PropTypes.func,
  onReorderLevel: PropTypes.func,
  onReorderTrack: PropTypes.func,
  handleCreateLevel: PropTypes.func,
  handleMoveTrack: PropTypes.func,
  isReadOnly: PropTypes.bool.isRequired,
  isExpandedOnFirstOpen: PropTypes.bool,
  toggleExpansion: PropTypes.func,
  isAdmin: PropTypes.bool.isRequired,
};

export default memo(LevelingTracks);
